import { connect } from 'react-redux'
import EventListComponent from '../../components/Users/EventListComponent'
import EventActions from '../../redux/EventRedux'

const mapStateToProps = (state) => {
  const { event } = state

  return {
    event
  }
}

const mapStateToDispatch = (dispatch) => ({
  getEvents: () => dispatch(EventActions.getEventsRequest()),
  toggleEventForm: (isFormVisible, event) => dispatch(EventActions.toggleEventForm(isFormVisible, event)),
  destroyEvent: (event) => dispatch(EventActions.destroyEventRequest(event))
})

export default connect(mapStateToProps, mapStateToDispatch)(EventListComponent)
