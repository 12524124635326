// a library to wrap and simplify api calls
import apisauce from "apisauce";
import $ from "jquery";
import qs from "qs";
import objectToFormData from "object-to-formdata";

const config = require("../config");
const node_env = process.env.NODE_ENV || "development";

const apiUrl = config[node_env]["apiUrl"];

// our "constructor"
const create = (baseURL = apiUrl) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache"
    },
    timeout: 60000
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const setHeaders = headers => api.setHeaders(headers);

  const userLogin = (email, password) =>
    api.post("/users/sign_in.json", { user: { email, password } });
  const userVerifyLogin = () => api.get("/users/verify.json");
  const userConfirm = confirmationToken =>
    api.get("/users/confirmation.json", {
      confirmation_token: confirmationToken
    });
  const userResetPasswordInstructions = email =>
    api.post("/users/password.json", { user: { email } });
  const userResetPassword = user => api.put("/users/password.json", { user });
  const adminLogin = (email, password) =>
    api.post("/admins/sign_in.json", { admin: { email, password } });
  const adminVerifyLogin = () => api.get("/admin/admins/verify.json");

  const adminGetEventsToPay = query => {
    query.q.starts_at_gteq = query.q.starts_at_gteq.format("YYYY-MM-DD HH:mm");
    query.q.starts_at_lteq = query.q.starts_at_lteq.format("YYYY-MM-DD HH:mm");

    return api.get("/admin/events/to_pay.json?" + $.param(query));
  };

  // Admin routes
  const getCompanies = () => api.get("/admin/companies.json");
  const createCompany = company => {
    const data = new FormData();

    Object.keys(company).forEach(key => {
      data.append(`company[${key}]`, company[key] || "");
    });

    return api.post("/admin/companies.json", data);
  };
  const updateCompany = company => {
    const data = new FormData();

    Object.keys(company).forEach(key => {
      data.append(`company[${key}]`, company[key] || "");
    });

    return api.put("/admin/companies/" + company.id + ".json", data);
  };
  const destroyCompany = company =>
    api.delete("/admin/companies/" + company.id + ".json");
  const getBillingPlans = () => api.get("/admin/billing_plans.json");
  const getCompany = companyId => api.get(`/admin/companies/${companyId}.json`);

  const getReligions = () => api.get("/admin/religions.json");
  const createReligion = religion => {
    const data = new FormData();

    Object.keys(religion).forEach(key => {
      data.append(`religion[${key}]`, religion[key] || "");
    });

    return api.post("/admin/religions.json", data);
  };
  const updateReligion = religion => {
    const data = new FormData();

    Object.keys(religion).forEach(key => {
      data.append(`religion[${key}]`, religion[key] || "");
    });

    return api.put("/admin/religions/" + religion.id + ".json", data);
  };
  const destroyReligion = religion =>
    api.delete("/admin/religions/" + religion.id + ".json");

  const getBackgroundImages = () => api.get("/admin/background_images.json");
  const createBackgroundImage = backgroundImage => {
    const data = new FormData();

    Object.keys(backgroundImage).forEach(key => {
      data.append(`background_image[${key}]`, backgroundImage[key] || "");
    });

    return api.post("/admin/background_images.json", data);
  };
  const updateBackgroundImage = backgroundImage => {
    const data = new FormData();

    Object.keys(backgroundImage).forEach(key => {
      data.append(`background_image[${key}]`, backgroundImage[key] || "");
    });

    return api.put(
      "/admin/background_images/" + backgroundImage.id + ".json",
      data
    );
  };
  const destroyBackgroundImage = backgroundImage =>
    api.delete("/admin/background_images/" + backgroundImage.id + ".json");

  // User routes
  const getUsers = () => api.get("/users.json");
  const createUser = user => api.post("/users.json", { user });
  const updateUser = user => api.put("/users/" + user.id + ".json", { user });
  const destroyUser = user => api.delete("/users/" + user.id + ".json");

  const getBranches = () => api.get("/branches.json");
  const getBranch = branchId => api.get(`/branches/${branchId}.json`);
  const createBranch = branch => api.post("/branches.json", { branch });
  const updateBranch = branch =>
    api.put("/branches/" + branch.id + ".json", { branch });
  const destroyBranch = branch =>
    api.delete("/branches/" + branch.id + ".json");

  const getRooms = branchId => api.get(`/branches/${branchId}/rooms.json`);
  const createRoom = room =>
    api.post(`/branches/${room.branch_id}/rooms.json`, { room });
  const getRoom = roomId => api.get(`/rooms/${roomId}.json`);
  const updateRoom = room => api.put("/rooms/" + room.id + ".json", { room });
  const destroyRoom = room => api.delete("/rooms/" + room.id + ".json");

  const getEvents = () => api.get("/events.json");
  const getEvent = eventId => api.get(`/events/${eventId}.json`);
  const createEvent = event => {
    const data = new FormData();

    Object.keys(event).forEach(key => {
      data.append(`event[${key}]`, event[key] || "");
    });

    return api.post("/events.json", data);
  };
  const updateEvent = event => {
    const data = new FormData();

    Object.keys(event).forEach(key => {
      data.append(`event[${key}]`, event[key] || "");
    });

    return api.put("/events/" + event.id + ".json", data);
  };
  const destroyEvent = event => api.delete("/events/" + event.id + ".json");
  const getEventsToPay = query => {
    query.q.starts_at_gteq = query.q.starts_at_gteq.format("YYYY-MM-DD HH:mm");
    query.q.starts_at_lteq = query.q.starts_at_lteq.format("YYYY-MM-DD HH:mm");

    return api.get("/events/to_pay.json?" + $.param(query));
  };
  const blockEventMessage = eventMessage =>
    api.post("/event_messages/" + eventMessage.id + "/block.json");
  const unblockEventMessage = eventMessage =>
    api.post("/event_messages/" + eventMessage.id + "/unblock.json");
  const toggleBlacklistEventMessage = eventMessage =>
    api.put("/event_messages/" + eventMessage.id + "/toggle_blacklist.json");

  const getCities = countryId =>
    api.get("/states.json?q[country_id_eq]=" + countryId);
  const getTypeOfBurials = () => api.get("/type_of_burials.json");
  const getCountries = () => api.get("/admin/countries.json");

  const activateScreen = (code, device_fingerprint) =>
    api.post("/activate.json", { code, device_fingerprint });
  const findRoomDeviceByFingerprint = device_fingerprint =>
    api.get("/find_room_device_by_fingerprint.json", { device_fingerprint });
  const getBranchById = branchId =>
    api.get("/find_branch_by_id.json", { id: branchId });
  const findEventByCode = code =>
    api.get(`/find_event_by_code.json?code=${code}`);
  const findEventBySlug = (slug, token) =>
    api.get(`/event/${slug}.json?token=${token}`);
  const createEventMessage = (code, event_message) => {
    const data = new FormData();

    Object.keys(event_message).forEach(key => {
      data.append(`event_message[${key}]`, event_message[key] || "");
    });

    return api.post(`/create_event_message.json?code=${code}`, data);
  };
  const destroyEventMessageWithToken = (eventMessageId, token) =>
    api.delete("/destroy_event_message.json", {
      id: eventMessageId,
      token: token
    });

  const userUpdateCompany = company => {
    const data = new FormData();

    Object.keys(company).forEach(key => {
      data.append(`company[${key}]`, company[key] || "");
    });

    return api.put("/companies/" + company.id + ".json", data);
  };

  const getPromos = q => api.get(`/promos?${qs.stringify({ q })}`);
  const getPromo = promoId => api.get(`/promos/${promoId}`);

  const createPromo = promo => {
    const { promo_contents, created_at, updated_at, url, ...data } = promo;

    const formData = objectToFormData(
      data,
      {
        indices: true
      },
      null,
      "promo"
    );

    return api.post("/promos.json", formData);
  };
  const updatePromo = promo => {
    const {
      promo_contents,
      created_at,
      updated_at,
      url,
      room_ids,
      ...data
    } = promo;

    const formData = objectToFormData(
      data,
      {
        indices: true
      },
      null,
      "promo"
    );

    for (var i = 0; i < room_ids.length; i++) {
      formData.append("promo[room_ids][]", room_ids[i]);
    }

    return api.put("/promos/" + promo.id + ".json", formData);
  };
  const destroyPromo = promo => api.delete("/promos/" + promo.id + ".json");

  const toggleActivePromo = promo =>
    api.post("/promos/" + promo.id + "/toggle_active.json");

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    setHeaders,
    userLogin,
    userVerifyLogin,
    userConfirm,
    userResetPasswordInstructions,
    userResetPassword,
    adminLogin,
    adminVerifyLogin,
    adminGetEventsToPay,

    getCompanies,
    createCompany,
    updateCompany,
    destroyCompany,
    getBillingPlans,
    getCompany,

    getReligions,
    createReligion,
    updateReligion,
    destroyReligion,

    getBackgroundImages,
    createBackgroundImage,
    updateBackgroundImage,
    destroyBackgroundImage,

    getUsers,
    createUser,
    updateUser,
    destroyUser,

    getBranches,
    getBranch,
    createBranch,
    updateBranch,
    destroyBranch,

    getRooms,
    getRoom,
    createRoom,
    updateRoom,
    destroyRoom,

    getEvents,
    getEvent,
    createEvent,
    updateEvent,
    destroyEvent,
    getEventsToPay,
    blockEventMessage,
    unblockEventMessage,
    toggleBlacklistEventMessage,

    getCities,

    getTypeOfBurials,

    getCountries,

    getBranchById,
    activateScreen,
    findRoomDeviceByFingerprint,
    findEventByCode,
    findEventBySlug,
    createEventMessage,
    destroyEventMessageWithToken,

    userUpdateCompany,

    getPromos,
    getPromo,
    createPromo,
    updatePromo,
    destroyPromo,
    toggleActivePromo
  };
};

// let's return back our create method as the default.
export default {
  create
};
