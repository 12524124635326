import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment
} from "semantic-ui-react";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import Styles from "../Styles/LoginComponentStyles";
import queryString from "query-string";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

export default class PasswordEditComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      user: {
        password: "",
        password_confirmation: "",
        reset_password_token: ""
      }
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    this.setState({
      user: {
        ...this.state.user,
        reset_password_token: params.reset_password_token
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.userAccount.fetching && !newProps.userAccount.fetching) {
      if (newProps.userAccount.errors.length) {
        const errors = Object.keys(newProps.userAccount.errors[0].errors).map(
          key => {
            return `${key} ${newProps.userAccount.errors[0].errors[key][0]}`;
          }
        );

        this.setState({ errors });
        return;
      }

      this.context.router.history.push("/");
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      user: { ...this.state.user, [name]: value }
    });
  }

  handleSubmit() {
    this.props.resetPassword(this.state.user, this.state.reset_password_token);
  }

  render() {
    const { password, password_confirmation, errors } = this.state;

    return (
      <Grid
        textAlign="center"
        style={Styles.loginFormUsers}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size="large" autoComplete="off">
            <Segment stacked>
              <Header>
                <Image src={Images.logo} style={Styles.loginLogo} />
              </Header>
              <Form autoComplete="off">
                <ErrorMessagesComponent messages={errors} />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Contraseña"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Confirmación de Contraseña"
                  type="password"
                  name="password_confirmation"
                  value={password_confirmation}
                  onChange={this.handleChange.bind(this)}
                />
                <Button
                  fluid
                  size="large"
                  style={Styles.loginButton}
                  primary
                  loading={this.props.userAccount.fetching}
                  onClick={this.handleSubmit.bind(this)}
                >
                  Cambiar Contraseña
                </Button>
              </Form>
            </Segment>
          </Form>
          <Message style={Styles.loginSegment}>
            <Image centered src={Images.logo} style={Styles.loginLogo} />
          </Message>

          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            closeButton={false}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

PasswordEditComponent.contextTypes = {
  router: PropTypes.object
};
