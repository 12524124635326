import { Colors, Images } from "../../themes";

export default {
  cardContent: {
    padding: 25
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: Colors.gray
  },
  cardWelcome: {
    marginTop: 10,
    marginBottom: 10
  },
  cardButton: {
    backgroundColor: Colors.primary,
    color: Colors.snow,
    borderRadius: 50
  },
  cardIcon: {
    color: Colors.snow
  },
  cardHeaderList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 12,
    paddingBottom: 12,
    color: Colors.gray,
    borderBottomStyle: "solid",
    borderBottomWidth: 3,
    borderBottomColor: Colors.lightgray
  },
  cardHeaderTitle: {
    fontSize: 25,
    marginTop: 10,
    marginBottom: 10
  },
  cardHeaderIcon: {
    marginTop: 5,
    fontSize: 25,
    cursor: "pointer"
  },
  eventRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  listRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: Colors.gray
  },
  text: {
    fontSize: 18,
    color: Colors.gray,
    fontWeight: "400"
  },
  textBold: {
    fontSize: 18,
    color: Colors.gray,
    fontWeight: "bold",
    paddingRight: 15
  },
  textRedBold: {
    fontSize: 18,
    color: Colors.red,
    fontWeight: "bold",
    paddingRight: 15
  },
  cardNewButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  notFoundText: {
    fontSize: 18,
    color: Colors.gray,
    fontWeight: "bold",
    paddingLeft: 0
  },
  cardForm: {
    paddingTop: 25
  },
  cardFormButton: {
    marginTop: 10,
    backgroundColor: Colors.primary,
    color: Colors.snow,
    borderRadius: 50
  },
  cardFormButtonSecondary: {
    marginRight: 20,
    marginTop: 10,
    backgroundColor: Colors.secondary,
    color: Colors.snow,
    borderRadius: 50
  },
  cardFormButtonRed: {
    marginTop: 10,
    backgroundColor: Colors.red,
    color: Colors.snow,
    borderRadius: 50
  },
  segment: {
    marginTop: 10,
    marginBottom: 15
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  address: {
    fontSize: 12
  },
  castButton: {
    backgroundColor: Colors.secondary,
    color: Colors.snow,
    minWidth: "unset"
  }
};
