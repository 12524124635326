import { Colors, Images } from "../../themes";

export default {
  screenContent: {
    backgroundImage: "url(" + Images.bg + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100vh",
    width: "100vw",
    display: "flex",
    margin: 0,
    fontFamily: "Poppins"
  },
  emptyScreenContent: {
    backgroundImage: "url(" + Images.bg + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    fontFamily: "Poppins"
  },
  emptyScreenLogo: {
    margin: "15px",
    height: "10vh",
    maxWidth: 200,
    objectFit: "contain"
  },
  emptyScreenText: {
    lineHeight: 1,
    fontSize: "5.5vh",
    margin: "15px"
  },
  emptyScreenCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20
  },
  logos: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 15
  },
  logo: {
    margin: "0 15px",
    height: "10vh",
    maxWidth: 200,
    objectFit: "contain"
  },
  rightContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  rightContainer: {
    height: "95vh",
    width: "100%"
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30
  },
  instructionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    flex: 1
  },
  instructions: {
    fontSize: "6.5vh",
    fontWeight: "700",
    lineHeight: 1.2,
    color: Colors.snow,
    marginBottom: 25
  },
  instructionsText: {
    fontSize: "4vh",
    lineHeight: 1.2,
    color: Colors.primary,
    marginBottom: 25
  },
  instructionsTextSmall: {
    fontSize: "2.5vh",
    lineHeight: 1.2,
    color: Colors.primary
  },
  code: {
    fontSize: "8vh",
    fontWeight: "600",
    lineHeight: 1,
    color: Colors.snow
  },
  qrContainer: {
    width: "fit-content",
    backgroundColor: Colors.snow,
    borderRadius: 20,
    border: "2px solid",
    borderColor: Colors.lightgray,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 15
  },
  commentCard: {
  },
  commentCardMedia: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: 50,
    border: "2px solid rgba(34, 36, 38, 0.15)",
    padding: "2.5vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  promoCard: {
    height: "95vh",
    width: "95vh",
    maxWidth: "95vh",
    backgroundColor: Colors.snow,
    borderRadius: 20,
    border: "2px solid",
    borderColor: Colors.lightgray,
    padding: 5,
    boxSizing: "border-box"
  },
  promoImageCard: {
    borderRadius: 35,
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
    maxHeight: "100% !important",
    minWidth: "100% !important",
    minHeight: "100% !important",
    objectFit: "cover",
    objectPosition: "center"
  },
  imageCard: {
    borderRadius: 35,
    height: "100% !important",
    width: "100% !important",
    maxWidth: "100% !important",
    maxHeight: "100% !important",
    minWidth: "100% !important",
    minHeight: "100% !important",
    objectFit: "cover",
    objectPosition: "center"
  },
  overlayContainer: {
    position: "absolute",
    bottom: "5vh",
    left: "5vh",
    zIndex: 2,
    pointerEvents: "none"  // Añadido para permitir interacciones con el video/imagen
  },
  avatarContainer: {
    height: 55,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    padding: "7.5px 10px",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 50,
    marginBottom: "-27.5px",
    marginLeft: 12.5,
    zIndex: 3
  },
  avatarContainerSingle: {
    height: 55,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    padding: "7.5px 10px",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 50,
    zIndex: 3
  },
  avatarName: {
    fontSize: "3vh",
    color: Colors.secondary,
    fontWeight: "500",
    lineHeight: 1.35
  },
  commentCardText: {
    fontSize: "3vh",
    fontWeight: "500",
    color: Colors.secondary,
    width: "85vh",
    lineHeight: 1.35,
    padding: "32.5px 20px 20px",
    backgroundColor: "rgba(255,255,255,0.80)",
    borderRadius: 20
  },
  noimage: {
    backgroundColor: "#000",
    borderRadius: 35
  }
};
