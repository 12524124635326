import React, { Component } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { Popup, Form, Grid, Card, Sidebar, Segment, Button, Menu, Modal, Image, Icon, Item, Header, Divider, Container, Dimmer, Loader } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import UserListComponent from '../Shared/UserListComponent'
import CompanyFormComponent from './CompanyFormComponent'

export default class SettingsComponent extends Component {

  componentDidMount() {
    this.props.getUsers()
  }

  render() {
    const company = (this.props.userAccount.user && this.props.userAccount.user.company) || {}

    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon name='chevron left' style={Styles.cardHeaderIcon} onClick={() => { this.context.router.history.goBack() }} />
                <Item style={Styles.cardHeaderTitle}>Perfil</Item>
                <Popup trigger={<Icon name='pencil' style={Styles.cardHeaderIcon} onClick={ () => { this.props.toggleCompanyForm(true, company) } } />} content='Editar Configuración' position='top center' />
              </Card.Header>

              <Item.Group>
                <Item style={Styles.textBold}>Nombre: {company.name}</Item>
                <Item style={Styles.textBold}>Cantidad de pantallas: {company.number_of_screens}</Item>
                <Item style={Styles.textBold}>Plan: {company.billing_plan.title}</Item>
              </Item.Group>

            </Card.Content>
          </Card>

          <Dimmer inverted active={this.props.user.fetching}>
            <Loader inverted />
          </Dimmer>

          <UserListComponent users={this.props.user.users} />
          <CompanyFormComponent />
        </Grid.Column>
      </Grid>
    )
  }
}

SettingsComponent.contextTypes = {
  router: PropTypes.object
}
