import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Card, Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import BranchListComponent from "../Shared/BranchListComponent";
import EventFormComponent from "./EventFormComponent";
import PromoActiveListComponent from '../Shared/PromoActiveListComponent';

export default class DashboardComponent extends Component {
  componentDidMount() {
    this.getBranches();
    this.getPromos();
  }

  getBranches() {
    this.props.getBranches();
  }

  getPromos() {
    this.props.getPromos({ is_active_eq: true });
  }

  allowCreateEvents() {
    return this.props.branch.branches.some(b => {
      return b.rooms.length > 0;
    });
  }

  render() {
    const allowCreateEvents = this.allowCreateEvents();

    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header>
                <Grid centered columns={2} style={Styles.cardHeader}>
                  <span style={Styles.cardWelcome}>
                    Bienvenido a Nitpoint
                  </span>

                  <Button
                    style={Styles.cardButton}
                    disabled={!allowCreateEvents}
                    onClick={() => {
                      this.props.toggleEventForm(true);
                    }}
                  >
                    <Icon name="plus" style={Styles.cardIcon} /> Crear Evento
                  </Button>
                </Grid>
              </Card.Header>
            </Card.Content>
          </Card>

          <Dimmer inverted active={this.props.branch.fetching}>
            <Loader inverted />
          </Dimmer>

          <BranchListComponent
            branches={this.props.branch.branches}
            refreshBranches={this.getBranches.bind(this)}
          />

          <br />

          <PromoActiveListComponent
            promos={this.props.promo.promos} />

        </Grid.Column>
        <EventFormComponent />
      </Grid>
    );
  }
}

DashboardComponent.contextTypes = {
  router: PropTypes.object
};
