import { put, call } from "redux-saga/effects";
import CompanyActions from "../redux/CompanyRedux";
import storage from "redux-persist/lib/storage";

export function* getCompanies(api, action) {
  const resp = yield call(api.getCompanies);

  if (resp.ok) {
    yield put(CompanyActions.getCompaniesSuccess(resp.data));
  } else {
    yield put(CompanyActions.getCompaniesFailure(resp.data));
  }
}

export function* createCompany(api, action) {
  const { company } = action;

  const resp = yield call(api.createCompany, company);

  if (resp.ok) {
    yield put(CompanyActions.createCompanySuccess(resp.data));
  } else {
    yield put(CompanyActions.createCompanyFailure(resp.data));
  }
}

export function* updateCompany(api, action) {
  const { company } = action;

  const resp = yield call(api.updateCompany, company);

  if (resp.ok) {
    yield put(CompanyActions.updateCompanySuccess(resp.data));
  } else {
    yield put(CompanyActions.updateCompanyFailure(resp.data));
  }
}

export function* destroyCompany(api, action) {
  const { company } = action;

  const resp = yield call(api.destroyCompany, company);

  if (resp.ok) {
    yield put(CompanyActions.destroyCompanySuccess(resp.data));
  } else {
    yield put(CompanyActions.destroyCompanyFailure(resp.data));
  }
}

export function* getCompany(api, action) {
  const { companyId } = action;

  const resp = yield call(api.getCompany, companyId);

  if (resp.ok) {
    storage.setItem("company", JSON.stringify(resp.data));

    yield call(api.setHeaders, {
      "X-Admin-CompanyId": companyId
    });

    yield put(CompanyActions.getCompanySuccess(resp.data));
  } else {
    storage.removeItem("company");

    yield call(api.setHeaders, {
      "X-Admin-CompanyId": null
    });

    yield put(CompanyActions.getCompanyFailure(resp.data));
  }
}

export function* getBillingPlans(api, action) {
  const resp = yield call(api.getBillingPlans);

  if (resp.ok) {
    yield put(CompanyActions.getBillingPlansSuccess(resp.data));
  } else {
    yield put(CompanyActions.getBillingPlansFailure(resp.data));
  }
}
