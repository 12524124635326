import { put, call } from 'redux-saga/effects'
import UserActions from '../redux/UserRedux'

export function * getUsers (api, action) {
  const resp = yield call(api.getUsers)

  if (resp.ok) {
    yield put(UserActions.getUsersSuccess(resp.data))
  } else {
    yield put(UserActions.getUsersFailure(resp.data))
  }
}

export function * createUser (api, action) {
  const { user } = action

  const resp = yield call(api.createUser, user)

  if (resp.ok) {
    yield put(UserActions.createUserSuccess(resp.data))
  } else {
    yield put(UserActions.createUserFailure(resp.data))
  }
}

export function * updateUser (api, action) {
  const { user } = action

  const resp = yield call(api.updateUser, user)

  if (resp.ok) {
    yield put(UserActions.updateUserSuccess(resp.data))
  } else {
    yield put(UserActions.updateUserFailure(resp.data))
  }
}

export function * destroyUser (api, action) {
  const { user } = action

  const resp = yield call(api.destroyUser, user)

  if (resp.ok) {
    yield put(UserActions.destroyUserSuccess(resp.data))
  } else {
    yield put(UserActions.destroyUserFailure(resp.data))
  }
}