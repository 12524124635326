import { put, call } from "redux-saga/effects";
import PublicControllerActions from "../redux/PublicControllerRedux";
import storage from "redux-persist/lib/storage";

export function* getBranchById(api, action) {
  const { branchId } = action;

  const resp = yield call(api.getBranchById, branchId);

  if (resp.ok) {
    yield put(PublicControllerActions.getBranchByIdSuccess(resp.data));
  } else {
    yield put(PublicControllerActions.getBranchByIdFailure(resp.data.errors));
  }
}

export function* activateScreen(api, action) {
  const { code } = action;

  const resp = yield call(api.activateScreen, code);

  if (resp.ok) {
    storage.setItem("device_fingerprint", resp.data.device_fingerprint);
    yield put(PublicControllerActions.activateScreenSuccess(resp.data));
  } else {
    yield put(PublicControllerActions.activateScreenFailure(resp.data.errors));
  }
}

export function* findRoomDeviceByFingerprint(api, action) {
  const { deviceFingerprint } = action;

  const resp = yield call(api.findRoomDeviceByFingerprint, deviceFingerprint);

  if (resp.ok) {
    yield put(
      PublicControllerActions.findRoomDeviceByFingerprintSuccess(resp.data)
    );
  } else {
    storage.removeItem("device_fingerprint");
    yield put(
      PublicControllerActions.findRoomDeviceByFingerprintFailure(
        resp.data.errors
      )
    );
  }
}

export function* findEventByCode(api, action) {
  const { code } = action;

  const resp = yield call(api.findEventByCode, code);

  if (resp.ok) {
    yield put(PublicControllerActions.findEventByCodeSuccess(resp.data));
  } else {
    yield put(PublicControllerActions.findEventByCodeFailure(resp.data.errors));
  }
}

export function* createEventMessage(api, action) {
  const { code, eventMessage } = action;

  const resp = yield call(api.createEventMessage, code, eventMessage);

  if (resp.ok) {
    yield put(PublicControllerActions.createEventMessageSuccess(resp.data));
  } else {
    yield put(
      PublicControllerActions.createEventMessageFailure(resp.data.errors)
    );
  }
}

export function* findEventBySlug(api, action) {
  const { slug, token } = action;

  const resp = yield call(api.findEventBySlug, slug, token);

  if (resp.ok) {
    yield put(PublicControllerActions.findEventBySlugSuccess(resp.data));
  } else {
    yield put(PublicControllerActions.findEventBySlugFailure(resp.data.errors));
  }
}

export function* removeEventMessageWithToken(api, action) {
  const { eventMessageId, token } = action;

  const resp = yield call(
    api.destroyEventMessageWithToken,
    eventMessageId,
    token
  );

  if (resp.ok) {
    yield put(
      PublicControllerActions.removeEventMessageWithTokenSuccess(resp.data)
    );
  } else {
    yield put(
      PublicControllerActions.removeEventMessageWithTokenFailure(
        resp.data.errors
      )
    );
  }
}
