import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Grid, Card, Sidebar, Segment, Button, Menu, Modal, Image, Icon, Item, Header, Dimmer, Loader, Popup, Confirm } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import moment from 'moment'

export default class ReportsComponent extends Component {

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon name='chevron left' style={Styles.cardHeaderIcon} onClick={() => { this.context.router.history.goBack() }} />
                <Item style={Styles.cardHeaderTitle}>Reportes</Item>
                <Item />
              </Card.Header>

              <Item.Group divided relaxed>
                <Item as='a' onClick={ () => { this.context.router.history.push(`/admins/reports/events`) }}>
                  <Item.Content>
                    <Item.Header style={Styles.textBold}>
                      Eventos contabilizados
                    </Item.Header>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    )
  }
}

ReportsComponent.contextTypes = {
  router: PropTypes.object
}
