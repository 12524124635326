import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment
} from "semantic-ui-react";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import Styles from "../Styles/LoginComponentStyles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

export default class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };
  }

  handleChange(e, { name, value }) {
    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.userAccount.fetching && !newProps.userAccount.fetching) {
      if (newProps.userAccount.errors.length === 0) {
        toast.success("Instrucciones enviadas a su correo");
        this.setState({ email: "" });
        return;
      }
    }
  }

  render() {
    return (
      <Grid
        textAlign="center"
        style={Styles.loginFormUsers}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form
            size="large"
            loading={this.props.userAccount.fetching}
            autoComplete="off"
          >
            <Segment stacked>
              <Header>
                <Image src={Images.logo} style={Styles.loginLogo} />
              </Header>

              {this.props.userAccount.errors.length > 0 && (
                <ErrorMessagesComponent
                  messages={this.props.userAccount.errors[0].errors.email}
                />
              )}

              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-Mail"
                name="email"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
              />

              <Button
                fluid
                size="large"
                style={Styles.loginButton}
                primary
                onClick={() => {
                  this.props.resetPasswordInstructions(this.state.email);
                }}
              >
                Enviar instrucciones
              </Button>
            </Segment>
          </Form>

          <Message style={Styles.loginSegment}>
            <Image centered src={Images.logo} style={Styles.loginLogo} />
          </Message>

          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            closeButton={false}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

ForgotPasswordComponent.contextTypes = {
  router: PropTypes.object
};
