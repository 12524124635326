import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown,
  Popup,
  Dimmer,
  Loader
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import PromoActions from "../../redux/PromoRedux";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import { toast } from "react-toastify";
import moment from "moment";

class PromoListComponent extends Component {
  componentDidMount() {
    this.props.getPromos();
  }

  renderPromos() {
    if (!this.props.promo.promos || this.props.promo.promos.length == 0) {
      return;
    }

    return this.props.promo.promos.map(p => {
      return (
        <Card fluid key={p.id}>
          <Card.Content>
            <Card.Header style={Styles.cardHeaderList}>
              <Item>{p.name}</Item>

              <Item>{`de ${p.starts_at} a ${p.ends_at} hs`}</Item>

              {window.ability.can("manage", "Promo") && (
                <Item>
                  <Popup
                    trigger={
                      <Icon
                        name={p.is_active ? "pause" : "play"}
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.props.toggleActivePromo(p);
                        }}
                      />
                    }
                    content={p.is_active ? "Deshabilitar" : "Habilitar"}
                    position="top center"
                  />
                </Item>
              )}

              {window.ability.can("update", "Promo") && (
                <Item>
                  <Popup
                    trigger={
                      <Icon
                        name="edit"
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.context.router.history.push(`/promos/${p.id}`);
                        }}
                      />
                    }
                    content="Editar promoción"
                    position="top center"
                  />
                </Item>
              )}
            </Card.Header>
          </Card.Content>
        </Card>
      );
    });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.promo.creatingPromo && !newProps.promo.creatingPromo) {
      if (newProps.promo.errors.length) {
        toast.error(newProps.promo.errors.join(", "));
      } else {
        toast.success("Promoción eliminada correctamente");
      }
    }

    if (this.props.promo.updatingPromo && !newProps.promo.updatingPromo) {
      toast.success("Promoción actualizada correctamente");
    }
  }

  destroyPromo() {
    this.props.destroyPromo(this.state.promoToDestroy);
    this.setState({ destroyLayerHidden: true, promoToDestroy: null });
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Dimmer inverted active={this.props.promo.updatingPromo}>
            <Loader inverted />
          </Dimmer>

          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header>
                <Grid centered columns={2} style={Styles.cardHeaderList}>
                  <Icon
                    name="chevron left"
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.context.router.history.goBack();
                    }}
                  />
                  <Item style={Styles.cardHeaderTitle}>Promociones</Item>
                  {window.ability.can("create", "Promo") && (
                    <Button
                      style={Styles.cardButton}
                      onClick={() => {
                        this.context.router.history.push("/promos/new");
                      }}
                    >
                      <Icon name="plus" style={Styles.cardIcon} />
                      Crear Promoción
                    </Button>
                  )}
                </Grid>
              </Card.Header>

              {this.props.promo.promos.length > 0 && (
                <Item.Group divided relaxed>
                  {this.renderPromos()}
                </Item.Group>
              )}

              {this.props.promo.promos.length == 0 && (
                <Card.Content style={Styles.cardContent}>
                  <Item.Group divided relaxed style={Styles.notFoundText}>
                    Aún no has creado ninguna promoción
                  </Item.Group>
                </Card.Content>
              )}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { promo } = state;

  return {
    promo
  };
};

const mapStateToDispatch = dispatch => ({
  getPromos: () => dispatch(PromoActions.getPromosRequest()),
  togglePromoForm: (isPromoFormVisible, promo) =>
    dispatch(PromoActions.togglePromoForm(isPromoFormVisible, promo)),
  createPromo: promo => dispatch(PromoActions.createUserRequest(promo)),
  updatePromo: promo => dispatch(PromoActions.updateUserRequest(promo)),
  toggleActivePromo: promo =>
    dispatch(PromoActions.toggleActivePromoRequest(promo))
});

export default connect(mapStateToProps, mapStateToDispatch)(PromoListComponent);

PromoListComponent.contextTypes = {
  router: PropTypes.object
};
