import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Button,
  Image,
  Icon,
  Item,
  Confirm,
  Checkbox
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import Datetime from "react-datetime";
import moment from "moment";
import Dropzone from "react-dropzone";
import { Images } from "../../themes";

export default class PromoComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promo: {
        promo_contents: [],
        room_ids: []
      },
      destroyLayerHidden: true,
      promoToDestroy: null,
      promo_contents_to_upload: []
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== "new") {
      this.props.getPromo(this.props.match.params.id);
    }

    this.props.getBranches();
  }

  componentWillUnmount() {
    this.props.clearPromoForm();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.promo.promo && nextProps.promo.promo) {
      this.setState({
        promo: nextProps.promo.promo
      });
    }

    if (this.props.promo.creatingPromo && !nextProps.promo.creatingPromo) {
      if (nextProps.promo.errors.length == 0) {
        this.context.router.history.push("/");
      }
    }

    if (this.props.promo.updatingPromo && !nextProps.promo.updatingPromo) {
      if (nextProps.promo.errors.length == 0) {
        this.context.router.history.push("/");
      }
    }

    if (this.props.promo.destroyingPromo && !nextProps.promo.destroyingPromo) {
      if (nextProps.promo.errors.length == 0) {
        this.context.router.history.push("/");
      }
    }
  }

  savePromo() {
    let { promo, promo_contents_to_upload } = this.state;

    if (promo.starts_at && typeof promo.starts_at === "object") {
      promo.starts_at = promo.starts_at.format("HH:mm");
    }

    if (promo.ends_at && typeof promo.ends_at === "object") {
      promo.ends_at = promo.ends_at.format("HH:mm");
    }

    const promo_contents_attributes = [
      ...this.state.promo.promo_contents,
      ...promo_contents_to_upload
    ];

    if (promo.id) {
      this.props.updatePromo({
        ...promo,
        promo_contents_attributes
      });
    } else {
      this.props.createPromo({
        ...promo,
        promo_contents_attributes
      });
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      promo: { ...this.state.promo, [name]: value }
    });
  }

  handleDroppedFiles(files) {
    this.setState({
      promo_contents_to_upload: files.map(file => {
        return {
          file,
          preview: URL.createObjectURL(file),
          duration_in_seconds: 10
        };
      })
    });
  }

  destroyPromo() {
    this.props.destroyPromo(this.state.promo);
    this.setState({ destroyLayerHidden: true, promoToDestroy: null });
  }

  render() {
    const { branches } = this.props.branch;

    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon
                  name="chevron left"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.context.router.history.goBack();
                  }}
                />
                <Item style={Styles.cardHeaderTitle}>
                  {this.state.promo.id ? "Editar" : "Crear"} Promoción
                </Item>
                <Item />
              </Card.Header>

              <ErrorMessagesComponent messages={this.props.promo.errors} />
              <Form
                style={Styles.cardForm}
                loading={
                  this.props.promo.fetching ||
                  this.props.promo.creatingPromo ||
                  this.props.promo.updatingPromo
                }
                autoComplete="off"
              >
                <Form.Input
                  fluid
                  label="Nombre"
                  placeholder="Nombre"
                  name="name"
                  value={this.state.promo.name}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.TextArea
                  fluid
                  label="Descripción"
                  placeholder="Descripción"
                  name="description"
                  value={this.state.promo.description}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Hora de inicio</label>

                    <Datetime
                      dateFormat={false}
                      timeFormat="HH:mm"
                      value={
                        this.state.promo.starts_at
                          ? moment(this.state.promo.starts_at, "HH:mm")
                          : ""
                      }
                      inputProps={{
                        name: "starts_at",
                        placeholder: "Hora de inicio"
                      }}
                      closeOnSelect
                      onChange={date => {
                        this.handleChange(null, {
                          name: "starts_at",
                          value: date
                        });
                      }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Hora de finalización</label>

                    <Datetime
                      dateFormat={false}
                      timeFormat="HH:mm"
                      value={
                        this.state.promo.ends_at
                          ? moment(this.state.promo.ends_at, "HH:mm")
                          : ""
                      }
                      inputProps={{
                        name: "ends_at",
                        placeholder: "Hora de finalización"
                      }}
                      closeOnSelect
                      onChange={date => {
                        this.handleChange(null, {
                          name: "ends_at",
                          value: date
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label>Indique áreas donde se mostrará la promoción</label>
                </Form.Field>
                {branches.map(b =>
                  b.rooms.map(r => (
                    <Form.Field>
                      <Checkbox
                        label={`${r.name} (${b.name})`}
                        checked={this.state.promo.room_ids.indexOf(r.id) >= 0}
                        onChange={(e, input) => {
                          this.setState({
                            promo: {
                              ...this.state.promo,
                              room_ids: input.checked
                                ? [...this.state.promo.room_ids, r.id]
                                : this.state.promo.room_ids.filter(
                                    id => id !== r.id
                                  )
                            }
                          });
                        }}
                      />
                    </Form.Field>
                  ))
                )}

                <Form.Field>
                  <label>
                    Seleccione archivos para subir. Puede subir cualquier
                    formato de imagen.
                  </label>
                </Form.Field>

                <Dropzone
                  onDrop={this.handleDroppedFiles.bind(this)}
                  accept="image/jpeg,image/jpg,image/gif,image/png,video/*"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button style={Styles.cardFormButtonSecondary}>
                          <Icon name="upload" style={Styles.cardIcon} />
                          Explorar archivos
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <Grid celled>
                  {this.state.promo.promo_contents
                    .filter(pc => !pc["_destroy"])
                    .map(pc => {
                      return (
                        <Grid.Row key={pc.id}>
                          <Grid.Column width={6}>
                            {pc.is_video ? (
                              <video width="100%" autoplay="autoplay" loop>
                                <source src={pc.file_preview_url}></source>
                              </video>
                            ) : (
                              <Image src={Images.remote(pc.file_preview_url)} />
                            )}
                          </Grid.Column>
                          <Grid.Column width={10}>
                            <Form.Input
                              fluid
                              label="Duración en segundos"
                              placeholder="Duración en segundos"
                              name="duration_in_seconds"
                              value={pc.duration_in_seconds}
                              onChange={e =>
                                this.setState({
                                  promo: {
                                    ...this.state.promo,
                                    promo_contents: this.state.promo.promo_contents.map(
                                      el => {
                                        if (el.id === pc.id) {
                                          return {
                                            ...el,
                                            duration_in_seconds: e.target.value
                                          };
                                        }

                                        return el;
                                      }
                                    )
                                  }
                                })
                              }
                            />
                            <Button
                              style={Styles.cardFormButtonRed}
                              onClick={() =>
                                this.setState({
                                  promo: {
                                    ...this.state.promo,
                                    promo_contents: this.state.promo.promo_contents.map(
                                      el => {
                                        if (el.id === pc.id) {
                                          return {
                                            ...el,
                                            _destroy: 1
                                          };
                                        }

                                        return el;
                                      }
                                    )
                                  }
                                })
                              }
                            >
                              Eliminar archivo
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      );
                    })}

                  {this.state.promo_contents_to_upload.map((file, i) => {
                    return (
                      <Grid.Row key={i}>
                        <Grid.Column width={6}>
                          <Image src={file.preview} />
                        </Grid.Column>
                        <Grid.Column width={10}>
                          <Form.Input
                            fluid
                            label="Duración en segundos"
                            placeholder="Duración en segundos"
                            name="duration_in_seconds"
                            defaultValue={10}
                            value={file.duration_in_seconds}
                            onChange={e =>
                              this.setState({
                                promo_contents_to_upload: this.state.promo_contents_to_upload.map(
                                  el => {
                                    if (el === file) {
                                      return {
                                        ...el,
                                        duration_in_seconds: e.target.value
                                      };
                                    }

                                    return el;
                                  }
                                )
                              })
                            }
                          />
                          <Button
                            style={Styles.cardFormButtonRed}
                            onClick={() =>
                              this.setState({
                                promo_contents_to_upload: this.state.promo_contents_to_upload.filter(
                                  el => el !== file
                                )
                              })
                            }
                          >
                            Eliminar
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    );
                  })}
                </Grid>
                <Item style={Styles.actions}>
                  <Item>
                    <Button
                      style={Styles.cardFormButton}
                      type="submit"
                      onClick={() => {
                        this.savePromo();
                      }}
                    >
                      Guardar
                    </Button>
                  </Item>

                  {this.state.promo.id && (
                    <Button
                      style={Styles.cardFormButtonRed}
                      type="submit"
                      onClick={() => {
                        this.setState({
                          destroyLayerHidden: false,
                          promoToDestroy: this.state.promo
                        });
                      }}
                    >
                      Eliminar promoción
                    </Button>
                  )}
                </Item>
              </Form>

              {this.state.promoToDestroy && (
                <Confirm
                  open={!this.state.destroyLayerHidden}
                  content={`Seguro desea eliminar ${this.state.promoToDestroy.name}?`}
                  cancelButton="Cancelar"
                  confirmButton="Aceptar"
                  onCancel={() => {
                    this.setState({
                      destroyLayerHidden: true,
                      promoToDestroy: null
                    });
                  }}
                  onConfirm={() => {
                    this.destroyPromo();
                  }}
                />
              )}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

PromoComponent.contextTypes = {
  router: PropTypes.object
};
