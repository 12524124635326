import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import ReligionActions from "../../redux/ReligionRedux";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import loadImage from "blueimp-load-image";
import { dataURItoBlob } from "../../lib";

class ReligionFormComponent extends Component {
  constructor(props) {
    super(props);

    this.initial_state = {
      religion: {
        name: "",
        logo: null
      },
      imagePreviewUrl: null
    };

    this.state = this.initial_state;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.religion.editReligion && nextProps.religion.editReligion) {
      this.setState({
        religion: nextProps.religion.editReligion,
        imagePreviewUrl: null
      });
    }

    if (
      !this.props.religion.isReligionFormVisible &&
      nextProps.religion.isReligionFormVisible
    ) {
      if (!nextProps.religion.editReligion) {
        this.setState(this.initial_state);
      }
    }
  }

  saveReligion() {
    if (this.state.religion.id) {
      this.props.updateReligion(this.state.religion);
    } else {
      this.props.createReligion(this.state.religion);
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      religion: { ...this.state.religion, [name]: value }
    });
  }

  handleImageChange(e) {
    e.preventDefault();

    loadImage(
      e.target.files[0],
      img => {
        const b64 = img.toDataURL();

        this.setState({
          religion: {
            ...this.state.religion,
            logo: dataURItoBlob(b64),
            logo_url: null
          },
          imagePreviewUrl: b64
        });
      },
      { maxWidth: 2000, orientation: true }
    );
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon="close"
        open={this.props.religion.isReligionFormVisible}
        onClose={() => {
          this.props.closeReligionForm();
        }}
      >
        <Modal.Header>
          {this.state.religion.id ? "Editar" : "Crear"} Religión
        </Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent messages={this.props.religion.errors} />
          <Form
            loading={
              this.props.religion.creatingReligion ||
              this.props.religion.updatingReligion
            }
            autoComplete="off"
          >
            <Form.Input
              fluid
              label="Nombre"
              placeholder="Nombre"
              name="name"
              value={this.state.religion.name}
              onChange={this.handleChange.bind(this)}
            />

            <Form.Field>
              <label>Logo de Religión</label>
              <label className="fileContainer fileContainerSmall">
                <Icon name="upload" className="fileContainerIcon" />
                Seleccionar archivo
                <input
                  type="file"
                  onChange={e => this.handleImageChange(e)}
                  accept="image/*"
                />
              </label>
            </Form.Field>

            <div>
              {this.state.imagePreviewUrl && (
                <Image
                  src={this.state.imagePreviewUrl}
                  size="medium"
                  centered
                />
              )}
              {this.state.religion.logo_url && (
                <Image
                  src={Images.remote(this.state.religion.logo_url)}
                  size="medium"
                  centered
                />
              )}
            </div>

            <Button
              style={Styles.cardButton}
              type="submit"
              onClick={() => {
                this.saveReligion();
              }}
            >
              Guardar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { religion } = state;

  return {
    religion
  };
};

const mapStateToDispatch = dispatch => ({
  closeReligionForm: () => dispatch(ReligionActions.toggleReligionForm(false)),
  createReligion: religion =>
    dispatch(ReligionActions.createReligionRequest(religion)),
  updateReligion: religion =>
    dispatch(ReligionActions.updateReligionRequest(religion))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ReligionFormComponent);
