import { connect } from 'react-redux'
import ScreenComponent from '../../components/Shared/ScreenComponent'
import RoomActions from '../../redux/RoomRedux'

const mapStateToProps = (state) => {
  const { room } = state

  return {
    room
  }
}

const mapStateToDispatch = (dispatch) => ({
  getRoom: (id) => dispatch(RoomActions.getRoomRequest(id))
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreenComponent)
