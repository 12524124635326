import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userLoginRequest: ['email', 'password'],
  userLoginSuccess: ['user'],
  userLoginFailure: ['errors'],
  userLoginVerify: ['user'],
  userLoginVerifySuccess: ['user'],
  userLoginVerifyFailure: ['errors'],
  userConfirmRequest: ['confirmationToken'],
  userConfirmSuccess: ['user'],
  userConfirmFailure: ['errors'],
  userResetPasswordInstructionsRequest: ['email'],
  userResetPasswordInstructionsSuccess: [''],
  userResetPasswordInstructionsFailure: ['errors'],
  userResetPasswordRequest: ['user'],
  userResetPasswordSuccess: ['user'],
  userResetPasswordFailure: ['errors'],
  updateCompanyScreensAvailable: ['numberOfScreens'],
  userReset: null,
  userUpdateCompanyRequest: ['company'],
  userUpdateCompanySuccess: ['company'],
  userUpdateCompanyFailure: ['errors'],
  userToggleCompanyForm: ['isCompanyFormVisible', 'editCompany'],
})

export const UserAccountTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: null,
  errors: [],
  fetching: false,
  isCompanyFormVisible: false,
  editCompany: null
})

/* ------------- Reducers ------------- */

export const userLoginRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const userLoginSuccess = (state: Object, { user }: Object) => 
  state.merge({
    user,
    fetching: false
  })

export const userLoginFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const userLoginVerify = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const userLoginVerifySuccess = (state: Object, { user }: Object) => 
  state.merge({
    user,
    fetching: false
  })

export const userLoginVerifyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const userConfirmRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const userConfirmSuccess = (state: Object, { user }: Object) => 
  state.merge({
    user,
    fetching: false
  })

export const userConfirmFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const userResetPasswordInstructionsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const userResetPasswordInstructionsSuccess = (state: Object) => 
  state.merge({
    fetching: false
  })

export const userResetPasswordInstructionsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const userResetPasswordRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const userResetPasswordSuccess = (state: Object, { user }: Object) => 
  state.merge({
    user,
    fetching: false
  })

export const userResetPasswordFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const updateCompanyScreensAvailable = (state: Object, { numberOfScreens }: Object) =>
  state.merge({
    user: { ...state.user, company: { ...state.user.company, screens_available: state.user.company.screens_available + numberOfScreens }}
  })

export const userReset = (state: Object) =>
  INITIAL_STATE

export const userUpdateCompanyRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  })

export const userUpdateCompanySuccess = (state: Object, { company }: Object) => {
  return state.merge({ 
    fetching: false,
    user: { ...state.user, company },
    isCompanyFormVisible: false,
    editCompany: null
  })
}

export const userUpdateCompanyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const userToggleCompanyForm = (state: Object, { isCompanyFormVisible, editCompany }: Object) =>
  state.merge({
    isCompanyFormVisible,
    editCompany,
    errors: []
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_LOGIN_REQUEST]: userLoginRequest,
  [Types.USER_LOGIN_SUCCESS]: userLoginSuccess,
  [Types.USER_LOGIN_FAILURE]: userLoginFailure,
  [Types.USER_LOGIN_VERIFY]: userLoginVerify,
  [Types.USER_LOGIN_VERIFY_SUCCESS]: userLoginVerifySuccess,
  [Types.USER_LOGIN_VERIFY_FAILURE]: userLoginVerifyFailure,
  [Types.USER_CONFIRM_REQUEST]: userConfirmRequest,
  [Types.USER_CONFIRM_SUCCESS]: userConfirmSuccess,
  [Types.USER_CONFIRM_FAILURE]: userConfirmFailure,
  [Types.USER_RESET_PASSWORD_INSTRUCTIONS_REQUEST]: userResetPasswordInstructionsRequest,
  [Types.USER_RESET_PASSWORD_INSTRUCTIONS_SUCCESS]: userResetPasswordInstructionsSuccess,
  [Types.USER_RESET_PASSWORD_INSTRUCTIONS_FAILURE]: userResetPasswordInstructionsFailure,
  [Types.USER_RESET_PASSWORD_REQUEST]: userResetPasswordRequest,
  [Types.USER_RESET_PASSWORD_SUCCESS]: userResetPasswordSuccess,
  [Types.USER_RESET_PASSWORD_FAILURE]: userResetPasswordFailure,
  [Types.UPDATE_COMPANY_SCREENS_AVAILABLE]: updateCompanyScreensAvailable,
  [Types.USER_RESET]: userReset,
  [Types.USER_UPDATE_COMPANY_REQUEST]: userUpdateCompanyRequest,
  [Types.USER_UPDATE_COMPANY_SUCCESS]: userUpdateCompanySuccess,
  [Types.USER_UPDATE_COMPANY_FAILURE]: userUpdateCompanyFailure,
  [Types.USER_TOGGLE_COMPANY_FORM]: userToggleCompanyForm,
})