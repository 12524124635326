import { Colors, Images } from "../../themes";

export default {
  screenContent: {
    backgroundColor: "#d7eaf1",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  portrait: {
    position: "relative",
    height: 300,
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 125
  },
  settingsButton: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#fff",
    borderRadius: 50,
    margin: 0,
    padding: 0,
    width: 45,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 0.8,
    zIndex: 999,
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
  },
  settingsIcon: {
    margin: 0,
    fontSize: 30
  },
  shareButton: {
    fontFamily: "Raleway",
    lineHeight: "125%",
    width: 250,
    marginTop: 30,
    backgroundColor: "rgb(55, 162, 204)",
    borderRadius: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  shareIcon: {
    paddingTop: 5,
    color: "#fff",
    fontSize: 30
  },
  imageContainer: {
    position: "absolute",
    top: -125,
    width: 250,
    height: 250,
    backgroundColor: Colors.snow,
    borderRadius: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
  },
  image: {
    width: 240,
    borderRadius: 240
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 35
  },
  eventCard: {
    fontFamily: "Libre Baskerville",
    position: "relative",
    width: "90%",
    marginTop: 150,
    maxWidth: 750,
    marginBottom: 35,
    padding: 50,
    paddingTop: 150,
    backgroundColor: Colors.snow,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
  },
  eventCardHeader: {
    fontSize: 50,
    fontWeight: "bold",
    color: Colors.gray,
    lineHeight: "120%",
    textAlign: "center",
    marginBottom: 35
  },
  eventCardDates: {
    textAlign: "center",
    fontSize: 35,
    color: Colors.gray,
    lineHeight: 1
  },
  timelineContainer: {
    paddingTop: 15,
    paddingBottom: 100,
    display: "contents",
    height: "100%"
  },
  imageCard: {
    marginBottom: 15
  },
  deleteIcon: {
    cursor: "pointer",
    position: "absolute",
    bottom: 5,
    right: 0,
    margin: 0,
    fontSize: 20,
    color: Colors.red
  },
  companyLogo: {
    height: 100,
    maxWidth: 200,
    objectFit: "contain"
  },
  companyCard: {
    marginTop: -40,
    fontFamily: "Libre Baskerville",
    position: "relative",
    width: "90%",
    maxWidth: 750,
    padding: 20,
    backgroundColor: Colors.snow,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
  }
};
