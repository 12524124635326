import { connect } from 'react-redux'
import LoginComponent from '../../components/Users/LoginComponent'
import UserAccountActions from '../../redux/UserAccountRedux'

const mapStateToProps = (state) => {
  const { userAccount } = state
  
  return {
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  login: (email, password) => dispatch(UserAccountActions.userLoginRequest(email, password)),
  loginVerify: (user) => dispatch(UserAccountActions.userLoginVerify(user))
})

export default connect(mapStateToProps, mapStateToDispatch)(LoginComponent)
