import { connect } from 'react-redux'
import EventsToPayComponent from '../../../components/Shared/Reports/EventsToPayComponent'
import CompanyActions from '../../../redux/CompanyRedux'
import EventActions from '../../../redux/EventRedux'

const mapStateToProps = (state) => {
  const { event, company } = state

  return {
    company,
    event
  }
}

const mapStateToDispatch = (dispatch) => ({
  getCompany: (companyId) => dispatch(CompanyActions.getCompanyRequest(companyId)),
  getEventsToPay: (q) => dispatch(EventActions.getEventsToPayRequest(q)),
  clearCompany: () => dispatch(CompanyActions.clearCompany())
})

export default connect(mapStateToProps, mapStateToDispatch)(EventsToPayComponent)
