import React, { Component } from 'react'
import Loader from 'react-loader'
import PropTypes from 'prop-types'
import { Form, Grid, Card, Sidebar, Segment, Button, Menu, Modal, Image, Icon, Item, Header, Dropdown, Confirm, Popup } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import { connect } from 'react-redux'
import UserActions from '../../redux/UserRedux'
import ErrorMessagesComponent from './ErrorMessagesComponent'
import UserFormComponent from './UserFormComponent'
import { toast } from 'react-toastify'

class UserListComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      destroyLayerHidden: true,
      userToDestroy: null
    }
  }

  renderUsers() {
    if (!this.props.users) {
      return
    }

    return this.props.users.map((u) => {
      return (
        <Item key={u.id}>
          <Item.Content verticalAlign='middle'>
            <Item.Meta style={Styles.textBold}>{u.first_name} {u.last_name} ({u.role_name})</Item.Meta>
          </Item.Content>

          {window.ability.can('update', 'User') && <Popup trigger={<Icon name='pencil' style={Styles.cardHeaderIcon} onClick={() => { this.props.toggleUserForm(true, u) }} />} content='Editar Usuario' position='top center' />}
          {window.ability.can('delete', 'User') && <Popup trigger={<Icon name='trash' style={Styles.cardHeaderIcon} onClick={() => { this.setState({ destroyLayerHidden: false, userToDestroy: u }) }} />} content='Eliminar Usuario' position='top center' />}
        </Item>
      )
    })
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user.destroyingUser && !newProps.user.destroyingUser) {
      if (newProps.user.errors.length) {
        toast.error(newProps.user.errors.join(", "));
      }
      else {
        toast.success("Usuario eliminado correctamente");
      }
    }
  }

  destroyUser() {
    this.props.destroyUser(this.state.userToDestroy)
    this.setState({ destroyLayerHidden: true, userToDestroy: null })
  }

  render() {
    return (
      <Card fluid>
        <Card.Content style={Styles.cardContent}>
          <Card.Header >
            <Grid centered columns={2} style={Styles.cardHeaderList}>
              <Item style={Styles.cardHeaderTitle}>Usuarios</Item>
              {window.ability.can('create', 'User') && <Button style={Styles.cardButton} onClick={() => { this.props.toggleUserForm(true) }}><Icon name='plus' style={Styles.cardIcon} /> Crear Usuario</Button>}
            </Grid>
          </Card.Header>

          <Item.Group divided relaxed>
            {this.renderUsers()}
          </Item.Group>

          {this.state.userToDestroy && <Confirm
            open={!this.state.destroyLayerHidden}
            content={`Seguro desea eliminar ${this.state.userToDestroy.first_name}?`}
            cancelButton='Cancelar'
            confirmButton="Aceptar"
            onCancel={() => { this.setState({ destroyLayerHidden: true, userToDestroy: null }) }}
            onConfirm={() => { this.destroyUser() }} />}

        </Card.Content>

        <UserFormComponent />
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const { user } = state

  return {
    user
  }
}

const mapStateToDispatch = (dispatch) => ({
  toggleUserForm: (isUserFormVisible, user) => dispatch(UserActions.toggleUserForm(isUserFormVisible, user)),
  createUser: (user) => dispatch(UserActions.createUserRequest(user)),
  updateUser: (user) => dispatch(UserActions.updateUserRequest(user)),
  destroyUser: (user) => dispatch(UserActions.destroyUserRequest(user))
})

export default connect(mapStateToProps, mapStateToDispatch)(UserListComponent)
