import { put, call } from 'redux-saga/effects'
import EventActions from '../redux/EventRedux'

export function * getEvents (api, action) {
  const resp = yield call(api.getEvents)

  if (resp.ok) {
    yield put(EventActions.getEventsSuccess(resp.data))
  } else {
    yield put(EventActions.getEventsFailure(resp.data))
  }
}

export function * getEvent (api, action) {
  const { eventId } = action

  const resp = yield call(api.getEvent, eventId)

  if (resp.ok) {
    yield put(EventActions.getEventSuccess(resp.data))
  } else {
    yield put(EventActions.getEventFailure(resp.data))
  }
}

export function * createEvent (api, action) {
  const { event } = action

  const resp = yield call(api.createEvent, event)

  if (resp.ok) {
    yield put(EventActions.createEventSuccess(resp.data))
  } else {
    yield put(EventActions.createEventFailure(resp.data))
  }
}

export function * updateEvent (api, action) {
  const { event } = action

  const resp = yield call(api.updateEvent, event)

  if (resp.ok) {
    yield put(EventActions.updateEventSuccess(resp.data))
  } else {
    yield put(EventActions.updateEventFailure(resp.data))
  }
}

export function * destroyEvent (api, action) {
  const { event } = action

  const resp = yield call(api.destroyEvent, event)

  if (resp.ok) {
    yield put(EventActions.destroyEventSuccess(resp.data))
  } else {
    yield put(EventActions.destroyEventFailure(resp.data))
  }
}

export function * blockEventMessage (api, action) {
  const { eventMessage } = action

  const resp = yield call(api.blockEventMessage, eventMessage)

  if (resp.ok) {
    yield put(EventActions.blockEventMessageSuccess(resp.data))
  } else {
    yield put(EventActions.blockEventMessageFailure(resp.data))
  }
}

export function * unblockEventMessage (api, action) {
  const { eventMessage } = action

  const resp = yield call(api.unblockEventMessage, eventMessage)

  if (resp.ok) {
    yield put(EventActions.unblockEventMessageSuccess(resp.data))
  } else {
    yield put(EventActions.unblockEventMessageFailure(resp.data))
  }
}

export function * toggleBlacklistEventMessage (api, action) {
  const { eventMessage } = action

  const resp = yield call(api.toggleBlacklistEventMessage, eventMessage)

  if (resp.ok) {
    yield put(EventActions.toggleBlacklistEventMessageSuccess(resp.data))
  } else {
    yield put(EventActions.toggleBlacklistEventMessageFailure(resp.data))
  }
}

export function * getEventsToPay (api, action) {
  const { q } = action
  const resp = yield call(api.getEventsToPay, q)

  if (resp.ok) {
    yield put(EventActions.getEventsToPaySuccess(resp.data))
  } else {
    yield put(EventActions.getEventsToPayFailure(resp.data))
  }
}