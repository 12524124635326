import { connect } from 'react-redux'
import EventsToPayComponent from '../../../components/Admins/Reports/EventsToPayComponent'
import AdminAccountActions from '../../../redux/AdminAccountRedux'
import CompanyActions from '../../../redux/CompanyRedux'
import CountryActions from '../../../redux/CountryRedux'

const mapStateToProps = (state) => {
  const { adminAccount, company, country } = state

  return {
    adminAccount,
    company,
    country
  }
}

const mapStateToDispatch = (dispatch) => ({
  getEventsToPay: (q) => dispatch(AdminAccountActions.adminGetEventsToPayRequest(q)),
  getCompanies: () => dispatch(CompanyActions.getCompaniesRequest()),
  getCountries: () => dispatch(CountryActions.getCountriesRequest()),
})

export default connect(mapStateToProps, mapStateToDispatch)(EventsToPayComponent)
