import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import RoomActions from "../../redux/RoomRedux";
import ErrorMessagesComponent from "./ErrorMessagesComponent";

class RoomFormComponent extends Component {
  constructor(props) {
    super(props);

    this.initial_state = {
      room: {}
    };

    this.state = this.initial_state;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.room.editRoom && nextProps.room.editRoom) {
      this.setState({
        room: nextProps.room.editRoom
      });
    }

    if (
      !this.props.room.isRoomFormVisible &&
      nextProps.room.isRoomFormVisible
    ) {
      if (!nextProps.room.editRoom) {
        this.setState(this.initial_state);
      }
    }
  }

  saveRoom() {
    this.props.updateRoom(this.state.room);
  }

  handleChange(e, { name, value }) {
    this.setState({
      room: { ...this.state.room, [name]: value }
    });
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon="close"
        open={this.props.room.isRoomFormVisible}
        onClose={() => {
          this.props.closeRoomForm();
        }}
      >
        <Modal.Header>
          {this.state.room.id ? "Editar" : "Crear"} área
        </Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent messages={this.props.room.errors} />
          <Form autoComplete="off">
            <Form.Input
              fluid
              label="Nombre"
              placeholder="Nombre"
              name="name"
              value={this.state.room.name}
              onChange={this.handleChange.bind(this)}
            />

            <Button
              style={Styles.cardButton}
              loading={
                this.props.room.creatingRoom || this.props.room.updatingRoom
              }
              type="submit"
              onClick={() => {
                this.saveRoom();
              }}
            >
              Guardar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { room } = state;

  return {
    room
  };
};

const mapStateToDispatch = dispatch => ({
  closeRoomForm: () => dispatch(RoomActions.toggleRoomForm(false)),
  updateRoom: room => dispatch(RoomActions.updateRoomRequest(room))
});

export default connect(mapStateToProps, mapStateToDispatch)(RoomFormComponent);
