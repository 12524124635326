import { connect } from 'react-redux'
import ForgotPasswordComponent from '../../components/Users/ForgotPasswordComponent'
import UserAccountActions from '../../redux/UserAccountRedux'

const mapStateToProps = (state) => {
  const { userAccount } = state
  
  return {
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  resetPasswordInstructions: (email) => dispatch(UserAccountActions.userResetPasswordInstructionsRequest(email))
})

export default connect(mapStateToProps, mapStateToDispatch)(ForgotPasswordComponent)
