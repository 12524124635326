import { connect } from 'react-redux'
import PasswordEditComponent from '../../components/Users/PasswordEditComponent'
import UserAccountActions from '../../redux/UserAccountRedux'

const mapStateToProps = (state) => {
  const { userAccount } = state
  
  return {
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  resetPassword: (user) => dispatch(UserAccountActions.userResetPasswordRequest(user))
})

export default connect(mapStateToProps, mapStateToDispatch)(PasswordEditComponent)
