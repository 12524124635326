import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown,
  Popup,
  Dimmer,
  Loader
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import BranchActions from "../../redux/BranchRedux";
import EventActions from "../../redux/EventRedux";
import RoomActions from "../../redux/RoomRedux";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import { toast } from "react-toastify";
import moment from "moment";

class BranchListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destroyLayerHidden: true,
      branchToDestroy: null,
      castReceiverAvailable: true
    };

    this.castSettings = {
      applicationId: "31D274C8",
      namespace: "urn:x-cast:com.google.cast.codingways.nitpoint",
      session: null
    };
  }

  castRoom(room) {
    if (!window.chrome) {
      return toast.error(
        "Debe utilizar el navegador Google Chrome para transmitir contenido a las áreas."
      );
    }

    const { namespace, applicationId } = this.castSettings;

    const sessionRequest = new window.chrome.cast.SessionRequest(applicationId);
    const apiConfig = new window.chrome.cast.ApiConfig(
      sessionRequest,
      () => {},
      () => {},
      window.chrome.cast.AutoJoinPolicy.PAGE_SCOPED
    );
    window.chrome.cast.initialize(apiConfig, () => {
      window.chrome.cast.requestSession(session => {
        session.sendMessage(namespace, { roomCode: room.code }, () => {
          window.location.reload();
        });
      });
    });
  }

  castBranchScreen(branch) {
    if (!window.chrome) {
      return toast.error(
        "Debe utilizar el navegador Google Chrome para transmitir contenido a las áreas."
      );
    }

    const { namespace, applicationId } = this.castSettings;

    const sessionRequest = new window.chrome.cast.SessionRequest(applicationId);
    const apiConfig = new window.chrome.cast.ApiConfig(
      sessionRequest,
      () => {},
      () => {},
      window.chrome.cast.AutoJoinPolicy.PAGE_SCOPED
    );
    window.chrome.cast.initialize(apiConfig, () => {
      window.chrome.cast.requestSession(session => {
        session.sendMessage(namespace, { branchId: branch.id }, () => {
          window.location.reload();
        });
      });
    });
  }

  renderBranches() {
    if (!this.props.branches || this.props.branches.length == 0) {
      return;
    }

    return this.props.branches.map(b => {
      return (
        <Card fluid key={b.id}>
          <Card.Content>
            <Card.Header style={Styles.cardHeaderList}>
              <Item>
                {b.name}{" "}
                {b.address && (
                  <span style={Styles.address}>
                    {" "}
                    ({b.address}, {b.state.name})
                  </span>
                )}
              </Item>

              {window.ability.can("update", "Branch") && (
                <Item>
                  <Popup
                    trigger={
                      <Icon
                        name="edit"
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.context.router.history.push(`/branches/${b.id}`);
                        }}
                      />
                    }
                    content="Editar Establecimiento"
                    position="top center"
                  />
                  <Popup
                    trigger={
                      <Icon
                        name="list"
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.context.router.history.push(
                            `/branches/${b.id}/rooms`
                          );
                        }}
                      />
                    }
                    content="Editar áreas"
                    position="top center"
                  />
                </Item>
              )}
            </Card.Header>
            <Item.Group divided relaxed>
              {this.renderRooms(b)}
            </Item.Group>
          </Card.Content>
        </Card>
      );
    });
  }

  renderRooms(branch) {
    if (!branch.rooms.length) {
      return "No hay áreas creadas";
    }

    const rooms = [...branch.rooms].sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    return rooms.map(r => {
      return (
        <Item key={r.id} style={Styles.listRow}>
          <Item.Content style={Styles.listRow} verticalAlign="middle">
            <Item.Header style={Styles.textBold}>{r.name}</Item.Header>

            {r.current_event && (
              <Item.Header
                style={Styles.text}
                as="a"
                onClick={() => {
                  this.props.toggleEventForm(true, r.current_event);
                }}
              >
                {`Mostrando evento: ${
                  r.current_event.event_name
                } (hasta las ${moment(r.current_event.ends_at).format(
                  "HH:mm"
                )})`}
              </Item.Header>
            )}

            {!r.current_event && r.next_event && (
              <Item.Header
                style={Styles.text}
                as="a"
                onClick={() => {
                  this.props.toggleEventForm(true, r.next_event);
                }}
              >
                {`Próximo evento: ${r.next_event.event_name} (${moment(
                  r.next_event.starts_at
                ).fromNow()})`}
              </Item.Header>
            )}

            {!r.current_event && !r.next_event && (
              <Item.Header style={Styles.text}>Libre</Item.Header>
            )}
          </Item.Content>
          <Item>
            {r.current_event !== undefined && (
              <Popup
                trigger={
                  <Icon
                    name="edit"
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.props.toggleEventForm(true, r.current_event);
                    }}
                  />
                }
                content="Editar evento"
                position="top center"
              />
            )}
            {r.current_event !== undefined && (
              <Popup
                trigger={
                  <Icon
                    name="television"
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.previewRoom(r);
                    }}
                  />
                }
                content="Previsualizar área"
                position="top center"
              />
            )}
            {r.current_event ? (
              <Popup
                trigger={
                  <Icon
                    name="mail outline"
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.context.router.history.push(
                        `/events/${r.current_event.id}/messages`
                      );
                    }}
                  />
                }
                content="Ver Mensajes"
                position="top center"
              />
            ) : (
              <Popup
                trigger={
                  <Icon
                    name="plus"
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.props.toggleEventForm(true, {
                        room_id: r.id,
                        room: r
                      });
                    }}
                  />
                }
                content="Agregar Evento"
                position="top center"
              />
            )}
            {this.state.castReceiverAvailable && (
              <Popup
                trigger={
                  <Icon
                    name="feed"
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.castRoom(r);
                    }}
                  />
                }
                content="Enviar a Pantalla"
                position="top center"
              />
            )}

            {/*r.current_event !== undefined && <Popup trigger={<Icon name='browser' style={Styles.cardHeaderIcon} onClick={() => { this.seeEvent(r) }} />} content='Ver Event' position='top center' />*/}
          </Item>
        </Item>
      );
    });
  }

  previewRoom(r) {
    window.open("/receiver?roomCode=" + r.code);
  }

  seeEvent(r) {
    window.open("/event?eventCode=" + r.code);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.branch.destroyingBranch &&
      !newProps.branch.destroyingBranch
    ) {
      if (newProps.branch.errors.length) {
        toast.error(newProps.branch.errors.join(", "));
      } else {
        toast.success("Establecimiento eliminado correctamente");
      }
    }

    if (this.props.event.creatingEvent && !newProps.event.creatingEvent) {
      this.props.refreshBranches();
    }

    if (this.props.event.updatingEvent && !newProps.event.updatingEvent) {
      this.props.refreshBranches();
    }
  }

  destroyBranch() {
    this.props.destroyBranch(this.state.branchToDestroy);
    this.setState({ destroyLayerHidden: true, branchToDestroy: null });
  }

  render() {
    return (
      <div>
        <Card fluid>
          <Card.Content style={Styles.cardContent}>
            <Card.Header>
              <Grid centered columns={2} style={Styles.cardHeaderList}>
                <Item style={Styles.cardHeaderTitle}>Establecimientos</Item>
                {window.ability.can("create", "Branch") && (
                  <Button
                    style={Styles.cardButton}
                    onClick={() => {
                      this.context.router.history.push("/branches/new");
                    }}
                  >
                    <Icon name="plus" style={Styles.cardIcon} />
                    Crear Establecimiento
                  </Button>
                )}
              </Grid>
            </Card.Header>

            {this.props.branch.branches.length > 0 && (
              <Item.Group divided relaxed>
                {this.renderBranches()}
              </Item.Group>
            )}

            {this.props.branch.branches.length == 0 && (
              <Card.Content style={Styles.cardContent}>
                <Item.Group divided relaxed style={Styles.notFoundText}>
                  Aún no has creado ningún establecimiento
                </Item.Group>
              </Card.Content>
            )}
          </Card.Content>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { branch, event } = state;

  return {
    branch,
    event
  };
};

const mapStateToDispatch = dispatch => ({
  toggleBranchForm: (isBranchFormVisible, branch) =>
    dispatch(BranchActions.toggleBranchForm(isBranchFormVisible, branch)),
  createBranch: branch => dispatch(BranchActions.createUserRequest(branch)),
  updateBranch: branch => dispatch(BranchActions.updateUserRequest(branch)),
  toggleEventForm: (isEventFormVisible, event) =>
    dispatch(EventActions.toggleEventForm(isEventFormVisible, event))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(BranchListComponent);

BranchListComponent.contextTypes = {
  router: PropTypes.object
};
