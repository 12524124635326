import { Colors, Images } from "../../themes";

export default {
  navbarLogo: {
    height: 50
  },
  navbarIcon: {
    fontSize: 20
  }
};
