import React, { Component } from 'react'
import Loader from 'react-loader'
import PropTypes from 'prop-types'
import { Form, Grid, Card, Sidebar, Segment, Button, Menu, Modal, Image, Icon, Item, Header, Dropdown } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import { connect } from 'react-redux'
import UserActions from '../../redux/UserRedux'
import BranchActions from '../../redux/BranchRedux'
import ErrorMessagesComponent from './ErrorMessagesComponent'

class UserFormComponent extends Component {

  constructor(props) {
    super(props)

    this.initial_state = {
      user: {
      }
    }

    this.state = this.initial_state
  }

  componentDidMount() {
    this.props.getBranches()
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.user.editUser && nextProps.user.editUser) {
      this.setState({
        user: nextProps.user.editUser
      })
    }

    if (!this.props.user.isUserFormVisible && nextProps.user.isUserFormVisible) {
      if (!nextProps.user.editUser) {
        this.setState(this.initial_state)
      }
    }
  }

  saveUser() {
    if (this.state.user.id){
      this.props.updateUser(this.state.user)
    }
    else {
      this.props.createUser(this.state.user)
    }
  }

  renderRoles() {
    return [
      { text: 'Operario', value: 0 },
      { text: 'Administrador', value: 1 }
    ]
  }

  renderBranches() {
    return this.props.branch.branches.map((b) => {
      return {
        key: b.id,
        text: b.name,
        value: b.id
      }
    })
  }

  handleChange (e, {name, value}) {
    this.setState({
      user: {...this.state.user, [name]: value}
    })
  }

  render() {
    return (
      <Modal closeOnDimmerClick={false} closeIcon='close' open={this.props.user.isUserFormVisible} onClose={ () => { this.props.closeUserForm() }}>
        <Modal.Header>{this.state.user.id ? 'Editar' : 'Crear'} Usuario</Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent messages={this.props.user.errors} />
          <Form loading={this.props.user.creatingUser || this.props.user.updatingUser} autoComplete="off">
            <Form.Input
                fluid
                label="Nombre"
                placeholder="Nombre"
                name="first_name"
                value={this.state.user.first_name}
                onChange={this.handleChange.bind(this)} />

            <Form.Input
                fluid
                label="Apellido"
                placeholder="Apellido"
                name="last_name"
                value={this.state.user.last_name}
                onChange={this.handleChange.bind(this)} />

            <Form.Input
                fluid
                label="E-Mail"
                placeholder="E-Mail"
                name="email"
                value={this.state.user.email}
                onChange={this.handleChange.bind(this)} />

            <Form.Input
                fluid
                type='password'
                label="Contraseña"
                placeholder="Contraseña"
                name="password"
                value={this.state.user.password}
                onChange={this.handleChange.bind(this)} />

            <Form.Input
                fluid
                type='password'
                label="Confirmación de Contraseña"
                placeholder="Confirmación de Contraseña"
                name="password_confirmation"
                value={this.state.user.password_confirmation}
                onChange={this.handleChange.bind(this)} />

            <Form.Select
                fluid
                label="Rol"
                placeholder="Rol"
                name="role"
                options={this.renderRoles()}
                value={this.state.user.role}
                onChange={this.handleChange.bind(this)} />

            {this.state.user.role === 0 && <Form.Select
                fluid
                label="Sucursales Habilitadas"
                placeholder="Sucursales Habilitadas"
                name="branch_ids"
                options={this.renderBranches()}
                value={this.state.user.branch_ids}
                multiple selection
                onChange={this.handleChange.bind(this)} />}

            <Button style={Styles.cardButton} type='submit' onClick={ () => { this.saveUser() }}>Guardar</Button>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {  
  const { user, branch } = state
  
  return {
    user,
    branch
  }
}

const mapStateToDispatch = (dispatch) => ({
  closeUserForm: () => dispatch(UserActions.toggleUserForm(false)),
  createUser: (user) => dispatch(UserActions.createUserRequest(user)),
  updateUser: (user) => dispatch(UserActions.updateUserRequest(user)),
  getBranches: () => dispatch(BranchActions.getBranchesRequest())
})

export default connect(mapStateToProps, mapStateToDispatch)(UserFormComponent)
