import { connect } from "react-redux";
import LeaveMessageComponent from "../../components/Shared/LeaveMessageComponent";
import PublicControllerActions from "../../redux/PublicControllerRedux";

const mapStateToProps = state => {
  const { publicController } = state;

  return {
    publicController
  };
};

const mapStateToDispatch = dispatch => ({
  findEventByCode: code =>
    dispatch(PublicControllerActions.findEventByCodeRequest(code)),
  createEventMessage: (code, eventMessage) =>
    dispatch(
      PublicControllerActions.createEventMessageRequest(code, eventMessage)
    ),
  setUser: userData => dispatch(PublicControllerActions.setUser(userData)),
  clearUser: () => dispatch(PublicControllerActions.clearUser()),
  clearEvent: () => dispatch(PublicControllerActions.clearEvent())
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(LeaveMessageComponent);
