import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getEventsRequest: [],
  getEventsSuccess: ['events'],
  getEventsFailure: ['errors'],

  getEventRequest: ['eventId'],
  getEventSuccess: ['event'],
  getEventFailure: ['errors'],

  createEventRequest: ['event'],
  createEventSuccess: ['event'],
  createEventFailure: ['errors'],

  updateEventRequest: ['event'],
  updateEventSuccess: ['event'],
  updateEventFailure: ['errors'],

  destroyEventRequest: ['event'],
  destroyEventSuccess: ['event'],
  destroyEventFailure: ['errors'],

  blockEventMessageRequest: ['eventMessage'],
  blockEventMessageSuccess: ['eventMessage'],
  blockEventMessageFailure: ['errors'],

  unblockEventMessageRequest: ['eventMessage'],
  unblockEventMessageSuccess: ['eventMessage'],
  unblockEventMessageFailure: ['errors'],

  toggleBlacklistEventMessageRequest: ['eventMessage'],
  toggleBlacklistEventMessageSuccess: ['eventMessage'],
  toggleBlacklistEventMessageFailure: ['errors'],

  toggleEventForm: ['isEventFormVisible', 'editEvent'],

  getEventsToPayRequest: ['q'],
  getEventsToPaySuccess: ['events'],
  getEventsToPayFailure: ['errors']
})

export const EventTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  event: null,
  errors: [],
  creatingEvent: false,
  fetching: false,
  events: [],
  updatingEvent: false,
  isEventFormVisible: false,
  editEvent: null,
  destroyingEvent: false,
  eventMessage: null,
  blockingEventMessage: false,
  unblockingEventMessage: false,
  togglingEventMessage: false
})

/* ------------- Reducers ------------- */

export const getEventsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getEventsSuccess = (state: Object, { events }: Object) => 
  state.merge({
    events,
    fetching: false
  })

export const getEventsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getEventRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getEventSuccess = (state: Object, { event }: Object) => 
  state.merge({
    event,
    fetching: false
  })

export const getEventFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createEventRequest = (state: Object) =>
  state.merge({
    creatingEvent: true,
    errors: []  
  })

export const createEventSuccess = (state: Object, { event }: Object) => 
  state.merge({
    events: [...state.events, event],
    creatingEvent: false,
    isEventFormVisible: false,
    editEvent: null
  })

export const createEventFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingEvent: false,
    errors
  })

export const updateEventRequest = (state: Object) =>
  state.merge({
    updatingEvent: true,
    errors: []  
  })

export const updateEventSuccess = (state: Object, { event }: Object) => {
  let events = state.events
  
  events = events.map((w) => {
    if (w.id === event.id) {
      return event
    }

    return w
  })

  return state.merge({ 
    updatingEvent: false,
    isEventFormVisible: false,
    events,
    editEvent: null
  })
}

export const updateEventFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingEvent: false,
    errors
  })

export const destroyEventRequest = (state: Object) => 
  state.merge({ 
    destroyingEvent: true,
    errors: [] 
  })

export const destroyEventSuccess = (state: Object, { event }: Object) =>
  state.merge({ 
    destroyingEvent: false,
    events: state.events.filter((w) => w.id !== event.id)
  })
  
export const destroyEventFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingEvent: false, 
    errors 
  })

export const toggleEventForm = (state: Object, { isEventFormVisible, editEvent }: Object) => {
  let event = isEventFormVisible ? editEvent : undefined

  return state.merge({
    isEventFormVisible,
    editEvent: event,
    errors: []
  })
}

export const blockEventMessageRequest = (state: Object) => 
  state.merge({ 
    blockingEventMessage: true,
    errors: [] 
  })

export const blockEventMessageSuccess = (state: Object, { eventMessage }: Object) =>
  state.merge({ 
    blockingEventMessage: false,
    event: {...state.event, event_messages: state.event.event_messages.map((m) => {
      if (m.id === eventMessage.id) {
        return eventMessage
      }

      return m
    })}
  })
  
export const blockEventMessageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    blockingEventMessage: false, 
    errors 
  })

export const unblockEventMessageRequest = (state: Object) => 
  state.merge({ 
    unblockingEventMessage: true,
    errors: [] 
  })

export const unblockEventMessageSuccess = (state: Object, { eventMessage }: Object) =>
  state.merge({ 
    unblockingEventMessage: false,
    event: {...state.event, event_messages: state.event.event_messages.map((m) => {
      if (m.id === eventMessage.id) {
        return eventMessage
      }

      return m
    })}
  })
  
export const unblockEventMessageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    unblockingEventMessage: false, 
    errors 
  })

export const toggleBlacklistEventMessageRequest = (state: Object) => 
  state.merge({ 
    togglingEventMessage: true,
    errors: [] 
  })

export const toggleBlacklistEventMessageSuccess = (state: Object, { eventMessage }: Object) =>
  state.merge({ 
    togglingEventMessage: false,
    event: {...state.event, event_messages: state.event.event_messages.map(e => e.id === eventMessage.id ? eventMessage : e) }
  })
  
export const toggleBlacklistEventMessageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    togglingEventMessage: false, 
    errors 
  })

export const getEventsToPayRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getEventsToPaySuccess = (state: Object, { events }: Object) => 
  state.merge({
    events,
    fetching: false
  })

export const getEventsToPayFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_EVENTS_REQUEST]: getEventsRequest,
  [Types.GET_EVENTS_SUCCESS]: getEventsSuccess,
  [Types.GET_EVENTS_FAILURE]: getEventsFailure,

  [Types.GET_EVENT_REQUEST]: getEventRequest,
  [Types.GET_EVENT_SUCCESS]: getEventSuccess,
  [Types.GET_EVENT_FAILURE]: getEventFailure,

  [Types.CREATE_EVENT_REQUEST]: createEventRequest,
  [Types.CREATE_EVENT_SUCCESS]: createEventSuccess,
  [Types.CREATE_EVENT_FAILURE]: createEventFailure,

  [Types.UPDATE_EVENT_REQUEST]: updateEventRequest,
  [Types.UPDATE_EVENT_SUCCESS]: updateEventSuccess,
  [Types.UPDATE_EVENT_FAILURE]: updateEventFailure,

  [Types.DESTROY_EVENT_REQUEST]: destroyEventRequest,
  [Types.DESTROY_EVENT_SUCCESS]: destroyEventSuccess,
  [Types.DESTROY_EVENT_FAILURE]: destroyEventFailure,

  [Types.BLOCK_EVENT_MESSAGE_REQUEST]: blockEventMessageRequest,
  [Types.BLOCK_EVENT_MESSAGE_SUCCESS]: blockEventMessageSuccess,
  [Types.BLOCK_EVENT_MESSAGE_FAILURE]: blockEventMessageFailure,

  [Types.UNBLOCK_EVENT_MESSAGE_REQUEST]: unblockEventMessageRequest,
  [Types.UNBLOCK_EVENT_MESSAGE_SUCCESS]: unblockEventMessageSuccess,
  [Types.UNBLOCK_EVENT_MESSAGE_FAILURE]: unblockEventMessageFailure,

  [Types.TOGGLE_BLACKLIST_EVENT_MESSAGE_REQUEST]: toggleBlacklistEventMessageRequest,
  [Types.TOGGLE_BLACKLIST_EVENT_MESSAGE_SUCCESS]: toggleBlacklistEventMessageSuccess,
  [Types.TOGGLE_BLACKLIST_EVENT_MESSAGE_FAILURE]: toggleBlacklistEventMessageFailure,

  [Types.TOGGLE_EVENT_FORM]: toggleEventForm,

  [Types.GET_EVENTS_TO_PAY_REQUEST]: getEventsToPayRequest,
  [Types.GET_EVENTS_TO_PAY_SUCCESS]: getEventsToPaySuccess,
  [Types.GET_EVENTS_TO_PAY_FAILURE]: getEventsToPayFailure,
})