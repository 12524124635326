import { Colors, Images } from "../../themes";

export default {
  loginForm: {
    height: "100%",
    margin: 0
  },
  loginFormUsers: {
    height: "100vh",
    backgroundColor: Colors.bg,
    backgroundSize: "cover",
    margin: 0
  },
  loginLogo: {
    width: 150,
    margin: 15
  },
  loginButton: {
    backgroundColor: Colors.primary,
    margin: "15px 0 10px"
  },
  adminTitle: {
    color: Colors.primary,
    fontSize: 24,
    fontWeight: "bold",
    paddingBottom: 15
  },
  forgetPasswordButton: {
    fontWeight: "600",
    color: Colors.secondary
  }
};
