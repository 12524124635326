import { connect } from 'react-redux'
import CompanyComponent from '../../components/Admins/CompanyComponent'
import CompanyActions from '../../redux/CompanyRedux'
import UserActions from '../../redux/UserRedux'

const mapStateToProps = (state) => {
  const { company, user } = state
  
  return {
    company,
    user
  }
}

const mapStateToDispatch = (dispatch) => ({
  getCompany: (companyId) => dispatch(CompanyActions.getCompanyRequest(companyId)),
  getUsers: () => dispatch(UserActions.getUsersRequest()),
  toggleCompanyForm: (isFormVisible, company) => dispatch(CompanyActions.toggleCompanyForm(isFormVisible, company)),
  toggleUserForm: (isFormVisible, user) => dispatch(UserActions.toggleUserForm(isFormVisible, user)),
  clearCompany: () => dispatch(CompanyActions.clearCompany())
})

export default connect(mapStateToProps, mapStateToDispatch)(CompanyComponent)
