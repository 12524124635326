import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import UserAccountActions from "../../redux/UserAccountRedux";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import { toast } from "react-toastify";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import loadImage from "blueimp-load-image";
import { dataURItoBlob } from "../../lib";

class CompanyFormComponent extends Component {
  constructor(props) {
    super(props);

    this.initial_state = {
      company: {
        logo: null
      },
      imagePreviewUrl: null,
      crop: {},
      cropImage: null
    };

    this.state = this.initial_state;
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.userAccount.editCompany &&
      nextProps.userAccount.editCompany
    ) {
      this.setState({
        company: nextProps.userAccount.editCompany,
        imagePreviewUrl: null
      });
    }

    if (this.props.userAccount.fetching && !nextProps.userAccount.fetching) {
      if (nextProps.userAccount.errors.length) {
        toast.error(nextProps.userAccount.errors.join(", "));
      } else {
        toast.success("Compañía actualizada correctamente");
      }
    }
  }

  saveCompany() {
    this.props.updateCompany(this.state.company);
  }

  handleImageChange(e) {
    e.preventDefault();

    loadImage(
      e.target.files[0],
      img => {
        const b64 = img.toDataURL();

        this.setState({
          company: {
            ...this.state.company,
            logo: dataURItoBlob(b64),
            logo_url: null
          },
          imagePreviewUrl: b64
        });
      },
      { maxWidth: 2000, orientation: true }
    );
  }

  onImageLoaded(image) {
    this.setState({
      crop: makeAspectCrop(
        {
          x: 0,
          y: 0,
          width: 50
        },
        image.width / image.height
      ),
      cropImage: image
    });
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  }

  async onCompleteCrop(crop, pixelCrop) {
    const croppedImg = await this.getCroppedImg(
      this.state.cropImage,
      pixelCrop,
      this.state.company.logo.name
    );
    this.setState({ company: { ...this.state.company, logo: croppedImg } });
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon="close"
        open={this.props.userAccount.isCompanyFormVisible}
        onClose={() => {
          this.props.closeCompanyForm();
        }}
      >
        <Modal.Header>Editar Compañía</Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent messages={this.props.userAccount.errors} />
          <Form loading={this.props.userAccount.fetching} autoComplete="off">
            <Form.Field>
              <Form.TextArea
                label="Mensaje inicial del evento"
                value={this.state.company.default_event_message}
                placeholder="Mensaje inicial del evento"
                onChange={e =>
                  this.setState({
                    company: {
                      ...this.state.company,
                      default_event_message: e.nativeEvent.target.value
                    }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Logo de Compañía</label>
              <label className="fileContainer fileContainerSmall">
                <Icon name="upload" className="fileContainerIcon" />
                Seleccionar archivo
                <input
                  type="file"
                  onChange={e => this.handleImageChange(e)}
                  accept="image/*"
                />
              </label>
            </Form.Field>

            <div>
              {this.state.imagePreviewUrl && (
                <ReactCrop
                  src={this.state.imagePreviewUrl}
                  keepSelection
                  onImageLoaded={image => {
                    this.onImageLoaded(image);
                  }}
                  crop={this.state.crop}
                  onChange={crop => {
                    this.setState({ crop });
                  }}
                  onComplete={(crop, pixelCrop) => {
                    this.onCompleteCrop(crop, pixelCrop);
                  }}
                />
              )}

              {this.state.company.logo_url && (
                <Image
                  src={`${Images.remote(
                    this.state.company.logo_url
                  )}?timestamp=${+new Date()}`}
                  size="medium"
                  centered
                />
              )}
            </div>

            <Button
              style={Styles.cardButton}
              type="submit"
              onClick={() => {
                this.saveCompany();
              }}
            >
              Guardar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { userAccount } = state;

  return {
    userAccount
  };
};

const mapStateToDispatch = dispatch => ({
  closeCompanyForm: () =>
    dispatch(UserAccountActions.userToggleCompanyForm(false)),
  updateCompany: company =>
    dispatch(UserAccountActions.userUpdateCompanyRequest(company))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(CompanyFormComponent);
