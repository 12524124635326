import { connect } from 'react-redux'
import BackgroundImageListComponent from '../../components/Admins/BackgroundImageListComponent'
import BackgroundImageActions from '../../redux/BackgroundImageRedux'

const mapStateToProps = (state) => {
  const { backgroundImage } = state

  return {
    backgroundImage
  }
}

const mapStateToDispatch = (dispatch) => ({
  getBackgroundImages: () => dispatch(BackgroundImageActions.getBackgroundImagesRequest()),
  toggleBackgroundImageForm: (isBackgroundImageFormVisible, backgroundImage) => dispatch(BackgroundImageActions.toggleBackgroundImageForm(isBackgroundImageFormVisible, backgroundImage)),
  clearBackgroundImageForm: () => dispatch(BackgroundImageActions.clearBackgroundImageForm()),
  destroyBackgroundImage: (backgroundImage) => dispatch(BackgroundImageActions.destroyBackgroundImageRequest(backgroundImage))
})

export default connect(mapStateToProps, mapStateToDispatch)(BackgroundImageListComponent)
