import { createReducer, createActions } from "reduxsauce";

import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  activateScreenRequest: ["code"],
  activateScreenSuccess: ["roomDevice"],
  activateScreenFailure: ["errors"],

  findRoomDeviceByFingerprintRequest: ["deviceFingerprint"],
  findRoomDeviceByFingerprintSuccess: ["roomDevice"],
  findRoomDeviceByFingerprintFailure: ["errors"],

  getBranchByIdRequest: ["branchId"],
  getBranchByIdSuccess: ["branch"],
  getBranchByIdFailure: ["errors"],

  findEventByCodeRequest: ["code"],
  findEventByCodeSuccess: ["event"],
  findEventByCodeFailure: ["errors"],

  createEventMessageRequest: ["code", "eventMessage"],
  createEventMessageSuccess: ["eventMessage"],
  createEventMessageFailure: ["errors"],

  findEventBySlugRequest: ["slug", "token"],
  findEventBySlugSuccess: ["event"],
  findEventBySlugFailure: ["errors"],

  removeEventMessageWithTokenRequest: ["eventMessageId", "token"],
  removeEventMessageWithTokenSuccess: ["eventMessage"],
  removeEventMessageWithTokenFailure: ["errors"],

  setUser: ["user"],
  clearUser: null,
  clearEvent: null
});

export const PublicControllerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  branch: null,
  roomDevice: null,
  errors: [],
  fetching: false,
  activatingScreen: false,
  event: null,
  creatingMessage: false,
  eventMessage: null,
  destroyingMessage: false,
  user: null
});

/* ------------- Reducers ------------- */

export const activateScreenRequest = (state: Object) => ({
  ...state,
  activatingScreen: true,
  errors: []
});

export const activateScreenSuccess = (
  state: Object,
  { roomDevice }: Object
) => ({
  ...state,
  roomDevice,
  activatingScreen: false
});

export const activateScreenFailure = (state: Object, { errors }: Object) => ({
  ...state,
  activatingScreen: false,
  errors
});

export const findRoomDeviceByFingerprintRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const findRoomDeviceByFingerprintSuccess = (
  state: Object,
  { roomDevice }: Object
) => ({
  ...state,
  roomDevice,
  fetching: false
});

export const findRoomDeviceByFingerprintFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  fetching: false,
  errors
});

export const getBranchByIdRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const getBranchByIdSuccess = (state: Object, { branch }: Object) => ({
  ...state,
  branch,
  fetching: false
});

export const getBranchByIdFailure = (state: Object, { errors }: Object) => ({
  ...state,
  fetching: false,
  errors
});

export const findEventByCodeRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const findEventByCodeSuccess = (state: Object, { event }: Object) => ({
  ...state,
  event,
  fetching: false
});

export const findEventByCodeFailure = (state: Object, { errors }: Object) => ({
  ...state,
  fetching: false,
  errors
});

export const createEventMessageRequest = (state: Object) => ({
  ...state,
  creatingMessage: true,
  errors: [],
  eventMessage: null
});

export const createEventMessageSuccess = (state: Object, { eventMessage }) => ({
  ...state,
  creatingMessage: false,
  eventMessage
});

export const createEventMessageFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  creatingMessage: false,
  errors
});

export const findEventBySlugRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const findEventBySlugSuccess = (state: Object, { event }: Object) => ({
  ...state,
  event,
  fetching: false
});

export const findEventBySlugFailure = (state: Object, { errors }: Object) => ({
  ...state,
  fetching: false,
  errors
});

export const removeEventMessageWithTokenRequest = (state: Object) => ({
  ...state,
  destroyingMessage: true,
  errors: [],
  eventMessage: null
});

export const removeEventMessageWithTokenSuccess = (
  state: Object,
  { eventMessage }: Object
) => ({
  ...state,
  eventMessage,
  destroyingMessage: false
});

export const removeEventMessageWithTokenFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  destroyingMessage: false,
  errors
});

export const setUser = (state: Object, { user }: Object) => ({
  ...state,
  user
});

export const clearUser = (state: Object) => ({
  ...state,
  user: null
});

export const clearEvent = (state: Object) => ({
  ...state,
  event: null
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACTIVATE_SCREEN_REQUEST]: activateScreenRequest,
  [Types.ACTIVATE_SCREEN_SUCCESS]: activateScreenSuccess,
  [Types.ACTIVATE_SCREEN_FAILURE]: activateScreenFailure,

  [Types.FIND_ROOM_DEVICE_BY_FINGERPRINT_REQUEST]: findRoomDeviceByFingerprintRequest,
  [Types.FIND_ROOM_DEVICE_BY_FINGERPRINT_SUCCESS]: findRoomDeviceByFingerprintSuccess,
  [Types.FIND_ROOM_DEVICE_BY_FINGERPRINT_FAILURE]: findRoomDeviceByFingerprintFailure,

  [Types.GET_BRANCH_BY_ID_REQUEST]: getBranchByIdRequest,
  [Types.GET_BRANCH_BY_ID_SUCCESS]: getBranchByIdSuccess,
  [Types.GET_BRANCH_BY_ID_FAILURE]: getBranchByIdFailure,

  [Types.FIND_EVENT_BY_CODE_REQUEST]: findEventByCodeRequest,
  [Types.FIND_EVENT_BY_CODE_SUCCESS]: findEventByCodeSuccess,
  [Types.FIND_EVENT_BY_CODE_FAILURE]: findEventByCodeFailure,

  [Types.CREATE_EVENT_MESSAGE_REQUEST]: createEventMessageRequest,
  [Types.CREATE_EVENT_MESSAGE_SUCCESS]: createEventMessageSuccess,
  [Types.CREATE_EVENT_MESSAGE_FAILURE]: createEventMessageFailure,

  [Types.FIND_EVENT_BY_SLUG_REQUEST]: findEventBySlugRequest,
  [Types.FIND_EVENT_BY_SLUG_SUCCESS]: findEventBySlugSuccess,
  [Types.FIND_EVENT_BY_SLUG_FAILURE]: findEventBySlugFailure,

  [Types.REMOVE_EVENT_MESSAGE_WITH_TOKEN_REQUEST]: removeEventMessageWithTokenRequest,
  [Types.REMOVE_EVENT_MESSAGE_WITH_TOKEN_SUCCESS]: removeEventMessageWithTokenSuccess,
  [Types.REMOVE_EVENT_MESSAGE_WITH_TOKEN_FAILURE]: removeEventMessageWithTokenFailure,

  [Types.SET_USER]: setUser,
  [Types.CLEAR_USER]: clearUser,
  [Types.CLEAR_EVENT]: clearEvent
});
