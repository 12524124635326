import { put, call } from 'redux-saga/effects'
import ReligionActions from '../redux/ReligionRedux'

export function * getReligions (api, action) {
  const resp = yield call(api.getReligions)

  if (resp.ok) {
    yield put(ReligionActions.getReligionsSuccess(resp.data))
  } else {
    yield put(ReligionActions.getReligionsFailure(resp.data))
  }
}

export function * getReligion (api, action) {
  const { religionId } = action

  const resp = yield call(api.getReligion, religionId)

  if (resp.ok) {
    yield put(ReligionActions.getReligionSuccess(resp.data))
  } else {
    yield put(ReligionActions.getReligionFailure(resp.data))
  }
}

export function * createReligion (api, action) {
  const { religion } = action

  const resp = yield call(api.createReligion, religion)

  if (resp.ok) {
    yield put(ReligionActions.createReligionSuccess(resp.data))
  } else {
    yield put(ReligionActions.createReligionFailure(resp.data))
  }
}

export function * updateReligion (api, action) {
  const { religion } = action

  const resp = yield call(api.updateReligion, religion)

  if (resp.ok) {
    yield put(ReligionActions.updateReligionSuccess(resp.data))
  } else {
    yield put(ReligionActions.updateReligionFailure(resp.data))
  }
}

export function * destroyReligion (api, action) {
  const { religion } = action

  const resp = yield call(api.destroyReligion, religion)

  if (resp.ok) {
    yield put(ReligionActions.destroyReligionSuccess(resp.data))
  } else {
    yield put(ReligionActions.destroyReligionFailure(resp.data))
  }
}