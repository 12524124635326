import React, { Component } from "react";
import { Responsive, Dropdown, Menu, Image, Icon } from "semantic-ui-react";

import { Images } from "../../../themes";
import Styles from "../../Styles/NavbarStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminAccountActions from "../../../redux/AdminAccountRedux";

const options = [
  { key: 1, text: "Compañías", value: "/admins" },
  { key: 2, text: "Fondos de pantalla", value: "/admins/background_images" },
  { key: 3, text: "Reportes", value: "/admins/reports" }
];

class Navbar extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.adminAccount.admin && !nextProps.adminAccount.admin) {
      this.context.router.history.push("/admins/login");
    }
  }

  render() {
    return (
      <Menu style={Styles.navbar}>
        <Responsive
          as={Menu.Item}
          onClick={() => {
            this.context.router.history.push("/admins");
          }}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Image src={Images.logo} style={Styles.navbarLogo} />
        </Responsive>

        {this.props.adminAccount.admin && (
          <Responsive as={Menu.Item} minWidth={Responsive.onlyTablet.minWidth}>
            {this.props.adminAccount.admin.first_name +
              " " +
              this.props.adminAccount.admin.last_name}
          </Responsive>
        )}

        <Menu.Menu position="right">
          <Dropdown
            text={Menu}
            options={options}
            simple
            item
            icon={"ellipsis vertical"}
            onChange={(e, data) => {
              this.context.router.history.push(data.value);
            }}
          />

          <Menu.Item
            style={Styles.navbarIcon}
            onClick={() => {
              this.props.logout();
            }}
          >
            <Icon name="sign in" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

Navbar.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = state => {
  const { adminAccount } = state;

  return {
    adminAccount
  };
};

const mapStateToDispatch = dispatch => ({
  logout: () => dispatch(AdminAccountActions.adminReset())
});

export default connect(mapStateToProps, mapStateToDispatch)(Navbar);
