import { put, call } from 'redux-saga/effects'
import TypeOfBurialActions from '../redux/TypeOfBurialRedux'

export function * getTypeOfBurials (api, action) {
  const resp = yield call(api.getTypeOfBurials)

  if (resp.ok) {
    yield put(TypeOfBurialActions.getTypeOfBurialsSuccess(resp.data))
  } else {
    yield put(TypeOfBurialActions.getTypeOfBurialsFailure(resp.data))
  }
}
