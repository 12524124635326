import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUsersRequest: [],
  getUsersSuccess: ['users'],
  getUsersFailure: ['errors'],

  createUserRequest: ['user'],
  createUserSuccess: ['user'],
  createUserFailure: ['errors'],

  updateUserRequest: ['user'],
  updateUserSuccess: ['user'],
  updateUserFailure: ['errors'],

  destroyUserRequest: ['user'],
  destroyUserSuccess: ['user'],
  destroyUserFailure: ['errors'],

  toggleUserForm: ['isUserFormVisible', 'editUser']
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: null,
  errors: [],
  creatingUser: false,
  fetching: false,
  users: [],
  updatingUser: false,
  isUserFormVisible: false,
  editUser: null,
  destroyingUser: false
})

/* ------------- Reducers ------------- */

export const getUsersRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getUsersSuccess = (state: Object, { users }: Object) => 
  state.merge({
    users,
    fetching: false
  })

export const getUsersFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createUserRequest = (state: Object) =>
  state.merge({
    creatingUser: true,
    errors: []  
  })

export const createUserSuccess = (state: Object, { user }: Object) => 
  state.merge({
    users: [...state.users, user],
    creatingUser: false,
    isUserFormVisible: false,
    editUser: null
  })

export const createUserFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingUser: false,
    errors
  })

export const updateUserRequest = (state: Object) =>
  state.merge({
    updatingUser: true,
    errors: []  
  })

export const updateUserSuccess = (state: Object, { user }: Object) => {
  let users = state.users
  
  users = users.map((w) => {
    if (w.id === user.id) {
      return user
    }

    return w
  })

  return state.merge({ 
    updatingUser: false,
    isUserFormVisible: false,
    users,
    editUser: null
  })
}

export const updateUserFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingUser: false,
    errors
  })

export const destroyUserRequest = (state: Object) => 
  state.merge({ 
    destroyingUser: true,
    errors: [] 
  })

export const destroyUserSuccess = (state: Object, { user }: Object) =>
  state.merge({ 
    destroyingUser: false,
    users: state.users.filter((w) => w.id !== user.id)
  })
  
export const destroyUserFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingUser: false, 
    errors 
  })

export const toggleUserForm = (state: Object, { isUserFormVisible, editUser }: Object) =>
  state.merge({
    isUserFormVisible,
    editUser,
    errors: []
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,

  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,

  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,

  [Types.DESTROY_USER_REQUEST]: destroyUserRequest,
  [Types.DESTROY_USER_SUCCESS]: destroyUserSuccess,
  [Types.DESTROY_USER_FAILURE]: destroyUserFailure,

  [Types.TOGGLE_USER_FORM]: toggleUserForm
})