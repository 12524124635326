import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCitiesRequest: ['countryId'],
  getCitiesSuccess: ['cities'],
  getCitiesFailure: ['errors'],
})

export const CityTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  errors: [],
  fetching: false,
  cities: []
})

/* ------------- Reducers ------------- */

export const getCitiesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getCitiesSuccess = (state: Object, { cities }: Object) => 
  state.merge({
    cities,
    fetching: false
  })

export const getCitiesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CITIES_REQUEST]: getCitiesRequest,
  [Types.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [Types.GET_CITIES_FAILURE]: getCitiesFailure
})