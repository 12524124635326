import { put, call } from 'redux-saga/effects'
import CityActions from '../redux/CityRedux'

export function * getCities (api, action) {
  const { countryId } = action

  const resp = yield call(api.getCities, countryId)

  if (resp.ok) {
    yield put(CityActions.getCitiesSuccess(resp.data))
  } else {
    yield put(CityActions.getCitiesFailure(resp.data))
  }
}