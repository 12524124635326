import { connect } from 'react-redux'
import ReligionListComponent from '../../components/Admins/ReligionListComponent'
import ReligionActions from '../../redux/ReligionRedux'

const mapStateToProps = (state) => {  
  const { religion } = state
  
  return {
    religion
  }
}

const mapStateToDispatch = (dispatch) => ({
  getReligions: () => dispatch(ReligionActions.getReligionsRequest()),
  toggleReligionForm: (isReligionFormVisible, religion) => dispatch(ReligionActions.toggleReligionForm(isReligionFormVisible, religion)),
  clearReligionForm: () => dispatch(ReligionActions.clearReligionForm()),
  destroyReligion: (religion) => dispatch(ReligionActions.destroyReligionRequest(religion))
})

export default connect(mapStateToProps, mapStateToDispatch)(ReligionListComponent)