import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dimmer,
  Loader,
  Popup
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import moment from "moment";
import { Images } from "../../themes";

export default class EventMessageListComponent extends Component {
  componentDidMount() {
    this.props.getEvent(this.props.match.params.id);
  }

  renderMessages() {
    return (
      <Item.Group divided relaxed>
        {this.props.event.event.event_messages.map(m => {
          return (
            <Item key={m.id}>
              <Item.Image
                size="tiny"
                as="a"
                href={m.image_url ? Images.remote(m.image_url) : Images.logo}
                src={m.image_url ? Images.remote(m.image_url) : Images.logo}
                target='_blank'
              />

              <Item.Content>
                <Item.Header
                  style={
                    m.is_message_blacklisted || m.is_device_blacklisted
                      ? Styles.textRedBold
                      : Styles.textBold
                  }
                >
                  {m.name}
                  {m.is_message_blacklisted && " (mensaje bloqueado)"}
                  {m.is_device_blacklisted && " (usuario bloqueado)"}
                  {m.is_private && " (privado)"}
                </Item.Header>
                <Item.Meta>{moment(m.created_at).fromNow()}</Item.Meta>
                <Item.Description>{m.content}</Item.Description>
              </Item.Content>
              <Popup
                trigger={
                  <Icon
                    name={m.is_message_blacklisted ? "undo" : "trash"}
                    style={Styles.cardHeaderIcon}
                    onClick={() => {
                      this.props.toggleBlacklistEventMessage(m);
                    }}
                  />
                }
                content={
                  m.is_message_blacklisted
                    ? "Desbloquear mensaje"
                    : "Bloquear mensaje"
                }
                position="top center"
              />
              {!m.is_device_blacklisted && (
                <Popup
                  trigger={
                    <Icon
                      name="ban"
                      style={Styles.cardHeaderIcon}
                      onClick={() => {
                        this.props.blockEventMessage(m);
                      }}
                    />
                  }
                  content="Bloquear Usuario"
                  position="top center"
                />
              )}
              {m.is_device_blacklisted && (
                <Popup
                  trigger={
                    <Icon
                      name="undo"
                      style={Styles.cardHeaderIcon}
                      onClick={() => {
                        this.props.unblockEventMessage(m);
                      }}
                    />
                  }
                  content="Desbloquear Usuario"
                  position="top center"
                />
              )}
            </Item>
          );
        })}
      </Item.Group>
    );
  }

  render() {
    const event = this.props.event.event || {};

    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Dimmer inverted active={this.props.event.togglingEventMessage}>
              <Loader inverted />
            </Dimmer>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon
                  name="chevron left"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.context.router.history.goBack();
                  }}
                />
                <Item style={Styles.cardHeaderTitle}>
                  Mensajes de {event.event_name}
                </Item>
                <Item />
              </Card.Header>

              <Dimmer inverted active={this.props.event.fetching}>
                <Loader inverted />
              </Dimmer>

              <Item.Group divided relaxed>
                {this.props.event.event && this.renderMessages()}
              </Item.Group>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

EventMessageListComponent.contextTypes = {
  router: PropTypes.object
};
