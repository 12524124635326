import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCountriesRequest: null,
  getCountriesSuccess: ['countries'],
  getCountriesFailure: ['errors'],
})

export const CountryTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  errors: [],
  fetching: false,
  countries: []
})

/* ------------- Reducers ------------- */

export const getCountriesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  })

export const getCountriesSuccess = (state: Object, { countries }: Object) =>
  state.merge({
    countries,
    fetching: false
  })

export const getCountriesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,
  [Types.GET_COUNTRIES_SUCCESS]: getCountriesSuccess,
  [Types.GET_COUNTRIES_FAILURE]: getCountriesFailure
})
