const config = require("../config");
const node_env = process.env.NODE_ENV || "development";
const apiUrl = config[node_env]["apiUrl"];

const images = {
  logo: require("../images/logo.png"),
  bg: require("../images/bg-a.png"),
  bgWeb: require("../images/bg-web.png"),
  bgMobile: require("../images/bg-mobile.png"),
  pattern: require("../images/pattern.png"),
  remote: imgUrl => imgUrl
};

export default images;
