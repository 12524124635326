import { Colors, Images } from "../../themes";

export default {
  bg: {
    backgroundColor: "transparent"
  },
  content: {
    minHeight: "100%",
    margin: 0,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  cardButton: {
    fontFamily: "Raleway",
    lineHeight: "125%",
    width: "90%",
    maxWidth: 300,
    backgroundColor: "#37a2cc",
    color: Colors.snow,
    borderRadius: 50,
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10
  },
  cardButtonModal: {
    backgroundColor: "#37a2cc",
    color: Colors.snow,
    borderRadius: 50,
    fontSize: 16
  },
  cardContent: {},
  logo: {
    marginBottom: 5,
    width: 200
  },
  logo: {
    marginTop: 15,
    width: 200
  },
  message: {
    textAlign: "left",
    marginBottom: 15
  },
  messageTitle: {
    marginBottom: 5,
    color: Colors.charcoal,
    fontSize: 20,
    textAlign: "center"
  },
  messageText: {
    color: Colors.charcoal,
    fontSize: 16,
    textAlign: "center"
  },
  eventHeader: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightgray,
    paddingTop: 10,
    paddingBottom: 10
  },
  eventName: {
    fontSize: 32,
    color: Colors.charcoal,
    marginBottom: 5,
    lineHeight: 1
  },
  eventDates: {
    fontSize: 16,
    color: Colors.gray
  },
  inlineRadios: {
    justifyContent: "center",
    alignItems: "center"
  },
  logosContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 15
  },
  modalIcon: {
    color: "#fff"
  },
  logos: {
    display: "flex",
    width: "50%"
  }
};
