import { put, call } from 'redux-saga/effects'
import PromoActions from '../redux/PromoRedux'

export function * getPromos (api, action) {
  const { q } = action

  const resp = yield call(api.getPromos, q)

  if (resp.ok) {
    yield put(PromoActions.getPromosSuccess(resp.data))
  } else {
    yield put(PromoActions.getPromosFailure(resp.data))
  }
}

export function * getPromo (api, action) {
  const { promoId } = action

  const resp = yield call(api.getPromo, promoId)

  if (resp.ok) {
    yield put(PromoActions.getPromoSuccess(resp.data))
  } else {
    yield put(PromoActions.getPromoFailure(resp.data))
  }
}

export function * createPromo (api, action) {
  const { promo } = action

  const resp = yield call(api.createPromo, promo)

  if (resp.ok) {
    yield put(PromoActions.createPromoSuccess(resp.data))
  } else {
    yield put(PromoActions.createPromoFailure(resp.data))
  }
}

export function * updatePromo (api, action) {
  const { promo } = action

  const resp = yield call(api.updatePromo, promo)

  if (resp.ok) {
    yield put(PromoActions.updatePromoSuccess(resp.data))
  } else {
    yield put(PromoActions.updatePromoFailure(resp.data))
  }
}

export function * destroyPromo (api, action) {
  const { promo } = action

  const resp = yield call(api.destroyPromo, promo)

  if (resp.ok) {
    yield put(PromoActions.destroyPromoSuccess(resp.data))
  } else {
    yield put(PromoActions.destroyPromoFailure(resp.data))
  }
}

export function * toggleActivePromo (api, action) {
  const { promo } = action

  const resp = yield call(api.toggleActivePromo, promo)

  if (resp.ok) {
    yield put(PromoActions.toggleActivePromoSuccess(resp.data))
  } else {
    yield put(PromoActions.toggleActivePromoFailure(resp.data))
  }
}