import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown,
  Confirm
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import ErrorMessagesComponent from "./ErrorMessagesComponent";

export default class BranchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: {},
      destroyLayerHidden: true,
      branchToDestroy: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== "new") {
      this.props.getBranch(this.props.match.params.id);
    }

    this.props.getCities(this.props.userAccount.user.country_id);
  }

  componentWillUnmount() {
    this.props.clearBranchForm();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.branch.branch && nextProps.branch.branch) {
      this.setState({
        branch: nextProps.branch.branch
      });
    }

    if (this.props.branch.creatingBranch && !nextProps.branch.creatingBranch) {
      if (nextProps.branch.errors.length == 0) {
        this.context.router.history.push(
          `/branches/${nextProps.branch.branch.id}/rooms`
        );
      }
    }

    if (this.props.branch.updatingBranch && !nextProps.branch.updatingBranch) {
      if (nextProps.branch.errors.length == 0) {
        this.context.router.history.push("/");
      }
    }

    if (
      this.props.branch.destroyingBranch &&
      !nextProps.branch.destroyingBranch
    ) {
      if (nextProps.branch.errors.length == 0) {
        this.context.router.history.push("/");
      }
    }
  }

  saveBranch() {
    if (this.state.branch.id) {
      this.props.updateBranch(this.state.branch);
    } else {
      this.props.createBranch(this.state.branch);
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      branch: { ...this.state.branch, [name]: value }
    });
  }

  renderCities() {
    return this.props.city.cities.map(c => {
      return {
        text: c.name,
        value: c.id
      };
    });
  }

  destroyBranch() {
    this.props.destroyBranch(this.state.branch);
    this.setState({ destroyLayerHidden: true, branchToDestroy: null });
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon
                  name="chevron left"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.context.router.history.goBack();
                  }}
                />
                <Item style={Styles.cardHeaderTitle}>
                  {this.state.branch.id ? "Editar" : "Crear"} Establecimiento
                </Item>
                <Item />
              </Card.Header>

              <ErrorMessagesComponent messages={this.props.branch.errors} />
              <Form
                style={Styles.cardForm}
                loading={
                  this.props.branch.fetching ||
                  this.props.branch.creatingBranch ||
                  this.props.branch.updatingBranch
                }
                autoComplete="off"
              >
                <Form.Input
                  fluid
                  label="Nombre"
                  placeholder="Nombre"
                  name="name"
                  value={this.state.branch.name}
                  onChange={this.handleChange.bind(this)}
                />

                <Form.Input
                  fluid
                  label="Dirección"
                  placeholder="Dirección"
                  name="address"
                  value={this.state.branch.address}
                  onChange={this.handleChange.bind(this)}
                />

                <Form.Input
                  fluid
                  label="Teléfono"
                  placeholder="Teléfono"
                  name="phone"
                  value={this.state.branch.phone}
                  onChange={this.handleChange.bind(this)}
                />

                <Form.Select
                  fluid
                  label="Provincia"
                  placeholder="Provincia"
                  name="state_id"
                  options={this.renderCities()}
                  value={this.state.branch.state_id}
                  onChange={this.handleChange.bind(this)}
                />

                <Item style={Styles.actions}>
                  <Item>
                    {this.state.branch.id && (
                      <Button
                        style={Styles.cardFormButtonSecondary}
                        type="submit"
                        onClick={() => {
                          this.context.router.history.push(
                            `/branches/${this.state.branch.id}/rooms`
                          );
                        }}
                      >
                        Editar áreas
                      </Button>
                    )}

                    <Button
                      style={Styles.cardFormButton}
                      type="submit"
                      onClick={() => {
                        this.saveBranch();
                      }}
                    >
                      Guardar
                    </Button>
                  </Item>
                  {this.state.branch.id && (
                    <Button
                      style={Styles.cardFormButtonRed}
                      type="submit"
                      onClick={() => {
                        this.setState({
                          destroyLayerHidden: false,
                          branchToDestroy: this.state.branch
                        });
                      }}
                    >
                      Eliminar
                    </Button>
                  )}
                </Item>
              </Form>

              {this.state.branchToDestroy && (
                <Confirm
                  open={!this.state.destroyLayerHidden}
                  content={`Seguro desea eliminar ${this.state.branchToDestroy.name}?`}
                  cancelButton="Cancelar"
                  confirmButton="Aceptar"
                  onCancel={() => {
                    this.setState({
                      destroyLayerHidden: true,
                      branchToDestroy: null
                    });
                  }}
                  onConfirm={() => {
                    this.destroyBranch();
                  }}
                />
              )}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

BranchComponent.contextTypes = {
  router: PropTypes.object
};
