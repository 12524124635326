import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown,
  Checkbox
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import CompanyActions from "../../redux/CompanyRedux";
import CountryActions from "../../redux/CountryRedux";
import BackgroundImageActions from "../../redux/BackgroundImageRedux";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import loadImage from "blueimp-load-image";
import { dataURItoBlob } from "../../lib";

class CompanyFormComponent extends Component {
  constructor(props) {
    super(props);

    this.initial_state = {
      company: {
        name: "",
        number_of_screens: 0,
        billing_plan_id: null,
        logo: null,
        country_id: null,
        display_cross_in_name: false,
        background_image_id: null,
        custom_signature: null
      },
      imagePreviewUrl: null
    };

    this.state = this.initial_state;
  }

  componentDidMount() {
    this.props.getBillingPlans();
    this.props.getCountries();
    this.props.getBackgroundImages();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.company.editCompany && nextProps.company.editCompany) {
      this.setState({
        company: nextProps.company.editCompany,
        imagePreviewUrl: null
      });
    }

    if (
      !this.props.company.isCompanyFormVisible &&
      nextProps.company.isCompanyFormVisible
    ) {
      if (!nextProps.company.editCompany) {
        this.setState(this.initial_state);
      }
    }
  }

  saveCompany() {
    if (this.state.company.id) {
      this.props.updateCompany(this.state.company);
    } else {
      this.props.createCompany(this.state.company);
    }
  }

  renderBillingPlans() {
    return this.props.company.billingPlans.map(b => {
      return {
        text: b.title,
        value: b.id
      };
    });
  }

  renderCountries() {
    return this.props.country.countries.map(c => {
      return {
        text: c.name,
        value: c.id
      };
    });
  }

  renderBackgroundImages() {
    return this.props.backgroundImage.backgroundImages.map(b => {
      return {
        text: b.name,
        value: b.id
      };
    });
  }

  handleImageChange(e) {
    e.preventDefault();

    loadImage(
      e.target.files[0],
      img => {
        const b64 = img.toDataURL();

        this.setState({
          company: {
            ...this.state.company,
            logo: dataURItoBlob(b64),
            logo_url: null
          },
          imagePreviewUrl: b64
        });
      },
      { maxWidth: 2000, orientation: true }
    );
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon="close"
        open={this.props.company.isCompanyFormVisible}
        onClose={() => {
          this.props.closeCompanyForm();
        }}
      >
        <Modal.Header>
          {this.state.company.id ? "Editar" : "Crear"} Compañía
        </Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent messages={this.props.company.errors} />
          <Form
            loading={
              this.props.company.creatingCompany ||
              this.props.company.updatingCompany
            }
            autoComplete="off"
          >
            <Form.Field>
              <label>Nombre</label>
              <input
                value={this.state.company.name}
                onChange={e =>
                  this.setState({
                    company: {
                      ...this.state.company,
                      name: e.nativeEvent.target.value
                    }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Cantidad de pantallas</label>
              <input
                value={this.state.company.number_of_screens}
                type="number"
                min="0"
                onChange={e =>
                  this.setState({
                    company: {
                      ...this.state.company,
                      number_of_screens: e.nativeEvent.target.value
                    }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Plan</label>
              <Dropdown
                value={this.state.company.billing_plan_id}
                placeholder="Plan"
                fluid
                search
                selection
                options={this.renderBillingPlans()}
                onChange={(e, data) =>
                  this.setState({
                    company: {
                      ...this.state.company,
                      billing_plan_id: data.value
                    }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>País</label>
              <Dropdown
                value={this.state.company.country_id}
                placeholder="País"
                fluid
                search
                selection
                options={this.renderCountries()}
                onChange={(e, data) =>
                  this.setState({
                    company: { ...this.state.company, country_id: data.value }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                label="Mensaje inicial del evento"
                value={this.state.company.default_event_message}
                placeholder="Mensaje inicial del evento"
                onChange={e =>
                  this.setState({
                    company: {
                      ...this.state.company,
                      default_event_message: e.nativeEvent.target.value
                    }
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Logo de Compañía</label>
              <label className="fileContainer fileContainerSmall">
                <Icon name="upload" className="fileContainerIcon" />
                Seleccionar archivo
                <input
                  type="file"
                  onChange={e => this.handleImageChange(e)}
                  accept="image/*"
                />
              </label>
            </Form.Field>
            <div>
              {this.state.imagePreviewUrl && (
                <Image
                  src={this.state.imagePreviewUrl}
                  size="medium"
                  centered
                />
              )}
              {this.state.company.logo_url && (
                <Image
                  src={Images.remote(this.state.company.logo_url)}
                  size="medium"
                  centered
                />
              )}
            </div>
            <Form.Field>
              <label>Fondo de pantalla</label>
              <Dropdown
                value={this.state.company.background_image_id}
                placeholder="Fondo de pantalla"
                fluid
                search
                selection
                options={this.renderBackgroundImages()}
                onChange={(e, data) =>
                  this.setState({
                    company: {
                      ...this.state.company,
                      background_image_id: data.value
                    }
                  })
                }
              />
            </Form.Field>

            <Form.Field>
              <label>Fecha de Inicio</label>

              <Datetime
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                inputProps={{
                  name: "started_at",
                  placeholder: "Fecha de Inicio"
                }}
                closeOnSelect
                defaultValue={
                  this.state.company.started_at
                    ? moment(this.state.company.started_at).format("DD/MM/YYYY")
                    : ""
                }
                onChange={date => {
                  this.setState({
                    company: { ...this.state.company, started_at: date }
                  });
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>Fecha límite para plan gratuito</label>

              <Datetime
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                inputProps={{
                  name: "free_until",
                  placeholder: "Fecha límite para plan gratuito"
                }}
                closeOnSelect
                defaultValue={
                  this.state.company.free_until
                    ? moment(this.state.company.free_until).format("DD/MM/YYYY")
                    : ""
                }
                onChange={date => {
                  this.setState({
                    company: { ...this.state.company, free_until: date }
                  });
                }}
              />
            </Form.Field>

            <Button
              style={Styles.cardButton}
              type="submit"
              onClick={() => {
                this.saveCompany();
              }}
            >
              Guardar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { company, country, backgroundImage } = state;

  return {
    company,
    country,
    backgroundImage
  };
};

const mapStateToDispatch = dispatch => ({
  closeCompanyForm: () => dispatch(CompanyActions.toggleCompanyForm(false)),
  createCompany: company =>
    dispatch(CompanyActions.createCompanyRequest(company)),
  updateCompany: company =>
    dispatch(CompanyActions.updateCompanyRequest(company)),
  getBillingPlans: () => dispatch(CompanyActions.getBillingPlansRequest()),
  getCountries: () => dispatch(CountryActions.getCountriesRequest()),
  getBackgroundImages: () =>
    dispatch(BackgroundImageActions.getBackgroundImagesRequest())
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(CompanyFormComponent);
