import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dimmer,
  Loader,
  Popup,
  Confirm
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import moment from "moment";
import EventFormComponent from "./EventFormComponent";

export default class EventListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destroyLayerHidden: true,
      eventToDestroy: null
    };
  }

  componentDidMount() {
    this.props.getEvents();
  }

  openEvent(m) {
    window.open(`/event/${m.slug}?token=${m.token}`);
  }

  renderEvents() {
    return (
      <Item.Group divided relaxed>
        {this.props.event.events.map(m => {
          return (
            <Item key={m.id} style={Styles.listRow}>
              <Item.Content>
                <Item.Header style={Styles.textBold}>
                  {m.event_name}
                </Item.Header>
                <Item.Meta>
                  {moment(m.starts_at).fromNow()} (
                  {moment(m.starts_at).format("DD/MM/YYYY HH:mm")})
                </Item.Meta>
                <Item.Description></Item.Description>
              </Item.Content>
              <Item>
                <Popup
                  trigger={
                    <Icon
                      name="edit"
                      style={Styles.cardHeaderIcon}
                      onClick={() => {
                        this.props.toggleEventForm(true, m);
                      }}
                    />
                  }
                  content="Editar Evento"
                  position="top center"
                />
                {/*
                  <Popup
                    trigger={
                      <Icon
                        name="browser"
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.openEvent(m);
                        }}
                      />
                    }
                    content="Ver Evento"
                    position="top center"
                  />
                  */}
                <Popup
                  trigger={
                    <Icon
                      name="mail outline"
                      style={Styles.cardHeaderIcon}
                      onClick={() => {
                        this.context.router.history.push(
                          `/events/${m.id}/messages`
                        );
                      }}
                    />
                  }
                  content="Ver Mensajes"
                  position="top center"
                />
                <Popup
                  trigger={
                    <Icon
                      name="trash"
                      style={Styles.cardHeaderIcon}
                      onClick={() => {
                        this.setState({
                          destroyLayerHidden: false,
                          eventToDestroy: m
                        });
                      }}
                    />
                  }
                  content="Eliminar Evento"
                  position="top center"
                />
              </Item>
            </Item>
          );
        })}
      </Item.Group>
    );
  }

  destroyEvent() {
    this.props.destroyEvent(this.state.eventToDestroy);
    this.setState({ destroyLayerHidden: true, eventToDestroy: null });
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon
                  name="chevron left"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.context.router.history.goBack();
                  }}
                />
                <Item style={Styles.cardHeaderTitle}>Eventos</Item>
                <Item />
              </Card.Header>

              <Dimmer inverted active={this.props.event.fetching}>
                <Loader inverted />
              </Dimmer>

              <Item.Group divided relaxed>
                {this.renderEvents()}
              </Item.Group>

              {this.state.eventToDestroy && (
                <Confirm
                  open={!this.state.destroyLayerHidden}
                  content={`Seguro desea eliminar ${this.state.eventToDestroy.event_name}?`}
                  cancelButton="Cancelar"
                  confirmButton="Aceptar"
                  onCancel={() => {
                    this.setState({
                      destroyLayerHidden: true,
                      eventToDestroy: null
                    });
                  }}
                  onConfirm={() => {
                    this.destroyEvent();
                  }}
                />
              )}
            </Card.Content>
          </Card>

          <EventFormComponent />
        </Grid.Column>
      </Grid>
    );
  }
}

EventListComponent.contextTypes = {
  router: PropTypes.object
};
