import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Sidebar, Segment, Button, Menu, Image, Icon, Item, Header, Confirm, Dimmer, Loader, Popup } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import ReligionFormComponent from './ReligionFormComponent'
import { toast } from 'react-toastify'

export default class ReligionListComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      destroyLayerHidden: true,
      religionToDestroy: null
    }
  }

  componentDidMount() {
    this.props.getReligions()
  }

  componentWillUnmount() {
    this.props.clearReligionForm()
  }

  renderReligions() {
    return this.props.religion.religions.map((r) => {
      return (
        <Item key={r.id}>
          <Item.Content verticalAlign='middle'>
            <Item.Header as='a'>{r.name}</Item.Header>
          </Item.Content>
          <Popup trigger={<Icon name='pencil' style={Styles.cardHeaderIcon} onClick={() => { this.props.toggleReligionForm(true, r) }} />} content='Editar Religión' position='top center' />
          <Popup trigger={<Icon name='trash' style={Styles.cardHeaderIcon} onClick={() => { this.setState({ destroyLayerHidden: false, religionToDestroy: r }) }} />} content='Eliminar Religión' position='top center' />
        </Item>
      )
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.religion.creatingReligion && !nextProps.religion.creatingReligion) {
      if (nextProps.religion.errors.length) {
        toast.error(nextProps.religion.errors.join(", "))
      }
      else {
        toast.success("Religión creada correctamente")
      }
    }

    if (this.props.religion.destroyingReligion && !nextProps.religion.destroyingReligion) {
      if (nextProps.religion.errors.length) {
        toast.error(nextProps.religion.errors.join(", "))
      }
      else {
        toast.success("Religión eliminada correctamente")
      }
    }
  }

  destroyReligion() {
    this.props.destroyReligion(this.state.religionToDestroy)
    this.setState({ destroyLayerHidden: true, religionToDestroy: null })
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid >
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeader}>
                Religiones
                <Button style={Styles.cardButton} onClick={ () => { this.props.toggleReligionForm(true) }}><Icon name='plus' style={Styles.cardIcon} /> Nueva Religión</Button>
              </Card.Header>
            </Card.Content>
          </Card>

          <Card fluid>
            {this.props.religion.religions.length > 0 && <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                Religiones
              </Card.Header>
              <Item.Group divided relaxed>
                {this.renderReligions()}
              </Item.Group>
            </Card.Content>}

            {this.props.religion.religions.length == 0 && <Card.Content style={Styles.cardContent}>
              <Item.Group divided relaxed>
                Aún no has creado ninguna religión
              </Item.Group>
            </Card.Content>}

          </Card>

          <Dimmer inverted active={this.props.religion.fetching}>
            <Loader inverted />
          </Dimmer>

          {this.state.religionToDestroy && <Confirm
            open={!this.state.destroyLayerHidden}
            content={`Seguro desea eliminar ${this.state.religionToDestroy.name}?`}
            cancelButton='Cancelar'
            confirmButton="Aceptar"
            onCancel={() => { this.setState({ destroyLayerHidden: true, religionToDestroy: null }) }}
            onConfirm={() => { this.destroyReligion() }} />}

          <ReligionFormComponent />
        </Grid.Column>
      </Grid>
    )
  }
}

ReligionListComponent.contextTypes = {
  router: PropTypes.object
}
