import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Sidebar, Segment, Button, Menu, Image, Icon, Item, Header, Dimmer, Loader, Popup } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import BackgroundImageFormComponent from './BackgroundImageFormComponent'

export default class BackgroundImageListComponent extends Component {

  componentDidMount() {
    this.props.getBackgroundImages()
  }

  renderBackgroundImages() {
    return this.props.backgroundImage.backgroundImages.map((b) => {
      return (
        <Item key={b.id}>
          <Item.Content verticalAlign='middle'>
            <Item.Header>{b.name}</Item.Header>
          </Item.Content>
          <Popup trigger={<Icon name='pencil' style={Styles.cardHeaderIcon} onClick={() => { this.props.toggleBackgroundImageForm(true, b) }} />} content='Editar Fondo' position='top center' />
        </Item>
      )
    })
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid >
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeader}>
                Fondos de pantalla
                <Button style={Styles.cardButton} onClick={ () => { this.props.toggleBackgroundImageForm(true) }}><Icon name='plus' style={Styles.cardIcon} /> Nuevo Fondo</Button>
              </Card.Header>
            </Card.Content>
          </Card>
          <Card fluid>
            {this.props.backgroundImage.backgroundImages.length > 0 && <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                Fondos de pantalla
              </Card.Header>
              <Item.Group divided relaxed>
                {this.renderBackgroundImages()}
              </Item.Group>
            </Card.Content>}

            {this.props.backgroundImage.backgroundImages.length == 0 && <Card.Content style={Styles.cardContent}>
              <Item.Group divided relaxed>
                Aún no has creado ningún fondo
              </Item.Group>
            </Card.Content>}
          </Card>

          <Dimmer inverted active={this.props.backgroundImage.fetching}>
            <Loader inverted />
          </Dimmer>

          <BackgroundImageFormComponent />
        </Grid.Column>
      </Grid>
    )
  }
}

BackgroundImageListComponent.contextTypes = {
  router: PropTypes.object
}
