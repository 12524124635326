import { put, call } from "redux-saga/effects";
import UserAccountActions from "../redux/UserAccountRedux";
import storage from "redux-persist/lib/storage";
import { AbilityBuilder } from "casl";

export function* userLoginRequest(api, action) {
  const { email, password } = action;

  const resp = yield call(api.userLogin, email, password);

  if (resp.ok) {
    const user = resp.data;
    storage.setItem("user", JSON.stringify(user));

    yield call(api.setHeaders, {
      "X-User-Email": user.email,
      "X-User-Token": user.authentication_token
    });

    yield put(UserAccountActions.userLoginSuccess(user));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data.error];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userLoginFailure(errors));
  }
}

export function* userLoginVerify(api, action) {
  const { user } = action;

  yield call(api.setHeaders, {
    "X-User-Email": user.email,
    "X-User-Token": user.authentication_token
  });

  const resp = yield call(api.userVerifyLogin);

  if (resp.ok) {
    const user = resp.data;

    window.ability = AbilityBuilder.define((can, cannot) => {
      user.permissions.forEach(permission => {
        if (permission.can) {
          can(permission.actions, permission.subjects, permission.conditions);
        } else {
          cannot(
            permission.actions,
            permission.subjects,
            permission.conditions
          );
        }
      });
    });

    yield put(UserAccountActions.userLoginVerifySuccess(user));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data.error];
    } else {
      errors = [resp.problem];
    }

    storage.removeItem("user");

    yield call(api.setHeaders, {
      "X-User-Email": null,
      "X-User-Token": null
    });

    yield put(UserAccountActions.userLoginVerifyFailure(errors));
  }
}

export function* userConfirm(api, action) {
  const { confirmationToken } = action;

  const resp = yield call(api.userConfirm, confirmationToken);

  if (resp.ok) {
    const user = resp.data;
    storage.setItem("user", JSON.stringify(user));

    yield call(api.setHeaders, {
      "X-User-Email": user.email,
      "X-User-Token": user.authentication_token
    });

    yield put(UserAccountActions.userConfirmSuccess(user));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userConfirmFailure(errors));
  }
}

export function* userResetPasswordInstructions(api, action) {
  const { email } = action;

  const resp = yield call(api.userResetPasswordInstructions, email);

  if (resp.ok) {
    yield put(UserAccountActions.userResetPasswordInstructionsSuccess());
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userResetPasswordInstructionsFailure(errors));
  }
}

export function* userResetPassword(api, action) {
  const { user } = action;

  const resp = yield call(api.userResetPassword, user);

  if (resp.ok) {
    yield put(UserAccountActions.userResetPasswordSuccess(resp.data));
  } else {
    let errors;

    if (resp.problem === "CLIENT_ERROR") {
      errors = [resp.data];
    } else {
      errors = [resp.problem];
    }

    yield put(UserAccountActions.userResetPasswordFailure(errors));
  }
}

export function* userLogout(api, action) {
  storage.removeItem("user");

  yield call(api.setHeaders, {
    "X-User-Email": null,
    "X-User-Token": null
  });
}

export function* userUpdateCompany(api, action) {
  const { company } = action;

  const resp = yield call(api.userUpdateCompany, company);

  if (resp.ok) {
    yield put(UserAccountActions.userUpdateCompanySuccess(resp.data));
  } else {
    yield put(UserAccountActions.userUpdateCompanyFailure(resp.data));
  }
}
