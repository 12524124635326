import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPromosRequest: ['q'],
  getPromosSuccess: ['promos'],
  getPromosFailure: ['errors'],

  getPromoRequest: ['promoId'],
  getPromoSuccess: ['promo'],
  getPromoFailure: ['errors'],

  createPromoRequest: ['promo'],
  createPromoSuccess: ['promo'],
  createPromoFailure: ['errors'],

  updatePromoRequest: ['promo'],
  updatePromoSuccess: ['promo'],
  updatePromoFailure: ['errors'],

  destroyPromoRequest: ['promo'],
  destroyPromoSuccess: ['promo'],
  destroyPromoFailure: ['errors'],

  toggleActivePromoRequest: ['promo'],
  toggleActivePromoSuccess: ['promo'],
  toggleActivePromoFailure: ['errors'],

  togglePromoForm: ['isPromoFormVisible', 'editPromo'],
  clearPromo: null
})

export const PromoTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  promo: null,
  errors: [],
  creatingPromo: false,
  fetching: false,
  promos: [],
  updatingPromo: false,
  isPromoFormVisible: false,
  editPromo: null,
  destroyingPromo: false
})

/* ------------- Reducers ------------- */

export const getPromosRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getPromosSuccess = (state: Object, { promos }: Object) => 
  state.merge({
    promos,
    fetching: false
  })

export const getPromosFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getPromoRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getPromoSuccess = (state: Object, { promo }: Object) => 
  state.merge({
    promo,
    fetching: false
  })

export const getPromoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createPromoRequest = (state: Object) =>
  state.merge({
    creatingPromo: true,
    errors: []  
  })

export const createPromoSuccess = (state: Object, { promo }: Object) => 
  state.merge({
    promos: [...state.promos, promo],
    creatingPromo: false,
    isPromoFormVisible: false,
    editPromo: null
  })

export const createPromoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingPromo: false,
    errors
  })

export const updatePromoRequest = (state: Object) =>
  state.merge({
    updatingPromo: true,
    errors: []
  })

export const updatePromoSuccess = (state: Object, { promo }: Object) => {
  let promos = state.promos
  
  promos = promos.map((w) => {
    if (w.id === promo.id) {
      return promo
    }

    return w
  })

  return state.merge({ 
    updatingPromo: false,
    isPromoFormVisible: false,
    promos,
    editPromo: null
  })
}

export const updatePromoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingPromo: false,
    errors
  })

export const destroyPromoRequest = (state: Object) => 
  state.merge({ 
    destroyingPromo: true,
    errors: [] 
  })

export const destroyPromoSuccess = (state: Object, { promo }: Object) =>
  state.merge({ 
    destroyingPromo: false,
    promos: state.promos.filter((w) => w.id !== promo.id)
  })
  
export const destroyPromoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingPromo: false, 
    errors 
  })

export const toggleActivePromoRequest = (state: Object) => 
  state.merge({ 
    updatingPromo: true,
    errors: [] 
  })

export const toggleActivePromoSuccess = (state: Object, { promo }: Object) =>
  state.merge({ 
    updatingPromo: false,
    promos: state.promos.map(p => p.id === promo.id ? promo : p),
  })
  
export const toggleActivePromoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingPromo: false, 
    errors 
  })

export const togglePromoForm = (state: Object, { isPromoFormVisible, editPromo }: Object) =>
  state.merge({
    isPromoFormVisible,
    editPromo,
    errors: []
  })

export const clearPromo = (state: Object) =>
  INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROMOS_REQUEST]: getPromosRequest,
  [Types.GET_PROMOS_SUCCESS]: getPromosSuccess,
  [Types.GET_PROMOS_FAILURE]: getPromosFailure,

  [Types.GET_PROMO_REQUEST]: getPromoRequest,
  [Types.GET_PROMO_SUCCESS]: getPromoSuccess,
  [Types.GET_PROMO_FAILURE]: getPromoFailure,

  [Types.CREATE_PROMO_REQUEST]: createPromoRequest,
  [Types.CREATE_PROMO_SUCCESS]: createPromoSuccess,
  [Types.CREATE_PROMO_FAILURE]: createPromoFailure,

  [Types.UPDATE_PROMO_REQUEST]: updatePromoRequest,
  [Types.UPDATE_PROMO_SUCCESS]: updatePromoSuccess,
  [Types.UPDATE_PROMO_FAILURE]: updatePromoFailure,

  [Types.DESTROY_PROMO_REQUEST]: destroyPromoRequest,
  [Types.DESTROY_PROMO_SUCCESS]: destroyPromoSuccess,
  [Types.DESTROY_PROMO_FAILURE]: destroyPromoFailure,

  [Types.TOGGLE_ACTIVE_PROMO_REQUEST]: toggleActivePromoRequest,
  [Types.TOGGLE_ACTIVE_PROMO_SUCCESS]: toggleActivePromoSuccess,
  [Types.TOGGLE_ACTIVE_PROMO_FAILURE]: toggleActivePromoFailure,

  [Types.TOGGLE_PROMO_FORM]: togglePromoForm,
  [Types.CLEAR_PROMO]: clearPromo
})