import { connect } from 'react-redux'
import BranchComponent from '../../components/Shared/BranchComponent'
import BranchActions from '../../redux/BranchRedux'
import CityActions from '../../redux/CityRedux'

const mapStateToProps = (state) => {  
  const { branch, city, userAccount } = state
  
  return {
    branch,
    city,
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  getCities: (countryId) => dispatch(CityActions.getCitiesRequest(countryId)),
  getBranch: (branchId) => dispatch(BranchActions.getBranchRequest(branchId)),
  createBranch: (branch) => dispatch(BranchActions.createBranchRequest(branch)),
  updateBranch: (branch) => dispatch(BranchActions.updateBranchRequest(branch)),
  clearBranchForm: () => dispatch(BranchActions.clearBranchForm()),
  destroyBranch: (branch) => dispatch(BranchActions.destroyBranchRequest(branch))
})

export default connect(mapStateToProps, mapStateToDispatch)(BranchComponent)