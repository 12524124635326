import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import queryString from "query-string";

import UserAppContainer from "../containers/Users/AppContainer";
import AdminAppContainer from "../containers/Admins/AppContainer";

import UserForgotPasswordContainer from "../containers/Users/ForgotPasswordContainer";
import UserPasswordEditContainer from "../containers/Users/PasswordEditContainer";
import UserConfirmationContainer from "../containers/Users/ConfirmationContainer";
import AdminLoginContainer from "../containers/Admins/LoginContainer";
import UserLoginContainer from "../containers/Users/LoginContainer";
import ActivateScreenContainer from "../containers/Shared/ActivateScreenContainer";
import EventContainer from "../containers/Shared/EventContainer";
import LeaveMessageContainer from "../containers/Shared/LeaveMessageContainer";

export default class NavigationRouter extends Component {

  state = {
    loading: true,
  }

  componentDidMount() {
    const subdomain = window.location.hostname.split(".");
    const params = queryString.parse(window.location.search);

    if (params.eventCode || subdomain[0] === "app") {
      this.setState({ loading: false });
      return;
    }

    window.location.href = 'https://sites.google.com/view/nit-point';
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    const params = queryString.parse(window.location.search);

    if (params.eventCode) {
      return (
        <Router>
          <Switch>
            <Route exact path="/event/:id" component={EventContainer} />
            <Route path="/" component={LeaveMessageContainer} />
          </Switch>
        </Router>
      );
    }

    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/users/forgot"
            component={UserForgotPasswordContainer}
          />
          <Route
            exact
            path="/users/password/edit"
            component={UserPasswordEditContainer}
          />
          <Route
            exact
            path="/users/confirmation"
            component={UserConfirmationContainer}
          />
          <Route exact path="/receiver" component={ActivateScreenContainer} />
          <Route exact path="/event/:id" component={EventContainer} />
          <Route exact path="/enviar" component={LeaveMessageContainer} />
          <Route exact path="/admins/login" component={AdminLoginContainer} />
          <Route exact path="/login" component={UserLoginContainer} />
          <Route path="/admins" component={AdminAppContainer} />
          <Route path="/" component={UserAppContainer} />
        </Switch>
      </Router>
    )
  }
}
