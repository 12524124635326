import React, { Component } from "react";

import storage from "redux-persist/lib/storage";

import DashboardContainer from "../../containers/Users/DashboardContainer";
import EventListContainer from "../../containers/Users/EventListContainer";
import SettingsContainer from "../../containers/Users/SettingsContainer";
import ReportsContainer from "../../containers/Shared/ReportsContainer";
import BranchContainer from "../../containers/Shared/BranchContainer";
import RoomListContainer from "../../containers/Shared/RoomListContainer";
import ScreenContainer from "../../containers/Shared/ScreenContainer";
import EventMessageListContainer from "../../containers/Users/EventMessageListContainer";
import EventsToPayContainer from "../../containers/Shared/Reports/EventsToPayContainer";
import PromoContainer from "../../containers/Shared/PromoContainer";
import PromosComponent from "../../components/Shared/PromoListComponent";

import Navbar from "./Shared/Navbar";
import { Segment, Dimmer, Loader, Container } from "semantic-ui-react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

import { Route } from "react-router-dom";

import moment from "moment";
import "moment/locale/es";

moment.locale("es");

export default class AppComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userVerified: false,
      user: {}
    };
  }

  async componentDidMount() {
    const data = await storage.getItem("user");
    const user = JSON.parse(data);

    if (!user) {
      this.context.router.history.push("/login");
      return;
    } else {
      this.props.loginVerify(user);
    }

    this.setState({ user });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.userAccount.fetching && !newProps.userAccount.fetching) {
      if (newProps.userAccount.user) {
        this.setState({ userVerified: true });
        return;
      }

      this.context.router.history.push("/login");
    }
  }

  handleLogout() {
    storage.removeItem("user");
    this.context.router.history.push("/login");
  }

  render() {
    if (!this.state.userVerified) {
      return (
        <Container>
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        </Container>
      );
    }

    return (
      <div>
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          closeButton={false}
        />

        <Navbar />
        <Segment basic>
          <Route exact path="/" component={DashboardContainer} />
          <Route exact path="/branches/:id" component={BranchContainer} />
          <Route
            exact
            path="/branches/:id/rooms"
            component={RoomListContainer}
          />
          <Route exact path="/promos" component={PromosComponent} />
          <Route exact path="/promos/:id" component={PromoContainer} />
          <Route exact path="/events" component={EventListContainer} />
          <Route exact path="/settings" component={SettingsContainer} />
          <Route exact path="/rooms/:id/screen" component={ScreenContainer} />
          <Route
            exact
            path="/events/:id/messages"
            component={EventMessageListContainer}
          />
          <Route exact path="/reports" component={ReportsContainer} />
          <Route
            exact
            path="/reports/events"
            component={EventsToPayContainer}
          />
        </Segment>
      </div>
    );
  }
}

AppComponent.contextTypes = {
  router: PropTypes.object
};
