import React, { Component } from "react";
import { Responsive, Dropdown, Menu, Image, Icon } from "semantic-ui-react";

import { Images, Colors } from "../../../themes";
import Styles from "../../Styles/NavbarStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserAccountActions from "../../../redux/UserAccountRedux";

class Navbar extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.userAccount.user && !nextProps.userAccount.user) {
      this.context.router.history.push("/login");
    }
  }

  render() {
    return (
      <Menu style={Styles.navbar} stackable>
        <Responsive
          as={Menu.Item}
          onClick={() => {
            this.context.router.history.push("/");
          }}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Image src={Images.logo} style={Styles.navbarLogo} />
        </Responsive>

        {this.props.userAccount.user && (
          <Responsive as={Menu.Item} minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{ fontWeight: 600, color: Colors.secondary }}>
              {this.props.userAccount.user.first_name +
                " " +
                this.props.userAccount.user.last_name}
            </div>
          </Responsive>
        )}

        <Menu.Menu position="right">
          <Dropdown text={Menu} simple item icon={"ellipsis vertical"}>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  this.context.router.history.push(`/`);
                }}
              >
                Establecimientos
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.context.router.history.push(`/events`);
                }}
              >
                Eventos
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.context.router.history.push(`/settings`);
                }}
              >
                Configuracion
              </Dropdown.Item>
              {/* 
              <Dropdown.Item
                onClick={() => {
                  this.context.router.history.push(`/reports`);
                }}
              >
                Reportes
              </Dropdown.Item>*/}
              <Dropdown.Item
                onClick={() => {
                  this.props.logout();
                }}
              >
                Salir
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}

Navbar.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = state => {
  const { userAccount } = state;

  return {
    userAccount
  };
};

const mapStateToDispatch = dispatch => ({
  logout: () => dispatch(UserAccountActions.userReset())
});

export default connect(mapStateToProps, mapStateToDispatch)(Navbar);
