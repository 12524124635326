import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getTypeOfBurialsRequest: [],
  getTypeOfBurialsSuccess: ['typeOfBurials'],
  getTypeOfBurialsFailure: ['errors'],
})

export const TypeOfBurialTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  errors: [],
  fetching: false,
  typeOfBurials: []
})

/* ------------- Reducers ------------- */

export const getTypeOfBurialsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getTypeOfBurialsSuccess = (state: Object, { typeOfBurials }: Object) => 
  state.merge({
    typeOfBurials,
    fetching: false
  })

export const getTypeOfBurialsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TYPE_OF_BURIALS_REQUEST]: getTypeOfBurialsRequest,
  [Types.GET_TYPE_OF_BURIALS_SUCCESS]: getTypeOfBurialsSuccess,
  [Types.GET_TYPE_OF_BURIALS_FAILURE]: getTypeOfBurialsFailure
})