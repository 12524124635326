import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Sidebar, Segment, Button, Menu, Image, Icon, Item, Header, Dimmer, Loader, Popup } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import CompanyFormComponent from './CompanyFormComponent'

export default class DashboardComponent extends Component {

  componentDidMount() {
    this.props.getCompanies()
  }

  renderCompanies() {
    return this.props.company.companies.map((c) => {
      return (
        <Item key={c.id}>
          <Item.Content verticalAlign='middle'>
            <Item.Header as='a'>{c.name}</Item.Header>
            <Item.Description>{c.number_of_screens} pantallas</Item.Description>
          </Item.Content>
          <Popup trigger={<Icon name='eye' style={Styles.cardHeaderIcon} onClick={() => { this.context.router.history.push('/admins/companies/' + c.id) }} />} content='Ver Companía' position='top center' />
          <Popup trigger={<Icon name='pencil' style={Styles.cardHeaderIcon} onClick={() => { this.props.toggleCompanyForm(true, c) }} />} content='Editar Companía' position='top center' />
        </Item>
      )
    })
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid >
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeader}>
                Bienvenido a Nitpoint
                <Button style={Styles.cardButton} onClick={ () => { this.props.toggleCompanyForm(true) }}><Icon name='plus' style={Styles.cardIcon} /> Nueva Compañía</Button>
              </Card.Header>
            </Card.Content>
          </Card>
          <Card fluid>
            {this.props.company.companies.length > 0 && <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                Compañías
              </Card.Header>
              <Item.Group divided relaxed>
                {this.renderCompanies()}
              </Item.Group>
            </Card.Content>}

            {this.props.company.companies.length == 0 && <Card.Content style={Styles.cardContent}>
              <Item.Group divided relaxed>
                Aún no has creado ninguna compañía
              </Item.Group>
            </Card.Content>}
          </Card>

          <Dimmer inverted active={this.props.company.fetching}>
            <Loader inverted />
          </Dimmer>

          <CompanyFormComponent />
        </Grid.Column>
      </Grid>
    )
  }
}

DashboardComponent.contextTypes = {
  router: PropTypes.object
}