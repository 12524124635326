import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown,
  Radio,
  Checkbox
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import EventActions from "../../redux/EventRedux";
import BranchActions from "../../redux/BranchRedux";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class EventFormComponent extends Component {
  constructor(props) {
    super(props);

    this.initial_state = {
      event: {
        image: null,
        display_code_in_room: true
      },
      imagePreviewUrl: null,
      crop: {},
      cropImage: null
    };

    this.state = this.initial_state;
  }

  componentDidMount() {
    this.props.getBranches();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.event.editEvent && nextProps.event.editEvent) {
      this.setState({
        event: {
          ...nextProps.event.editEvent,
          branch_id: nextProps.event.editEvent.room.branch_id
        },
        imagePreviewUrl: null
      });
    }

    if (
      !this.props.event.isEventFormVisible &&
      nextProps.event.isEventFormVisible
    ) {
      if (!nextProps.event.editEvent) {
        this.setState(this.initial_state);
      }
    }
  }

  saveEvent() {
    if (this.state.event.id) {
      this.props.updateEvent(this.state.event);
    } else {
      this.props.createEvent(this.state.event);
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      event: { ...this.state.event, [name]: value }
    });
  }

  renderBranches() {
    return this.props.branch.branches.map(b => {
      return {
        text: b.name,
        value: b.id
      };
    });
  }

  renderRooms() {
    if (!this.state.event.branch_id) {
      return [];
    }

    return this.props.branch.branches
      .find(b => b.id == this.state.event.branch_id)
      .rooms.map(r => {
        return {
          text: r.name,
          value: r.id
        };
      });
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        event: {
          ...this.state.event,
          image: file,
          image_url: null,
          remove_event_image_request: false
        },
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  }

  onImageLoaded(image) {
    this.setState({
      crop: makeAspectCrop(
        {
          x: 0,
          y: 0,
          aspect: 4 / 4,
          width: 50
        },
        image.width / image.height
      ),
      cropImage: image
    });
  }

  async onCompleteCrop(crop, pixelCrop) {
    const croppedImg = await this.getCroppedImg(
      this.state.cropImage,
      pixelCrop,
      this.state.event.image.name
    );
    this.setState({ event: { ...this.state.event, image: croppedImg } });
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon="close"
        open={this.props.event.isEventFormVisible}
        onClose={() => {
          this.props.closeEventForm();
        }}
      >
        <Modal.Header>
          {this.state.event.id ? "Editar" : "Crear"} Evento
        </Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent messages={this.props.event.errors} />
          <Form
            loading={
              this.props.event.creatingEvent || this.props.event.updatingEvent
            }
            autoComplete="off"
          >
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Nombre"
                placeholder="Nombre (Opcional)"
                name="name"
                value={this.state.event.name}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Fecha Inicio del Evento</label>

                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  defaultValue={
                    this.state.event.starts_at
                      ? moment(this.state.event.starts_at).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      : ""
                  }
                  inputProps={{
                    name: "starts_at",
                    placeholder: "Fecha Inicio del Evento"
                  }}
                  closeOnSelect
                  onChange={date => {
                    this.handleChange(null, { name: "starts_at", value: date });
                  }}
                />
              </Form.Field>

              <Form.Field>
                <label>Fecha Fin del Evento</label>

                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  defaultValue={
                    this.state.event.ends_at
                      ? moment(this.state.event.ends_at).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      : ""
                  }
                  inputProps={{
                    name: "ends_at",
                    placeholder: "Fecha Fin del Evento"
                  }}
                  closeOnSelect
                  onChange={date => {
                    this.handleChange(null, { name: "ends_at", value: date });
                  }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Select
                fluid
                label="Establecimiento"
                placeholder="Establecimiento"
                name="branch_id"
                options={this.renderBranches()}
                value={this.state.event.branch_id}
                onChange={this.handleChange.bind(this)}
              />

              <Form.Select
                fluid
                label="Área"
                placeholder="Área"
                name="room_id"
                options={this.renderRooms()}
                value={this.state.event.room_id}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Porcentaje de seguridad de filtro de imágenes"
                placeholder="Porcentaje de seguridad de filtro de imágenes"
                name="filter_confidence"
                value={this.state.event.filter_confidence || 40}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Límite de imagenes a mostrar"
                placeholder="Límite de imagenes a mostrar"
                name="messages_limit"
                value={this.state.event.messages_limit}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Group>

            <Form.Field>
              <Checkbox
                label="Mostrar código en pantalla"
                checked={this.state.event.display_code_in_room}
                onChange={(e, input) => {
                  this.handleChange(null, {
                    name: "display_code_in_room",
                    value: input.checked
                  });
                }}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox
                label="Moderación manual"
                checked={this.state.event.manual_moderation}
                onChange={(e, input) => {
                  this.handleChange(null, {
                    name: "manual_moderation",
                    value: input.checked
                  });
                }}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox
                label="Sólo imágenes"
                checked={this.state.event.image_only}
                onChange={(e, input) => {
                  this.handleChange(null, {
                    name: "image_only",
                    value: input.checked
                  });
                }}
              />
            </Form.Field>

            <Button
              style={Styles.cardFormButton}
              type="submit"
              onClick={() => {
                this.saveEvent();
              }}
            >
              Guardar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { event, religion, branch, typeOfBurial } = state;

  return {
    event,
    religion,
    branch,
    typeOfBurial
  };
};

const mapStateToDispatch = dispatch => ({
  getBranches: () => dispatch(BranchActions.getBranchesRequest()),
  closeEventForm: () => dispatch(EventActions.toggleEventForm(false)),
  createEvent: event => dispatch(EventActions.createEventRequest(event)),
  updateEvent: event => dispatch(EventActions.updateEventRequest(event))
});

export default connect(mapStateToProps, mapStateToDispatch)(EventFormComponent);
