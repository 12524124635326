import { connect } from 'react-redux'
import SettingsComponent from '../../components/Users/SettingsComponent'
import UserActions from '../../redux/UserRedux'
import UserAccountActions from '../../redux/UserAccountRedux'

const mapStateToProps = (state) => {
  const { user, userAccount } = state
  
  return {
    user,
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  getUsers: () => dispatch(UserActions.getUsersRequest()),
  toggleUserForm: (isUserFormVisible, user) => dispatch(UserActions.toggleUserForm(isUserFormVisible, user)),
  destroyUser: (user) => dispatch(UserActions.destroyUserRequest(user)),
  toggleCompanyForm: (isFormVisible, company) => dispatch(UserAccountActions.userToggleCompanyForm(isFormVisible, company))  
})

export default connect(mapStateToProps, mapStateToDispatch)(SettingsComponent)