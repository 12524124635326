import React, { Component } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import BackgroundImageActions from "../../redux/BackgroundImageRedux";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import loadImage from "blueimp-load-image";
import { dataURItoBlob } from "../../lib";

class BackgroundImageFormComponent extends Component {
  constructor(props) {
    super(props);

    this.initial_state = {
      background_image: {
        name: "",
        image: null
      },
      imagePreviewUrl: null
    };

    this.state = this.initial_state;
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.backgroundImage.editBackgroundImage &&
      nextProps.backgroundImage.editBackgroundImage
    ) {
      this.setState({
        background_image: nextProps.backgroundImage.editBackgroundImage,
        imagePreviewUrl: null
      });
    }

    if (
      !this.props.backgroundImage.isBackgroundImageFormVisible &&
      nextProps.backgroundImage.isBackgroundImageFormVisible
    ) {
      if (!nextProps.backgroundImage.editBackgroundImage) {
        this.setState(this.initial_state);
      }
    }
  }

  saveBackgroundImage() {
    if (this.state.background_image.id) {
      this.props.updateBackgroundImage(this.state.background_image);
    } else {
      this.props.createBackgroundImage(this.state.background_image);
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      background_image: { ...this.state.background_image, [name]: value }
    });
  }

  handleImageChange(e) {
    e.preventDefault();

    loadImage(
      e.target.files[0],
      img => {
        const b64 = img.toDataURL();

        this.setState({
          background_image: {
            ...this.state.background_image,
            image: dataURItoBlob(b64),
            image_url: null
          },
          imagePreviewUrl: b64
        });
      },
      { maxWidth: 2000, orientation: true }
    );
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon="close"
        open={this.props.backgroundImage.isBackgroundImageFormVisible}
        onClose={() => {
          this.props.closeBackgroundImageForm();
        }}
      >
        <Modal.Header>
          {this.state.background_image.id ? "Editar" : "Crear"} Fondo de
          pantalla
        </Modal.Header>
        <Modal.Content>
          <ErrorMessagesComponent
            messages={this.props.backgroundImage.errors}
          />
          <Form
            loading={
              this.props.backgroundImage.creatingBackgroundImage ||
              this.props.backgroundImage.updatingBackgroundImage
            }
            autoComplete="off"
          >
            <Form.Input
              fluid
              label="Nombre"
              placeholder="Nombre"
              name="name"
              value={this.state.background_image.name}
              onChange={this.handleChange.bind(this)}
            />

            <Form.Field>
              <label>Fondo</label>
              <label className="fileContainer fileContainerSmall">
                <Icon name="upload" className="fileContainerIcon" />
                Seleccionar archivo
                <input
                  type="file"
                  onChange={e => this.handleImageChange(e)}
                  accept="image/*"
                />
              </label>
            </Form.Field>

            <div>
              {this.state.imagePreviewUrl && (
                <Image
                  src={this.state.imagePreviewUrl}
                  size="medium"
                  centered
                />
              )}
              {this.state.background_image.image_url && (
                <Image
                  src={Images.remote(this.state.background_image.image_url)}
                  size="medium"
                  centered
                />
              )}
            </div>

            <Button
              style={Styles.cardButton}
              type="submit"
              onClick={() => {
                this.saveBackgroundImage();
              }}
            >
              Guardar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { backgroundImage } = state;

  return {
    backgroundImage
  };
};

const mapStateToDispatch = dispatch => ({
  closeBackgroundImageForm: () =>
    dispatch(BackgroundImageActions.toggleBackgroundImageForm(false)),
  createBackgroundImage: backgroundImage =>
    dispatch(
      BackgroundImageActions.createBackgroundImageRequest(backgroundImage)
    ),
  updateBackgroundImage: backgroundImage =>
    dispatch(
      BackgroundImageActions.updateBackgroundImageRequest(backgroundImage)
    )
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(BackgroundImageFormComponent);
