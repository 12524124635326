import { put, call } from "redux-saga/effects";
import AdminAccountActions from "../redux/AdminAccountRedux";
import storage from "redux-persist/lib/storage";
import { AbilityBuilder } from "casl";

export function* adminLoginRequest(api, action) {
  const { email, password } = action;

  const resp = yield call(api.adminLogin, email, password);

  if (resp.ok) {
    const admin = resp.data;
    storage.setItem("admin", JSON.stringify(admin));

    yield call(api.setHeaders, {
      "X-Admin-Email": admin.email,
      "X-Admin-Token": admin.authentication_token
    });

    yield put(AdminAccountActions.adminLoginSuccess(admin));
  } else {
    const errors = [resp.data.error];
    yield put(AdminAccountActions.adminLoginFailure(errors));
  }
}

export function* adminLoginVerify(api, action) {
  const { admin } = action;

  yield call(api.setHeaders, {
    "X-Admin-Email": admin.email,
    "X-Admin-Token": admin.authentication_token
  });

  const resp = yield call(api.adminVerifyLogin);

  if (resp.ok) {
    const admin = resp.data;

    window.ability = AbilityBuilder.define((can, cannot) => {
      admin.permissions.forEach(permission => {
        if (permission.can) {
          can(permission.actions, permission.subjects, permission.conditions);
        } else {
          cannot(
            permission.actions,
            permission.subjects,
            permission.conditions
          );
        }
      });
    });

    yield put(AdminAccountActions.adminLoginVerifySuccess(admin));
  } else {
    const errors = [resp.data.error];

    storage.removeItem("admin");

    yield call(api.setHeaders, {
      "X-Admin-Email": null,
      "X-Admin-Token": null
    });

    yield put(AdminAccountActions.adminLoginVerifyFailure(errors));
  }
}

export function* adminLogout(api, action) {
  storage.removeItem("admin");

  yield call(api.setHeaders, {
    "X-Admin-Email": null,
    "X-Admin-Token": null
  });
}

export function* adminGetEventsToPay(api, action) {
  const { q } = action;
  const resp = yield call(api.adminGetEventsToPay, q);

  if (resp.ok) {
    yield put(AdminAccountActions.adminGetEventsToPaySuccess(resp.data));
  } else {
    yield put(AdminAccountActions.adminGetEventsToPayFailure(resp.data));
  }
}
