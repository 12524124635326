import { connect } from 'react-redux'
import EventComponent from '../../components/Shared/EventComponent'
import PublicControllerActions from '../../redux/PublicControllerRedux'

const mapStateToProps = (state) => {
  const { publicController } = state

  return {
    publicController
  }
}

const mapStateToDispatch = (dispatch) => ({
  findEventBySlug: (eventId, token) => dispatch(PublicControllerActions.findEventBySlugRequest(eventId, token)),
  removeEventMessageWithToken: (messageId, token) => dispatch(PublicControllerActions.removeEventMessageWithTokenRequest(messageId, token))
})

export default connect(mapStateToProps, mapStateToDispatch)(EventComponent)
