import React, { Component } from "react";
import {
  Form,
  Grid,
  Segment,
  Icon,
  Button,
  Message,
  Image,
  Item,
  Modal
} from "semantic-ui-react";
import Styles from "../Styles/LeaveMessageComponentInEventStyles";
import { Images } from "../../themes";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import storage from "redux-persist/lib/storage";
import { connect } from "react-redux";
import PublicControllerActions from "../../redux/PublicControllerRedux";

class LeaveMessageInEventComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: null,
      message: {
        name: "",
        email: ""
      },
      imagePreviewUrl: null,
      modalOpen: false
    };
  }

  async componentDidMount() {
    let deviceId = await storage.getItem("device_id");

    if (!deviceId) {
      deviceId = this.props.event.device_id;
      storage.setItem("device_id", deviceId);
    }

    this.setState({ message: { ...this.state.message, device_id: deviceId } });
  }

  async componentWillReceiveProps(nextProps) {
    if (
      this.props.publicController.creatingMessage &&
      !nextProps.publicController.creatingMessage
    ) {
      if (nextProps.publicController.errors.length === 0) {
        this.setState({
          message: {
            ...this.state.message,
            name: "",
            content: "",
            email: ""
          },
          imagePreviewUrl: null,
          modalOpen: false
        });

        document.querySelector('input[type="file"]').value = null;
      }
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      message: { ...this.state.message, [name]: value }
    });
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        message: { ...this.state.message, image: file },
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  removeImage() {
    this.setState({
      message: { ...this.state.message },
      imagePreviewUrl: null
    });
    document.querySelector('input[type="file"]').value = null;
  }

  render() {
    const event = this.props.event;

    return (
      <Modal
        basic
        size="small"
        closeIcon
        trigger={
          <Button onClick={this.handleOpen} style={Styles.cardButton} primary>
            Dejar Mensaje
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Content>
          <Grid textAlign="center" verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 400 }}>
              <Message style={Styles.logosContainer}>
                <Image src={Images.logo} style={Styles.logos} />
              </Message>

              <Form size="large" autoComplete="off">
                <Segment stacked>
                  <Item style={Styles.message}>
                    <Item style={Styles.messageText}>
                      A continuacion puede dejar su mensaje
                    </Item>
                  </Item>

                  <Form autoComplete="off">
                    <ErrorMessagesComponent
                      messages={this.props.publicController.errors}
                    />

                    <Form.Input
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder="Su Nombre"
                      name="name"
                      value={this.state.message.name}
                      onChange={this.handleChange.bind(this)}
                    />

                    <Form.Input
                      fluid
                      icon="mail"
                      iconPosition="left"
                      placeholder="Su E-Mail"
                      name="email"
                      value={this.state.message.email}
                      onChange={this.handleChange.bind(this)}
                    />

                    {!event.image_only && (
                      <Form.TextArea
                        fluid
                        placeholder="Mensaje"
                        name="content"
                        value={this.state.message.content}
                        onChange={this.handleChange.bind(this)}
                      />
                    )}

                    <Form.Field>
                      <label className="fileContainer">
                        <Icon name="camera" style={Styles.modalIcon} />
                        Añadir una foto
                        <input
                          type="file"
                          onChange={e => this.handleImageChange(e)}
                          accept="image/*"
                        />
                      </label>
                    </Form.Field>

                    {this.state.imagePreviewUrl && (
                      <div className="image-preview">
                        <Button
                          className="right floated"
                          content="Eliminar foto"
                          icon="trash"
                          labelPosition="left"
                          onClick={() => {
                            this.removeImage();
                          }}
                        />
                        {this.state.imagePreviewUrl && (
                          <Image
                            src={this.state.imagePreviewUrl}
                            size="medium"
                            centered
                          />
                        )}
                      </div>
                    )}

                    <Button
                      loading={this.props.publicController.creatingMessage}
                      fluid
                      style={Styles.cardButtonModal}
                      primary
                      onClick={() => {
                        this.props.createEventMessage(
                          event.code,
                          this.state.message
                        );
                      }}
                    >
                      Enviar
                    </Button>
                  </Form>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { publicController } = state;

  return {
    publicController
  };
};

const mapStateToDispatch = dispatch => ({
  createEventMessage: (code, eventMessage) =>
    dispatch(
      PublicControllerActions.createEventMessageRequest(code, eventMessage)
    )
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(LeaveMessageInEventComponent);
