import React, { Component } from "react";
import storage from "redux-persist/lib/storage";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment
} from "semantic-ui-react";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import { Images } from "../../themes";
import Styles from "../Styles/LoginComponentStyles";

export default class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      email: "",
      password: ""
    };
  }

  async componentDidMount() {
    const data = await storage.getItem("admin");
    const admin = JSON.parse(data);

    if (admin) {
      this.props.loginVerify(admin);
      return;
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.adminAccount.fetching && !newProps.adminAccount.fetching) {
      if (newProps.adminAccount.errors.length) {
        this.setState({ errors: newProps.adminAccount.errors });
        return;
      }

      if (newProps.adminAccount.admin) {
        this.context.router.history.push("/admins");
        return;
      }
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    this.props.login(this.state.email, this.state.password);
  }

  render() {
    const { email, password, errors } = this.state;

    return (
      <Grid textAlign="center" style={Styles.loginForm} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size="large">
            <Segment stacked>
              <Header>
                <Image src={Images.logo} style={Styles.loginLogo} />
              </Header>
              <Header.Subheader style={Styles.adminTitle}>
                Panel de Administración
              </Header.Subheader>
              <Form>
                <ErrorMessagesComponent messages={errors} />
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-Mail"
                  name="email"
                  value={email}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Contraseña"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange.bind(this)}
                />
                <Button
                  fluid
                  size="large"
                  style={Styles.loginButton}
                  primary
                  loading={this.props.adminAccount.fetching}
                  onClick={this.handleSubmit.bind(this)}
                >
                  Ingresar
                </Button>
              </Form>
            </Segment>
          </Form>
          <Message style={Styles.loginSegment}>
            <Image centered src={Images.logo} style={Styles.loginLogo} />
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

LoginComponent.contextTypes = {
  router: PropTypes.object
};
