import { connect } from 'react-redux'
import LoginComponent from '../../components/Admins/LoginComponent'
import AdminAccountActions from '../../redux/AdminAccountRedux'

const mapStateToProps = (state) => {
  const { adminAccount } = state
  
  return {
    adminAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  login: (email, password) => dispatch(AdminAccountActions.adminLoginRequest(email, password)),
  loginVerify: (admin) => dispatch(AdminAccountActions.adminLoginVerify(admin))
})

export default connect(mapStateToProps, mapStateToDispatch)(LoginComponent)
