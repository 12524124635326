import React, { Component } from "react";

import storage from "redux-persist/lib/storage";

import DashboardContainer from "../../containers/Admins/DashboardContainer";
import CompanyContainer from "../../containers/Admins/CompanyContainer";
import ReligionListContainer from "../../containers/Admins/ReligionListContainer";
import BackgroundImageListContainer from "../../containers/Admins/BackgroundImageListContainer";
import CompanyEventsToPayContainer from "../../containers/Shared/Reports/EventsToPayContainer";
import ReportsContainer from "../../containers/Admins/ReportsContainer";
import EventsToPayContainer from "../../containers/Admins/Reports/EventsToPayContainer";

import Navbar from "./Shared/Navbar";
import { Segment, Loader, Dimmer, Container } from "semantic-ui-react";

import PropTypes from "prop-types";

import { Route } from "react-router-dom";

export default class AppComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminVerified: false,
      admin: {}
    };
  }

  async componentDidMount() {
    const data = await storage.getItem("admin");
    const admin = JSON.parse(data);

    if (!admin) {
      this.context.router.history.push("/admins/login");
      return;
    } else {
      this.props.loginVerify(admin);
    }

    this.setState({ admin });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.adminAccount.fetching && !newProps.adminAccount.fetching) {
      if (newProps.adminAccount.admin) {
        this.setState({ adminVerified: true });
        return;
      }

      this.context.router.history.push("/admins/login");
    }
  }

  handleLogout() {
    storage.removeItem("admin");
    this.context.router.history.push("/admins/login");
  }

  render() {
    if (!this.state.adminVerified) {
      return (
        <Container>
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        </Container>
      );
    }

    return (
      <div>
        <Navbar />
        <Segment basic>
          <Route exact path="/admins" component={DashboardContainer} />
          <Route
            exact
            path="/admins/religions"
            component={ReligionListContainer}
          />
          <Route
            exact
            path="/admins/companies/:id"
            component={CompanyContainer}
          />
          <Route
            exact
            path="/admins/companies/:id/reports/events"
            component={CompanyEventsToPayContainer}
          />
          <Route
            exact
            path="/admins/background_images"
            component={BackgroundImageListContainer}
          />
          <Route exact path="/admins/reports" component={ReportsContainer} />
          <Route
            exact
            path="/admins/reports/events"
            component={EventsToPayContainer}
          />
        </Segment>
      </div>
    );
  }
}

AppComponent.contextTypes = {
  router: PropTypes.object
};
