import { connect } from 'react-redux'
import ReportsComponent from '../../components/Admins/ReportsComponent'

const mapStateToProps = (state) => {
  const { adminAccount } = state

  return {
    adminAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
})

export default connect(mapStateToProps, mapStateToDispatch)(ReportsComponent)
