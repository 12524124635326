import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getBackgroundImagesRequest: [],
  getBackgroundImagesSuccess: ['backgroundImages'],
  getBackgroundImagesFailure: ['errors'],

  getBackgroundImageRequest: ['backgroundImageId'],
  getBackgroundImageSuccess: ['backgroundImage'],
  getBackgroundImageFailure: ['errors'],

  createBackgroundImageRequest: ['backgroundImage'],
  createBackgroundImageSuccess: ['backgroundImage'],
  createBackgroundImageFailure: ['errors'],

  updateBackgroundImageRequest: ['backgroundImage'],
  updateBackgroundImageSuccess: ['backgroundImage'],
  updateBackgroundImageFailure: ['errors'],

  destroyBackgroundImageRequest: ['backgroundImage'],
  destroyBackgroundImageSuccess: ['backgroundImage'],
  destroyBackgroundImageFailure: ['errors'],

  clearBackgroundImageForm: [],
  toggleBackgroundImageForm: ['isBackgroundImageFormVisible', 'editBackgroundImage']
})

export const BackgroundImageTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  backgroundImage: null,
  errors: [],
  creatingBackgroundImage: false,
  fetching: false,
  backgroundImages: [],
  updatingBackgroundImage: false,
  isBackgroundImageFormVisible: false,
  editBackgroundImage: null,
  destroyingBackgroundImage: false
})

/* ------------- Reducers ------------- */

export const getBackgroundImagesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  })

export const getBackgroundImagesSuccess = (state: Object, { backgroundImages }: Object) =>
  state.merge({
    backgroundImages,
    fetching: false
  })

export const getBackgroundImagesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getBackgroundImageRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []
  })

export const getBackgroundImageSuccess = (state: Object, { backgroundImage }: Object) =>
  state.merge({
    backgroundImage,
    fetching: false
  })

export const getBackgroundImageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createBackgroundImageRequest = (state: Object) =>
  state.merge({
    creatingBackgroundImage: true,
    errors: []
  })

export const createBackgroundImageSuccess = (state: Object, { backgroundImage }: Object) =>
  state.merge({
    backgroundImages: [...state.backgroundImages, backgroundImage],
    creatingBackgroundImage: false,
    isBackgroundImageFormVisible: false,
    editBackgroundImage: null
  })

export const createBackgroundImageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingBackgroundImage: false,
    errors
  })

export const updateBackgroundImageRequest = (state: Object) =>
  state.merge({
    updatingBackgroundImage: true,
    errors: []
  })

export const updateBackgroundImageSuccess = (state: Object, { backgroundImage }: Object) => {
  let backgroundImages = state.backgroundImages

  backgroundImages = backgroundImages.map((w) => {
    if (w.id === backgroundImage.id) {
      return backgroundImage
    }

    return w
  })

  return state.merge({
    updatingBackgroundImage: false,
    isBackgroundImageFormVisible: false,
    backgroundImages,
    editBackgroundImage: null
  })
}

export const updateBackgroundImageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingBackgroundImage: false,
    errors
  })

export const destroyBackgroundImageRequest = (state: Object) =>
  state.merge({
    destroyingBackgroundImage: true,
    errors: []
  })

export const destroyBackgroundImageSuccess = (state: Object, { backgroundImage }: Object) =>
  state.merge({
    destroyingBackgroundImage: false,
    backgroundImages: state.backgroundImages.filter((w) => w.id !== backgroundImage.id)
  })

export const destroyBackgroundImageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingBackgroundImage: false,
    errors
  })

export const clearBackgroundImageForm = (state: Object ) =>
  state.merge({
    backgroundImage: null
  })

export const toggleBackgroundImageForm = (state: Object, { isBackgroundImageFormVisible, editBackgroundImage }: Object) =>
  state.merge({
    isBackgroundImageFormVisible,
    editBackgroundImage,
    errors: []
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BACKGROUND_IMAGES_REQUEST]: getBackgroundImagesRequest,
  [Types.GET_BACKGROUND_IMAGES_SUCCESS]: getBackgroundImagesSuccess,
  [Types.GET_BACKGROUND_IMAGES_FAILURE]: getBackgroundImagesFailure,

  [Types.GET_BACKGROUND_IMAGE_REQUEST]: getBackgroundImageRequest,
  [Types.GET_BACKGROUND_IMAGE_SUCCESS]: getBackgroundImageSuccess,
  [Types.GET_BACKGROUND_IMAGE_FAILURE]: getBackgroundImageFailure,

  [Types.CREATE_BACKGROUND_IMAGE_REQUEST]: createBackgroundImageRequest,
  [Types.CREATE_BACKGROUND_IMAGE_SUCCESS]: createBackgroundImageSuccess,
  [Types.CREATE_BACKGROUND_IMAGE_FAILURE]: createBackgroundImageFailure,

  [Types.UPDATE_BACKGROUND_IMAGE_REQUEST]: updateBackgroundImageRequest,
  [Types.UPDATE_BACKGROUND_IMAGE_SUCCESS]: updateBackgroundImageSuccess,
  [Types.UPDATE_BACKGROUND_IMAGE_FAILURE]: updateBackgroundImageFailure,

  [Types.DESTROY_BACKGROUND_IMAGE_REQUEST]: destroyBackgroundImageRequest,
  [Types.DESTROY_BACKGROUND_IMAGE_SUCCESS]: destroyBackgroundImageSuccess,
  [Types.DESTROY_BACKGROUND_IMAGE_FAILURE]: destroyBackgroundImageFailure,

  [Types.CLEAR_BACKGROUND_IMAGE_FORM]: clearBackgroundImageForm,
  [Types.TOGGLE_BACKGROUND_IMAGE_FORM]: toggleBackgroundImageForm
})
