import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dimmer,
  Loader,
  Popup,
  Confirm,
  Statistic
} from "semantic-ui-react";
import Styles from "../../Styles/DashboardComponentStyles";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
import storage from "redux-persist/lib/storage";

const config = require("../../../config");
const node_env = process.env.NODE_ENV || "development";
const apiUrl = config[node_env]["apiUrl"];

export default class EventsToPayComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      q: {
        starts_at_gteq: moment().startOf("month"),
        starts_at_lteq: moment().endOf("month")
      }
    };
  }

  componentDidMount() {
    if (this.props.match.params.id)
      this.props.getCompany(this.props.match.params.id);
    else this.props.getEventsToPay(this.state);
  }

  componentWillUnmount() {
    if (this.props.match.params.id) this.props.clearCompany();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.company.fetching && !nextProps.company.fetching) {
      if (nextProps.company.company) {
        this.props.getEventsToPay(this.state);
      }
    }
  }

  renderEvents() {
    return (
      <Item.Group divided relaxed>
        {this.props.event.events.map(m => {
          return (
            <Item key={m.id}>
              <Item.Content>
                <Item.Header style={Styles.textBold}>
                  {m.event_name}
                </Item.Header>
                <Item.Meta>
                  {`${moment(m.starts_at).format(
                    "DD/MM/YYYY HH:mm"
                  )} (${moment
                    .duration(moment(m.ends_at).diff(m.starts_at))
                    .humanize()})`}
                </Item.Meta>
              </Item.Content>
            </Item>
          );
        })}
      </Item.Group>
    );
  }

  getTotalToPay() {
    return this.props.event.events
      .map(m => {
        return parseFloat(m.price);
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
  }

  handleChange(e, { name, value }) {
    let q = {
      starts_at_gteq: moment(this.state.q.starts_at_gteq),
      starts_at_lteq: moment(this.state.q.starts_at_lteq),
      [name]: value
    };

    this.setState({
      q
    });

    this.props.getEventsToPay({ q });
  }

  async downloadXls() {
    let data = await storage.getItem("admin");
    const admin = JSON.parse(data);

    if (admin && this.props.company && this.props.company.company) {
      window.open(
        `${apiUrl}/events/to_pay.xlsx?company_id=${this.props.company.company.id}&admin_email=${admin.email}&admin_token=${admin.authentication_token}&` +
          $.param({ q: this.state.q })
      );
      return;
    }

    data = await storage.getItem("user");
    const user = JSON.parse(data);

    if (user) {
      window.open(
        `${apiUrl}/events/to_pay.xlsx?company_id=${user.company_id}&user_email=${user.email}&user_token=${user.authentication_token}&` +
          $.param({ q: this.state.q })
      );
    }
  }

  render() {
    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon
                  name="chevron left"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.context.router.history.goBack();
                  }}
                />
                <Item style={Styles.cardHeaderTitle}>
                  Eventos contabilizados
                </Item>
                <Item />
              </Card.Header>

              <Segment>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Desde</label>

                      <Datetime
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        defaultValue={this.state.q.starts_at_gteq}
                        inputProps={{ placeholder: "Desde" }}
                        closeOnSelect
                        onChange={date => {
                          this.handleChange(null, {
                            name: "starts_at_gteq",
                            value: date
                          });
                        }}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>Hasta</label>

                      <Datetime
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        defaultValue={this.state.q.starts_at_lteq}
                        inputProps={{ placeholder: "Hasta" }}
                        closeOnSelect
                        onChange={date => {
                          this.handleChange(null, {
                            name: "starts_at_lteq",
                            value: date
                          });
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>

              <Segment>
                <Statistic.Group widths="two">
                  <Statistic horizontal>
                    <Statistic.Value>
                      {this.props.event.events.length}
                    </Statistic.Value>
                    <Statistic.Label>Eventos generados</Statistic.Label>
                  </Statistic>
                  <Statistic horizontal>
                    <Statistic.Value>${this.getTotalToPay()}</Statistic.Value>
                    <Statistic.Label>Total generado</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>

              <Dimmer inverted active={this.props.event.fetching}>
                <Loader inverted />
              </Dimmer>

              <Segment.Group>
                <Segment.Group>
                  <Segment style={Styles.textBold}>
                    Eventos
                    <Popup
                      trigger={
                        <Icon
                          name="download"
                          style={Styles.cardHeaderIcon}
                          onClick={() => {
                            this.downloadXls();
                          }}
                        />
                      }
                      content="Descargar a Excel"
                      position="top center"
                    />
                  </Segment>
                </Segment.Group>
                <Segment.Group>
                  <Segment>{this.renderEvents()}</Segment>
                </Segment.Group>
              </Segment.Group>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

EventsToPayComponent.contextTypes = {
  router: PropTypes.object
};
