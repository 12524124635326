import { connect } from 'react-redux'
import ReportsComponent from '../../components/Shared/ReportsComponent'

const mapStateToProps = (state) => {
  const { userAccount } = state

  return {
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
})

export default connect(mapStateToProps, mapStateToDispatch)(ReportsComponent)
