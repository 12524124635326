import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dropdown,
  Popup,
  Dimmer,
  Loader
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import PromoActions from "../../redux/PromoRedux";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { Colors } from "../../themes";

class PromoActiveListComponent extends Component {
  renderPromos() {
    if (!this.props.promos || this.props.promos.length == 0) {
      return;
    }

    return this.props.promos.map(p => {
      return (
        <Card fluid key={p.id}>
          <Card.Content>
            <Card.Header style={Styles.cardHeaderList}>
              <Item>{p.name}</Item>

              <Item>{`de ${p.starts_at} a ${p.ends_at} hs`}</Item>

              {window.ability.can("manage", "Promo") && (
                <Item>
                  <Popup
                    trigger={
                      <Icon
                        name={p.is_active ? "pause" : "play"}
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.props.toggleActivePromo(p);
                        }}
                      />
                    }
                    content={p.is_active ? "Deshabilitar" : "Habilitar"}
                    position="top center"
                  />
                </Item>
              )}

              {window.ability.can("update", "Promo") && (
                <Item>
                  <Popup
                    trigger={
                      <Icon
                        name="edit"
                        style={Styles.cardHeaderIcon}
                        onClick={() => {
                          this.context.router.history.push(`/promos/${p.id}`);
                        }}
                      />
                    }
                    content="Editar promoción"
                    position="top center"
                  />
                </Item>
              )}
            </Card.Header>
          </Card.Content>
        </Card>
      );
    });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.promo.creatingPromo && !newProps.promo.creatingPromo) {
      if (newProps.promo.errors.length) {
        toast.error(newProps.promo.errors.join(", "));
      } else {
        toast.success("Promoción eliminada correctamente");
      }
    }
  }

  destroyPromo() {
    this.props.destroyPromo(this.state.promoToDestroy);
    this.setState({ destroyLayerHidden: true, promoToDestroy: null });
  }

  render() {
    return (
      <div>
        <Card fluid>
          <Card.Content style={Styles.cardContent}>
            <Card.Header>
              <Grid centered columns={2} style={Styles.cardHeaderList}>
                <Item style={Styles.cardHeaderTitle}>
                  Promociones activas
                  <Link
                    to="/promos"
                    style={{
                      fontSize: 14,
                      marginLeft: 10,
                      color: Colors.primary
                    }}
                  >
                    (Ver todas)
                  </Link>
                </Item>
                {window.ability.can("create", "Promo") && (
                  <Button
                    style={Styles.cardButton}
                    onClick={() => {
                      this.context.router.history.push("/promos/new");
                    }}
                  >
                    <Icon name="plus" style={Styles.cardIcon} />
                    Crear Promoción
                  </Button>
                )}
              </Grid>
            </Card.Header>

            {this.props.promos.length > 0 && (
              <Item.Group divided relaxed>
                {this.renderPromos()}
              </Item.Group>
            )}

            {this.props.promos.length == 0 && (
              <Card.Content style={Styles.cardContent}>
                <Item.Group divided relaxed style={Styles.notFoundText}>
                  No tienes ninguna promoción activa
                </Item.Group>
              </Card.Content>
            )}
          </Card.Content>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { promo } = state;

  return {
    promo
  };
};

const mapStateToDispatch = dispatch => ({
  togglePromoForm: (isPromoFormVisible, promo) =>
    dispatch(PromoActions.togglePromoForm(isPromoFormVisible, promo)),
  createPromo: promo => dispatch(PromoActions.createUserRequest(promo)),
  updatePromo: promo => dispatch(PromoActions.updateUserRequest(promo)),
  toggleActivePromo: promo =>
    dispatch(PromoActions.toggleActivePromoRequest(promo))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(PromoActiveListComponent);

PromoActiveListComponent.contextTypes = {
  router: PropTypes.object
};
