import { connect } from "react-redux";
import ActivateScreenComponent from "../../components/Shared/ActivateScreenComponent";
import PublicControllerActions from "../../redux/PublicControllerRedux";

const mapStateToProps = state => {
  const { publicController } = state;

  return {
    publicController
  };
};

const mapStateToDispatch = dispatch => ({
  activateScreen: code =>
    dispatch(PublicControllerActions.activateScreenRequest(code)),
  findRoomDeviceByFingerprint: deviceFingerprint =>
    dispatch(
      PublicControllerActions.findRoomDeviceByFingerprintRequest(
        deviceFingerprint
      )
    ),
  getBranchById: branchId =>
    dispatch(PublicControllerActions.getBranchByIdRequest(branchId))
});

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ActivateScreenComponent);
