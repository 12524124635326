import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";
import NavigationRouter from "./navigation/NavigationRouter";

import "./App.css";
import "semantic-ui-css/semantic.min.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NavigationRouter />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
