import { put, call } from 'redux-saga/effects'
import BackgroundImageActions from '../redux/BackgroundImageRedux'

export function * getBackgroundImages (api, action) {
  const resp = yield call(api.getBackgroundImages)

  if (resp.ok) {
    yield put(BackgroundImageActions.getBackgroundImagesSuccess(resp.data))
  } else {
    yield put(BackgroundImageActions.getBackgroundImagesFailure(resp.data))
  }
}

export function * getBackgroundImage (api, action) {
  const { backgroundImageId } = action

  const resp = yield call(api.getBackgroundImage, backgroundImageId)

  if (resp.ok) {
    yield put(BackgroundImageActions.getBackgroundImageSuccess(resp.data))
  } else {
    yield put(BackgroundImageActions.getBackgroundImageFailure(resp.data))
  }
}

export function * createBackgroundImage (api, action) {
  const { backgroundImage } = action

  const resp = yield call(api.createBackgroundImage, backgroundImage)

  if (resp.ok) {
    yield put(BackgroundImageActions.createBackgroundImageSuccess(resp.data))
  } else {
    yield put(BackgroundImageActions.createBackgroundImageFailure(resp.data))
  }
}

export function * updateBackgroundImage (api, action) {
  const { backgroundImage } = action

  const resp = yield call(api.updateBackgroundImage, backgroundImage)

  if (resp.ok) {
    yield put(BackgroundImageActions.updateBackgroundImageSuccess(resp.data))
  } else {
    yield put(BackgroundImageActions.updateBackgroundImageFailure(resp.data))
  }
}

export function * destroyBackgroundImage (api, action) {
  const { backgroundImage } = action

  const resp = yield call(api.destroyBackgroundImage, backgroundImage)

  if (resp.ok) {
    yield put(BackgroundImageActions.destroyBackgroundImageSuccess(resp.data))
  } else {
    yield put(BackgroundImageActions.destroyBackgroundImageFailure(resp.data))
  }
}
