import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getBranchesRequest: [],
  getBranchesSuccess: ['branches'],
  getBranchesFailure: ['errors'],

  getBranchRequest: ['branchId'],
  getBranchSuccess: ['branch'],
  getBranchFailure: ['errors'],

  createBranchRequest: ['branch'],
  createBranchSuccess: ['branch'],
  createBranchFailure: ['errors'],

  updateBranchRequest: ['branch'],
  updateBranchSuccess: ['branch'],
  updateBranchFailure: ['errors'],

  destroyBranchRequest: ['branch'],
  destroyBranchSuccess: ['branch'],
  destroyBranchFailure: ['errors'],

  clearBranchForm: []
})

export const BranchTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  branch: null,
  errors: [],
  creatingBranch: false,
  fetching: false,
  branches: [],
  updatingBranch: false,
  isBranchFormVisible: false,
  editBranch: null,
  destroyingBranch: false
})

/* ------------- Reducers ------------- */

export const getBranchesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getBranchesSuccess = (state: Object, { branches }: Object) => 
  state.merge({
    branches,
    fetching: false
  })

export const getBranchesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getBranchRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getBranchSuccess = (state: Object, { branch }: Object) => 
  state.merge({
    branch,
    fetching: false
  })

export const getBranchFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createBranchRequest = (state: Object) =>
  state.merge({
    creatingBranch: true,
    errors: []  
  })

export const createBranchSuccess = (state: Object, { branch }: Object) => 
  state.merge({
    branches: [...state.branches, branch],
    creatingBranch: false,
    isBranchFormVisible: false,
    branch,
    editBranch: null
  })

export const createBranchFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingBranch: false,
    errors,
    branch: null
  })

export const updateBranchRequest = (state: Object) =>
  state.merge({
    updatingBranch: true,
    errors: []  
  })

export const updateBranchSuccess = (state: Object, { branch }: Object) => {
  let branches = state.branches
  
  branches = branches.map((w) => {
    if (w.id === branch.id) {
      return branch
    }

    return w
  })

  return state.merge({ 
    updatingBranch: false,
    isBranchFormVisible: false,
    branches,
    editBranch: null
  })
}

export const updateBranchFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingBranch: false,
    errors
  })

export const destroyBranchRequest = (state: Object) => 
  state.merge({ 
    destroyingBranch: true,
    errors: [] 
  })

export const destroyBranchSuccess = (state: Object, { branch }: Object) =>
  state.merge({ 
    destroyingBranch: false,
    branches: state.branches.filter((w) => w.id !== branch.id)
  })
  
export const destroyBranchFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingBranch: false, 
    errors 
  })

export const clearBranchForm = (state: Object ) =>
  state.merge({
    branch: null
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BRANCHES_REQUEST]: getBranchesRequest,
  [Types.GET_BRANCHES_SUCCESS]: getBranchesSuccess,
  [Types.GET_BRANCHES_FAILURE]: getBranchesFailure,

  [Types.GET_BRANCH_REQUEST]: getBranchRequest,
  [Types.GET_BRANCH_SUCCESS]: getBranchSuccess,
  [Types.GET_BRANCH_FAILURE]: getBranchFailure,

  [Types.CREATE_BRANCH_REQUEST]: createBranchRequest,
  [Types.CREATE_BRANCH_SUCCESS]: createBranchSuccess,
  [Types.CREATE_BRANCH_FAILURE]: createBranchFailure,

  [Types.UPDATE_BRANCH_REQUEST]: updateBranchRequest,
  [Types.UPDATE_BRANCH_SUCCESS]: updateBranchSuccess,
  [Types.UPDATE_BRANCH_FAILURE]: updateBranchFailure,

  [Types.DESTROY_BRANCH_REQUEST]: destroyBranchRequest,
  [Types.DESTROY_BRANCH_SUCCESS]: destroyBranchSuccess,
  [Types.DESTROY_BRANCH_FAILURE]: destroyBranchFailure,

  [Types.CLEAR_BRANCH_FORM]: clearBranchForm
})