import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCompaniesRequest: [],
  getCompaniesSuccess: ['companies'],
  getCompaniesFailure: ['errors'],

  createCompanyRequest: ['company'],
  createCompanySuccess: ['company'],
  createCompanyFailure: ['errors'],

  updateCompanyRequest: ['company'],
  updateCompanySuccess: ['company'],
  updateCompanyFailure: ['errors'],

  destroyCompanyRequest: ['company'],
  destroyCompanySuccess: ['company'],
  destroyCompanyFailure: ['errors'],

  getCompanyRequest: ['companyId'],
  getCompanySuccess: ['company'],
  getCompanyFailure: ['errors'],

  getBillingPlansRequest: [],
  getBillingPlansSuccess: ['billingPlans'],
  getBillingPlansFailure: ['errors'],

  toggleCompanyForm: ['isCompanyFormVisible', 'editCompany'],

  clearCompany: null
})

export const CompanyTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  company: null,
  errors: [],
  creatingCompany: false,
  fetching: false,
  companies: [],
  updatingCompany: false,
  isCompanyFormVisible: false,
  editCompany: null,
  destroyingCompany: false,
  billingPlans: []
})

/* ------------- Reducers ------------- */

export const getCompaniesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getCompaniesSuccess = (state: Object, { companies }: Object) => 
  state.merge({
    companies,
    fetching: false
  })

export const getCompaniesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createCompanyRequest = (state: Object) =>
  state.merge({
    creatingCompany: true,
    errors: []  
  })

export const createCompanySuccess = (state: Object, { company }: Object) => 
  state.merge({
    companies: [...state.companies, company],
    creatingCompany: false,
    isCompanyFormVisible: false,
    editCompany: null
  })

export const createCompanyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingCompany: false,
    errors
  })

export const updateCompanyRequest = (state: Object) =>
  state.merge({
    updatingCompany: true,
    errors: []  
  })

export const updateCompanySuccess = (state: Object, { company }: Object) => {
  let companies = state.companies
  
  companies = companies.map((w) => {
    if (w.id === company.id) {
      return company
    }

    return w
  })

  return state.merge({ 
    updatingCompany: false,
    isCompanyFormVisible: false,
    companies,
    company,
    editCompany: null
  })
}

export const updateCompanyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingCompany: false,
    errors
  })

export const destroyCompanyRequest = (state: Object) => 
  state.merge({ 
    destroyingCompany: true,
    errors: [] 
  })

export const destroyCompanySuccess = (state: Object, { company }: Object) =>
  state.merge({ 
    destroyingCompany: false,
    companies: state.companies.filter((w) => w.id !== company.id)
  })
  
export const destroyCompanyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingCompany: false, 
    errors 
  })

export const getCompanyRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getCompanySuccess = (state: Object, { company }: Object) => 
  state.merge({
    company,
    fetching: false
  })

export const getCompanyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const toggleCompanyForm = (state: Object, { isCompanyFormVisible, editCompany }: Object) =>
  state.merge({
    isCompanyFormVisible,
    editCompany,
    errors: []
  })

export const getBillingPlansRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getBillingPlansSuccess = (state: Object, { billingPlans }: Object) => 
  state.merge({
    billingPlans,
    fetching: false
  })

export const getBillingPlansFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const clearCompany = (state: Object) =>
  INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COMPANIES_REQUEST]: getCompaniesRequest,
  [Types.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [Types.GET_COMPANIES_FAILURE]: getCompaniesFailure,

  [Types.CREATE_COMPANY_REQUEST]: createCompanyRequest,
  [Types.CREATE_COMPANY_SUCCESS]: createCompanySuccess,
  [Types.CREATE_COMPANY_FAILURE]: createCompanyFailure,

  [Types.UPDATE_COMPANY_REQUEST]: updateCompanyRequest,
  [Types.UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
  [Types.UPDATE_COMPANY_FAILURE]: updateCompanyFailure,

  [Types.DESTROY_COMPANY_REQUEST]: destroyCompanyRequest,
  [Types.DESTROY_COMPANY_SUCCESS]: destroyCompanySuccess,
  [Types.DESTROY_COMPANY_FAILURE]: destroyCompanyFailure,

  [Types.GET_COMPANY_REQUEST]: getCompanyRequest,
  [Types.GET_COMPANY_SUCCESS]: getCompanySuccess,
  [Types.GET_COMPANY_FAILURE]: getCompanyFailure,

  [Types.GET_BILLING_PLANS_REQUEST]: getBillingPlansRequest,
  [Types.GET_BILLING_PLANS_SUCCESS]: getBillingPlansSuccess,
  [Types.GET_BILLING_PLANS_FAILURE]: getBillingPlansFailure,

  [Types.TOGGLE_COMPANY_FORM]: toggleCompanyForm,

  [Types.CLEAR_COMPANY]: clearCompany
})