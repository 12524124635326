import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Grid,
  Card,
  Sidebar,
  Segment,
  Button,
  Menu,
  Modal,
  Image,
  Icon,
  Item,
  Header,
  Dimmer,
  Loader,
  Dropdown,
  Divider,
  Container,
  Confirm,
  Popup
} from "semantic-ui-react";
import Styles from "../Styles/DashboardComponentStyles";
import { connect } from "react-redux";
import RoomActions from "../../redux/RoomRedux";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import { toast } from "react-toastify";
import RoomFormComponent from "./RoomFormComponent";

export default class RoomListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destroyLayerHidden: true,
      roomToDestroy: null
    };
  }

  componentDidMount() {
    this.props.getBranch(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.clearBranchForm();
  }

  renderRooms() {
    return this.props.room.rooms.map(r => {
      return (
        <Item key={r.id} style={Styles.listRow}>
          <Item.Content verticalAlign="middle">
            <Item.Meta style={Styles.textBold}>{r.name}</Item.Meta>
          </Item.Content>
          <Item>
            <Popup
              trigger={
                <Icon
                  name="television"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.previewRoom(r);
                  }}
                />
              }
              content="Previsualizar área"
              position="top center"
            />
            <Popup
              trigger={
                <Icon
                  name="edit"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.props.toggleRoomForm(true, r);
                  }}
                />
              }
              content="Editar área"
              position="top center"
            />
            <Popup
              trigger={
                <Icon
                  name="trash"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.setState({
                      destroyLayerHidden: false,
                      roomToDestroy: r
                    });
                  }}
                />
              }
              content="Eliminar área"
              position="top center"
            />
          </Item>
        </Item>
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.room.creatingRoom && !nextProps.room.creatingRoom) {
      if (nextProps.room.errors.length) {
        toast.error(nextProps.room.errors.join(", "));
      } else {
        this.props.updateCompanyScreensAvailable(-1);
        toast.success("Área creada correctamente");
      }
    }

    if (this.props.room.destroyingRoom && !nextProps.room.destroyingRoom) {
      if (nextProps.room.errors.length) {
        toast.error(nextProps.room.errors.join(", "));
      } else {
        this.props.updateCompanyScreensAvailable(1);
        toast.success("Área eliminada correctamente");
      }
    }

    if (!this.props.branch.branch && nextProps.branch.branch) {
      this.props.getRooms(nextProps.branch.branch.id);
    }
  }

  createRoom() {
    const room = {
      branch_id: this.props.branch.branch.id
    };

    this.props.createRoom(room);
  }

  destroyRoom() {
    this.props.destroyRoom(this.state.roomToDestroy);
    this.setState({ destroyLayerHidden: true, roomToDestroy: null });
  }

  previewRoom(r) {
    window.open("/receiver?roomCode=" + r.code);
  }

  render() {
    const branch = this.props.branch.branch || {};

    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon
                  name="chevron left"
                  style={Styles.cardHeaderIcon}
                  onClick={() => {
                    this.context.router.history.goBack();
                  }}
                />
                <Item style={Styles.cardHeaderTitle}>Editar áreas</Item>
                <Item />
              </Card.Header>

              <Dimmer
                inverted
                active={
                  this.props.room.fetching || this.props.room.destroyingRoom
                }
              >
                <Loader inverted />
              </Dimmer>

              <Item.Group>
                <Item style={Styles.textBold}>
                  {branch.name}: {branch.address}
                </Item>
              </Item.Group>

              <Container textAlign={"center"}>
                <Item style={Styles.textBold}>Crear áreas</Item>
                <Item style={Styles.segment}>
                  <Item style={Styles.text}>
                    Agregue las áreas necesarias en base a sus pantallas
                    disponibles
                  </Item>
                  <Item style={Styles.text}>
                    Pantallas disponibles:{" "}
                    {this.props.userAccount.user.company.screens_available}
                  </Item>
                </Item>
                <Button
                  loading={this.props.room.creatingRoom}
                  style={Styles.cardButton}
                  onClick={() => {
                    this.createRoom();
                  }}
                >
                  <Icon name="plus" style={Styles.cardIcon} /> Agregar área
                </Button>
              </Container>

              <Item.Group divided relaxed>
                {this.renderRooms()}
              </Item.Group>

              {this.state.roomToDestroy && (
                <Confirm
                  open={!this.state.destroyLayerHidden}
                  content={`Seguro desea eliminar ${this.state.roomToDestroy.name}?`}
                  cancelButton="Cancelar"
                  confirmButton="Aceptar"
                  onCancel={() => {
                    this.setState({
                      destroyLayerHidden: true,
                      roomToDestroy: null
                    });
                  }}
                  onConfirm={() => {
                    this.destroyRoom();
                  }}
                />
              )}

              <RoomFormComponent />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

RoomListComponent.contextTypes = {
  router: PropTypes.object
};
