import { connect } from 'react-redux'
import EventMessageListComponent from '../../components/Users/EventMessageListComponent'
import EventActions from '../../redux/EventRedux'

const mapStateToProps = (state) => {
  const { event } = state

  return {
    event
  }
}

const mapStateToDispatch = (dispatch) => ({
  getEvent: (eventId) => dispatch(EventActions.getEventRequest(eventId)),
  blockEventMessage: (eventMessage) => dispatch(EventActions.blockEventMessageRequest(eventMessage)),
  unblockEventMessage: (eventMessage) => dispatch(EventActions.unblockEventMessageRequest(eventMessage)),
  toggleBlacklistEventMessage: (eventMessage) => dispatch(EventActions.toggleBlacklistEventMessageRequest(eventMessage))
})

export default connect(mapStateToProps, mapStateToDispatch)(EventMessageListComponent)
