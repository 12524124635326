import { connect } from 'react-redux'
import AppComponent from '../../components/Admins/AppComponent'
import AdminAccountActions from '../../redux/AdminAccountRedux'

const mapStateToProps = (state) => {
  const { adminAccount } = state
  
  return {
    adminAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  loginVerify: (admin) => dispatch(AdminAccountActions.adminLoginVerify(admin)),
  logout: () => dispatch(AdminAccountActions.adminReset())
})

export default connect(mapStateToProps, mapStateToDispatch)(AppComponent)
