import { connect } from 'react-redux'
import RoomListComponent from '../../components/Shared/RoomListComponent'
import BranchActions from '../../redux/BranchRedux'
import RoomActions from '../../redux/RoomRedux'
import UserAccountActions from '../../redux/UserAccountRedux'

const mapStateToProps = (state) => {  
  const { branch, room, userAccount } = state
  
  return {
    branch,
    room,
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  toggleRoomForm: (isRoomFormVisible, room) => dispatch(RoomActions.toggleRoomForm(isRoomFormVisible, room)),
  clearBranchForm: () => dispatch(BranchActions.clearBranchForm()),
  getBranch: (branchId) => dispatch(BranchActions.getBranchRequest(branchId)),
  getRooms: (branchId) => dispatch(RoomActions.getRoomsRequest(branchId)),
  createRoom: (room) => dispatch(RoomActions.createRoomRequest(room)),
  updateRoom: (room) => dispatch(RoomActions.updateRoomRequest(room)),
  destroyRoom: (room) => dispatch(RoomActions.destroyRoomRequest(room)),
  updateCompanyScreensAvailable: (numberOfScreens) => dispatch(UserAccountActions.updateCompanyScreensAvailable(numberOfScreens))
})

export default connect(mapStateToProps, mapStateToDispatch)(RoomListComponent)