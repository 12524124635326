import { put, call } from 'redux-saga/effects'
import BranchActions from '../redux/BranchRedux'

export function * getBranches (api, action) {
  const resp = yield call(api.getBranches)

  if (resp.ok) {
    yield put(BranchActions.getBranchesSuccess(resp.data))
  } else {
    yield put(BranchActions.getBranchesFailure(resp.data))
  }
}

export function * getBranch (api, action) {
  const { branchId } = action

  const resp = yield call(api.getBranch, branchId)

  if (resp.ok) {
    yield put(BranchActions.getBranchSuccess(resp.data))
  } else {
    yield put(BranchActions.getBranchFailure(resp.data))
  }
}

export function * createBranch (api, action) {
  const { branch } = action

  const resp = yield call(api.createBranch, branch)

  if (resp.ok) {
    yield put(BranchActions.createBranchSuccess(resp.data))
  } else {
    yield put(BranchActions.createBranchFailure(resp.data))
  }
}

export function * updateBranch (api, action) {
  const { branch } = action

  const resp = yield call(api.updateBranch, branch)

  if (resp.ok) {
    yield put(BranchActions.updateBranchSuccess(resp.data))
  } else {
    yield put(BranchActions.updateBranchFailure(resp.data))
  }
}

export function * destroyBranch (api, action) {
  const { branch } = action

  const resp = yield call(api.destroyBranch, branch)

  if (resp.ok) {
    yield put(BranchActions.destroyBranchSuccess(resp.data))
  } else {
    yield put(BranchActions.destroyBranchFailure(resp.data))
  }
}