import { takeLatest, all } from "redux-saga/effects";
import API from "../services/Api";

/* ------------- Types ------------- */

import { UserAccountTypes } from "../redux/UserAccountRedux";
import { AdminAccountTypes } from "../redux/AdminAccountRedux";
import { CompanyTypes } from "../redux/CompanyRedux";
import { UserTypes } from "../redux/UserRedux";
import { BranchTypes } from "../redux/BranchRedux";
import { EventTypes } from "../redux/EventRedux";
import { CityTypes } from "../redux/CityRedux";
import { CountryTypes } from "../redux/CountryRedux";
import { RoomTypes } from "../redux/RoomRedux";
import { ReligionTypes } from "../redux/ReligionRedux";
import { PublicControllerTypes } from "../redux/PublicControllerRedux";
import { TypeOfBurialTypes } from "../redux/TypeOfBurialRedux";
import { BackgroundImageTypes } from "../redux/BackgroundImageRedux";
import { PromoTypes } from '../redux/PromoRedux';

/* ------------- Sagas ------------- */

import {
  userLoginRequest,
  userLoginVerify,
  userConfirm,
  userResetPasswordInstructions,
  userResetPassword,
  userLogout,
  userUpdateCompany
} from "./UserAccountSagas";
import {
  adminLoginRequest,
  adminLoginVerify,
  adminLogout,
  adminGetEventsToPay
} from "./AdminAccountSagas";
import {
  getCompanies,
  createCompany,
  updateCompany,
  destroyCompany,
  getBillingPlans,
  getCompany
} from "./CompanySagas";
import { getUsers, createUser, updateUser, destroyUser } from "./UserSagas";
import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  destroyBranch
} from "./BranchSagas";
import {
  getEvents,
  getEvent,
  createEvent,
  updateEvent,
  destroyEvent,
  blockEventMessage,
  unblockEventMessage,
  toggleBlacklistEventMessage,
  getEventsToPay
} from "./EventSagas";
import { getCities } from "./CitySagas";
import { getCountries } from "./CountrySagas";
import {
  getRooms,
  getRoom,
  createRoom,
  updateRoom,
  destroyRoom
} from "./RoomSagas";
import {
  getReligions,
  createReligion,
  updateReligion,
  destroyReligion
} from "./ReligionSagas";
import {
  getBranchById,
  activateScreen,
  findRoomDeviceByFingerprint,
  findEventByCode,
  findEventBySlug,
  createEventMessage,
  removeEventMessageWithToken
} from "./PublicControllerSagas";
import { getTypeOfBurials } from "./TypeOfBurialSagas";
import {
  getBackgroundImages,
  createBackgroundImage,
  updateBackgroundImage,
  destroyBackgroundImage
} from "./BackgroundImageSagas";
import {
  getPromos,
  getPromo,
  createPromo,
  updatePromo,
  destroyPromo,
  toggleActivePromo,
} from "./PromoSagas";
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(UserAccountTypes.USER_LOGIN_REQUEST, userLoginRequest, api),
    takeLatest(UserAccountTypes.USER_LOGIN_VERIFY, userLoginVerify, api),
    takeLatest(UserAccountTypes.USER_CONFIRM_REQUEST, userConfirm, api),
    takeLatest(UserAccountTypes.USER_RESET, userLogout, api),
    takeLatest(
      UserAccountTypes.USER_RESET_PASSWORD_INSTRUCTIONS_REQUEST,
      userResetPasswordInstructions,
      api
    ),
    takeLatest(
      UserAccountTypes.USER_RESET_PASSWORD_REQUEST,
      userResetPassword,
      api
    ),
    takeLatest(
      UserAccountTypes.USER_UPDATE_COMPANY_REQUEST,
      userUpdateCompany,
      api
    ),

    takeLatest(AdminAccountTypes.ADMIN_LOGIN_REQUEST, adminLoginRequest, api),
    takeLatest(AdminAccountTypes.ADMIN_LOGIN_VERIFY, adminLoginVerify, api),
    takeLatest(AdminAccountTypes.ADMIN_RESET, adminLogout, api),
    takeLatest(
      AdminAccountTypes.ADMIN_GET_EVENTS_TO_PAY_REQUEST,
      adminGetEventsToPay,
      api
    ),

    takeLatest(CompanyTypes.GET_COMPANIES_REQUEST, getCompanies, api),
    takeLatest(CompanyTypes.CREATE_COMPANY_REQUEST, createCompany, api),
    takeLatest(CompanyTypes.UPDATE_COMPANY_REQUEST, updateCompany, api),
    takeLatest(CompanyTypes.DESTROY_COMPANY_REQUEST, destroyCompany, api),
    takeLatest(CompanyTypes.GET_BILLING_PLANS_REQUEST, getBillingPlans, api),
    takeLatest(CompanyTypes.GET_COMPANY_REQUEST, getCompany, api),

    takeLatest(UserTypes.GET_USERS_REQUEST, getUsers, api),
    takeLatest(UserTypes.CREATE_USER_REQUEST, createUser, api),
    takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUser, api),
    takeLatest(UserTypes.DESTROY_USER_REQUEST, destroyUser, api),

    takeLatest(BranchTypes.GET_BRANCHES_REQUEST, getBranches, api),
    takeLatest(BranchTypes.GET_BRANCH_REQUEST, getBranch, api),
    takeLatest(BranchTypes.CREATE_BRANCH_REQUEST, createBranch, api),
    takeLatest(BranchTypes.UPDATE_BRANCH_REQUEST, updateBranch, api),
    takeLatest(BranchTypes.DESTROY_BRANCH_REQUEST, destroyBranch, api),

    takeLatest(RoomTypes.GET_ROOMS_REQUEST, getRooms, api),
    takeLatest(RoomTypes.GET_ROOM_REQUEST, getRoom, api),
    takeLatest(RoomTypes.CREATE_ROOM_REQUEST, createRoom, api),
    takeLatest(RoomTypes.UPDATE_ROOM_REQUEST, updateRoom, api),
    takeLatest(RoomTypes.DESTROY_ROOM_REQUEST, destroyRoom, api),

    takeLatest(ReligionTypes.GET_RELIGIONS_REQUEST, getReligions, api),
    takeLatest(ReligionTypes.CREATE_RELIGION_REQUEST, createReligion, api),
    takeLatest(ReligionTypes.UPDATE_RELIGION_REQUEST, updateReligion, api),
    takeLatest(ReligionTypes.DESTROY_RELIGION_REQUEST, destroyReligion, api),

    takeLatest(CityTypes.GET_CITIES_REQUEST, getCities, api),

    takeLatest(CountryTypes.GET_COUNTRIES_REQUEST, getCountries, api),

    takeLatest(
      TypeOfBurialTypes.GET_TYPE_OF_BURIALS_REQUEST,
      getTypeOfBurials,
      api
    ),

    takeLatest(
      BackgroundImageTypes.GET_BACKGROUND_IMAGES_REQUEST,
      getBackgroundImages,
      api
    ),
    takeLatest(
      BackgroundImageTypes.CREATE_BACKGROUND_IMAGE_REQUEST,
      createBackgroundImage,
      api
    ),
    takeLatest(
      BackgroundImageTypes.UPDATE_BACKGROUND_IMAGE_REQUEST,
      updateBackgroundImage,
      api
    ),
    takeLatest(
      BackgroundImageTypes.DESTROY_BACKGROUND_IMAGE_REQUEST,
      destroyBackgroundImage,
      api
    ),

    takeLatest(EventTypes.GET_EVENTS_REQUEST, getEvents, api),
    takeLatest(EventTypes.GET_EVENT_REQUEST, getEvent, api),
    takeLatest(EventTypes.CREATE_EVENT_REQUEST, createEvent, api),
    takeLatest(EventTypes.UPDATE_EVENT_REQUEST, updateEvent, api),
    takeLatest(EventTypes.DESTROY_EVENT_REQUEST, destroyEvent, api),
    takeLatest(
      EventTypes.BLOCK_EVENT_MESSAGE_REQUEST,
      blockEventMessage,
      api
    ),
    takeLatest(
      EventTypes.UNBLOCK_EVENT_MESSAGE_REQUEST,
      unblockEventMessage,
      api
    ),
    takeLatest(
      EventTypes.TOGGLE_BLACKLIST_EVENT_MESSAGE_REQUEST,
      toggleBlacklistEventMessage,
      api
    ),
    takeLatest(
      EventTypes.GET_EVENTS_TO_PAY_REQUEST,
      getEventsToPay,
      api
    ),

    takeLatest(
      PublicControllerTypes.GET_BRANCH_BY_ID_REQUEST,
      getBranchById,
      api
    ),
    takeLatest(
      PublicControllerTypes.ACTIVATE_SCREEN_REQUEST,
      activateScreen,
      api
    ),
    takeLatest(
      PublicControllerTypes.FIND_ROOM_DEVICE_BY_FINGERPRINT_REQUEST,
      findRoomDeviceByFingerprint,
      api
    ),
    takeLatest(
      PublicControllerTypes.FIND_EVENT_BY_CODE_REQUEST,
      findEventByCode,
      api
    ),
    takeLatest(
      PublicControllerTypes.CREATE_EVENT_MESSAGE_REQUEST,
      createEventMessage,
      api
    ),
    takeLatest(
      PublicControllerTypes.FIND_EVENT_BY_SLUG_REQUEST,
      findEventBySlug,
      api
    ),
    takeLatest(
      PublicControllerTypes.REMOVE_EVENT_MESSAGE_WITH_TOKEN_REQUEST,
      removeEventMessageWithToken,
      api
    ),

    takeLatest(PromoTypes.GET_PROMOS_REQUEST, getPromos, api),
    takeLatest(PromoTypes.GET_PROMO_REQUEST, getPromo, api),
    takeLatest(PromoTypes.CREATE_PROMO_REQUEST, createPromo, api),
    takeLatest(PromoTypes.UPDATE_PROMO_REQUEST, updatePromo, api),
    takeLatest(PromoTypes.DESTROY_PROMO_REQUEST, destroyPromo, api),
    takeLatest(PromoTypes.TOGGLE_ACTIVE_PROMO_REQUEST, toggleActivePromo, api),
  ]);
}
