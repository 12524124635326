import { connect } from 'react-redux'
import ConfirmationComponent from '../../components/Users/ConfirmationComponent'
import UserAccountActions from '../../redux/UserAccountRedux'

const mapStateToProps = (state) => {
  const { userAccount } = state
  
  return {
    userAccount
  }
}

const mapStateToDispatch = (dispatch) => ({
  confirmAccount: (confirmationToken) => dispatch(UserAccountActions.userConfirmRequest(confirmationToken))
})

export default connect(mapStateToProps, mapStateToDispatch)(ConfirmationComponent)
