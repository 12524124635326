import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Dimmer,
  Loader
} from "semantic-ui-react";
import ErrorMessagesComponent from "../Shared/ErrorMessagesComponent";
import queryString from "query-string";
import { Images } from "../../themes";
import Styles from "../Styles/LoginComponentStyles";

export default class ConfirmationComponent extends Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.props.confirmAccount(params.confirmation_token);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.userAccount.fetching && !newProps.userAccount.fetching) {
      if (newProps.userAccount.user) {
        this.context.router.history.push("/");
        return;
      }
    }
  }

  render() {
    if (this.props.userAccount.errors.length) {
      return (
        <Grid
          textAlign="center"
          style={Styles.loginFormUsers}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form
              size="large"
              loading={this.props.userAccount.fetching}
              autoComplete="off"
            >
              <Segment stacked>
                <Header>
                  <Image src={Images.logo} style={Styles.loginLogo} />
                </Header>

                <ErrorMessagesComponent
                  messages={this.props.userAccount.errors[0].email}
                />

                <Button
                  fluid
                  size="large"
                  style={Styles.loginButton}
                  primary
                  onClick={() => {
                    this.context.router.history.push("/");
                  }}
                >
                  Ingresar
                </Button>
              </Segment>
            </Form>
            <Message style={Styles.loginSegment}>
              <Image centered src={Images.logo} style={Styles.loginLogo} />
            </Message>
          </Grid.Column>
        </Grid>
      );
    }

    return (
      <Dimmer inverted active={this.props.userAccount.fetching}>
        <Loader inverted>Confirmando cuenta</Loader>
      </Dimmer>
    );
  }
}

ConfirmationComponent.contextTypes = {
  router: PropTypes.object
};
