import { put, call } from 'redux-saga/effects'
import CountryActions from '../redux/CountryRedux'

export function * getCountries (api, action) {

  const resp = yield call(api.getCountries)

  if (resp.ok) {
    yield put(CountryActions.getCountriesSuccess(resp.data))
  } else {
    yield put(CountryActions.getCountriesFailure(resp.data))
  }
}
