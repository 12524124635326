import React, { Component } from 'react'

export default class ErrorMessagesComponent extends Component {

  render() {
    const errors = this.props.messages.map((message, i) => {
      return (
        <div key={i}><strong>{message}</strong></div>
      )
    })

    if (!errors.length) {
      return null
    }

    return (
      <div className="errors">
        <div>
          {errors}
        </div>
      </div>
    )
  }
}