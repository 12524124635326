import React, { Component } from "react";
import {
  Form,
  Grid,
  Segment,
  Icon,
  Button,
  Image,
  Item,
  Header,
  Confirm,
  Checkbox
} from "semantic-ui-react";
import Styles from "../Styles/LeaveMessageComponentStyles";
import { Images } from "../../themes";
import ErrorMessagesComponent from "./ErrorMessagesComponent";
import storage from "redux-persist/lib/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import queryString from "query-string";
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleLogin from "react-apple-login";
import { GoogleLogin, googleLogout, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

export default class LeaveMessageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: null,
      message: {
        name: "",
        email: "",
        video: null,
      },
      event: null,
      imagePreviewUrl: null,
      videoPreviewUrl: null,
      confirmOpen: false,
      acceptTerms: true,
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    if (params.eventCode) {
      this.setState({ code: params.eventCode }, () => {
        this.props.findEventByCode(params.eventCode);
      });
    }

    if (params.claims) {
      const data = JSON.parse(atob(params.claims));

      this.props.setUser({
        name: data.email,
        email: data.email,
        provider_picture_url:
          "https://www.kindpng.com/picc/m/105-1055656_account-user-profile-avatar-avatar-user-profile-icon.png",
        provider_network: "apple"
      });
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (
      !this.props.publicController.event &&
      nextProps.publicController.event
    ) {
      this.setState({ event: nextProps.publicController.event });

      let deviceId = await storage.getItem("device_id");

      if (!deviceId) {
        deviceId = nextProps.publicController.event.device_id;
        storage.setItem("device_id", deviceId);
      }

      this.setState({
        message: { ...this.state.message, device_id: deviceId }
      });
    }

    if (
      this.props.publicController.creatingMessage &&
      !nextProps.publicController.creatingMessage
    ) {
      if (nextProps.publicController.errors.length === 0) {
        toast.success("Mensaje enviado correctamente");

        this.setState({
          message: {
            ...this.state.message,
            content: "",
            image: null,
            video: null,
          },
          imagePreviewUrl: null,
          videoPreviewUrl: null,
        });

        document.querySelector('input[type="file"]').value = null;
      }
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      message: { ...this.state.message, [name]: value }
    });
  }

  handleMediaChange(e) {
    e.preventDefault();

    let file = e.target.files[0];
    
    if(!file) {
      return;
    }

    if (file.type.startsWith('image/')) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          message: { ...this.state.message, image: file, video: null },
          imagePreviewUrl: reader.result,
          videoPreviewUrl: null,
        });
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video/')) {
      // Create video element to check duration
      const video = document.createElement('video');
      video.preload = 'metadata';
      
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        if (video.duration > 60) {
          toast.error('El video no puede durar más de 60 segundos');
          document.querySelector('input[type="file"]').value = null;
          return;
        }
        
        this.setState({
          message: { ...this.state.message, image: null, video: file },
          imagePreviewUrl: null,
          videoPreviewUrl: URL.createObjectURL(file),
        });
      };
      
      video.src = URL.createObjectURL(file);
    }
  }

  removeMedia() {
    this.setState({
      message: { ...this.state.message, image: null, video: null },
      imagePreviewUrl: null,
      videoPreviewUrl: null,
    });
    document.querySelector('input[type="file"]').value = null;
  }

  facebookProfileResponse(facebookLogin) {
    this.setState({
      confirmOpen: false
    });

    if (!facebookLogin.email) {
      return;
    }

    this.props.setUser({
      name: facebookLogin.name,
      email: facebookLogin.email,
      provider_picture_url: facebookLogin.picture.data.url,
      provider_network: "facebook"
    });
  }

  googleResponse(googleLogin) {
    this.setState({
      confirmOpen: false
    });

    if (!googleLogin.credential) {
      return;
    }

    const userObject = jwtDecode(googleLogin.credential);

    this.props.setUser({
      name: userObject.name,
      email: userObject.email,
      provider_picture_url: userObject.picture,
      provider_network: "google"
    });
  }

  render() {
    const event = this.props.publicController.event;
    const user = this.props.publicController.user;

    if (event) {
      return (
        <Grid
          textAlign="center"
          style={Styles.content}
          verticalAlign="middle"
          className="public"
        >
          <Grid.Column style={{ maxWidth: 400 }}>
            {user && user.email ? (
              <Form size="large" autoComplete="off">
                <Segment style={Styles.card}>
                  <div style={Styles.logosContainer}>
                    {event.company && (
                      <Image
                        src={Images.remote(event.company.logo_url)}
                        style={Styles.logos}
                      />
                    )}
                    <Image src={Images.logo} style={Styles.logos} />
                  </div>
                  <Form autoComplete="off">
                    <ErrorMessagesComponent
                      messages={this.props.publicController.errors}
                    />

                    <Item style={Styles.message}>
                      <Item style={Styles.messageTitle}>
                        <Item>Genial!</Item>
                      </Item>
                      <Item style={Styles.messageText}>
                        Has ingresado a la pantalla de {event.event_name}. Deja
                        tu mensaje y/o foto.
                      </Item>
                    </Item>

                    <div>
                      <div
                        className="public-label"
                        style={{ textAlign: "center" }}
                      >
                        {user.name}
                      </div>

                      <Image
                        src={user.provider_picture_url}
                        size="tiny"
                        circular
                        centered
                      />
                    </div>

                    {/* {!event.image_only && (
                      <div style={{ marginBottom: 10 }}>
                        <div className="public-label">Mensaje</div>
                        <Form.TextArea
                          placeholder="(Opcional)"
                          name="content"
                          value={this.state.message.content}
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    )} */}

                    <Form.Field style={{ textAlign: "center" }}>
                      <label className="fileContainer">
                        <Icon name="camera" style={Styles.modalIcon} />
                        Tomar foto
                        <input
                          type="file"
                          onChange={e => this.handleMediaChange(e)}
                          accept="image/*"
                          capture="environment"
                        />
                      </label>
                      <label className="fileContainer">
                        <Icon name="image" style={Styles.modalIcon} />
                        Seleccionar de galería
                        <input
                          type="file"
                          onChange={e => this.handleMediaChange(e)}
                          accept="image/*,video/*"
                        />
                      </label>
                    </Form.Field>

                    {(this.state.imagePreviewUrl || this.state.videoPreviewUrl) && (
                      <div className="media-preview">
                        <Button
                          className="right floated"
                          content="Eliminar medio"
                          icon="trash"
                          labelPosition="left"
                          onClick={() => {
                            this.removeMedia();
                          }}
                        />
                        {this.state.imagePreviewUrl && (
                          <Image
                            src={this.state.imagePreviewUrl}
                            size="medium"
                            centered
                          />
                        )}
                        {this.state.videoPreviewUrl && (
                          <video
                            src={this.state.videoPreviewUrl}
                            style={{ maxWidth: '100%', maxHeight: '300px' }}
                            controls
                          />
                        )}
                      </div>
                    )}

                    <Button
                      loading={this.props.publicController.creatingMessage}
                      fluid
                      style={Styles.cardButton}
                      primary
                      onClick={() => {
                        this.props.createEventMessage(event.code, {
                          ...this.state.message,
                          ...user
                        });
                      }}
                    >
                      Enviar
                    </Button>

                    <Button
                      onClick={() => this.setState({ confirmOpen: true })}
                      style={Styles.textButton}
                    >
                      Volver al inicio
                    </Button>
                    <Confirm
                      size="small"
                      open={this.state.confirmOpen}
                      onCancel={() => this.setState({ confirmOpen: false })}
                      onConfirm={() => {
                        this.props.clearUser();
                        this.setState({ confirmOpen: false });
                      }}
                      cancelButton={
                        <Button style={Styles.cancelButton}>Cancelar</Button>
                      }
                      confirmButton={
                        <Button primary style={Styles.cardButton}>
                          Si
                        </Button>
                      }
                      content="¿Seguro desea desconectar su cuenta?"
                    />
                  </Form>
                </Segment>
              </Form>
            ) : (
              <Form size="large" autoComplete="off">
                <Segment style={Styles.card}>
                  <div style={Styles.logosContainer}>
                    {event.company && (
                      <Image
                        src={Images.remote(event.company.logo_url)}
                        style={Styles.logos}
                      />
                    )}
                    <Image src={Images.logo} style={Styles.logos} />
                  </div>
                  <Item style={{ ...Styles.message, textAlign: 'center' }}>
                    <Item style={Styles.messageText}>
                      {event.event_name}
                    </Item>
                  </Item>
                  <Item style={{ ...Styles.message, textAlign: 'center' }}>
                    <Item style={{...Styles.messageText, fontSize: 16 }}>
                      Ingresa con alguna de tus redes sociales
                    </Item>
                  </Item>
                  <Form autoComplete="off" style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                     <div style={{ width: '100%' }}>
                      <GoogleOAuthProvider clientId="944033839846-ibgphfash75ggejtfgqb92kbdcfv9ju3.apps.googleusercontent.com">
                        <GoogleLogin
                        onSuccess={this.googleResponse.bind(this)}
                        onError={this.googleResponse.bind(this)}
                        shape="circle"
                        useOneTap
                        auto_select
                        />
                      </GoogleOAuthProvider>
                    </div>
                    <AppleLogin
                      clientId="com.codingways.nitpoint.web"
                      redirectURI="https://api.nitpoint.com/apple_auth"
                      state={this.state.code}
                      scope="name email"
                      responseMode="form_post"
                      render={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          fluid
                          style={Styles.cardButtonApple}
                          primary
                        >
                          <Icon name="apple" style={Styles.buttonIcon} />
                          Inicia sesión con Apple
                        </Button>
                      )}
                    />
                    <FacebookLogin
                      appId="2403634373167290"
                      fields="name,email,picture.width(200)"
                      onFail={(error) => {
                        console.log('Login Failed!', error);
                      }}
                      onProfileSuccess={(response) => {this.facebookProfileResponse(response)}}
                      render={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          fluid
                          style={Styles.cardButtonFacebook}
                          primary
                        >
                          <Icon name="facebook" style={Styles.buttonIcon} />
                          Inicia sesión con Facebook
                        </Button>
                      )}
                    />
                  </Form>
                  <Button
                    style={Styles.textButton}
                    onClick={() => {
                      googleLogout();
                      this.props.clearEvent();
                    }}
                  >
                    Volver al inicio
                  </Button>
                </Segment>
              </Form>
            )}

            <ToastContainer
              position={toast.POSITION.BOTTOM_RIGHT}
              closeButton={false}
            />
          </Grid.Column>
        </Grid>
      );
    }

    return (
      <Grid
        textAlign="center"
        style={Styles.content}
        verticalAlign="middle"
        className="public"
      >
        <Grid.Column style={{ maxWidth: 400 }}>
          <Form size="large" autoComplete="off">
            <Segment style={Styles.card}>
              <Header>
                <Image src={Images.logo} style={Styles.logo} />
              </Header>
              <Item style={Styles.message}>
                <Item style={Styles.messageTitle}>
                  <Item>Bienvenido a Nitpoint!</Item>
                </Item>
                <Item style={Styles.messageText}>
                  Ingresa el código en pantalla para dejar tu mensaje
                </Item>
              </Item>
              <Form autoComplete="off">
                <ErrorMessagesComponent
                  messages={this.props.publicController.errors}
                />

                <div className="public-label">Código</div>
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="right"
                  placeholder="Código"
                  name="code"
                  value={this.state.code}
                  onChange={(e, data) => {
                    this.setState({ code: data.value });
                  }}
                />

                <Button
                  loading={this.props.publicController.fetching}
                  fluid
                  style={Styles.cardButton}
                  primary
                  onClick={() => {
                    this.props.findEventByCode(this.state.code);
                  }}
                >
                  Ingresar
                </Button>
              </Form>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}