import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Sidebar, Segment, Button, Menu, Image, Icon, Item, Header, Divider, Container, Dimmer, Loader, Popup } from 'semantic-ui-react'
import Styles from '../Styles/DashboardComponentStyles'
import CompanyFormComponent from './CompanyFormComponent'
import UserListComponent from '../Shared/UserListComponent'

export default class CompanyComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      company: {}
    }
  }

  componentDidMount() {
    this.props.getCompany(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearCompany()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.company.fetching && !nextProps.company.fetching) {
      if (nextProps.company.company){
        this.props.getUsers()
      }
    }
  }

  render() {
    if (!this.props.company.company) {
      return (
        <Container>
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        </Container>
      )
    }

    const company = this.props.company.company

    return (
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeader}>
                <Icon name='chevron left' style={Styles.cardHeaderIcon} onClick={() => { this.context.router.history.goBack() }} />
                {company.name}
                <Popup trigger={<Icon name='pencil' style={Styles.cardHeaderIcon} onClick={() => { this.props.toggleCompanyForm(true, company) }} />} content='Editar Companía' position='top center' />
              </Card.Header>

              <Divider />

              <Item.Group>
                <Item style={Styles.textBold}>Cantidad de pantallas: {company.number_of_screens}</Item>
                <Item style={Styles.textBold}>Plan: {company.billing_plan && company.billing_plan.title}</Item>
              </Item.Group>

            </Card.Content>
          </Card>

          <Dimmer inverted active={this.props.user.fetching}>
            <Loader inverted />
          </Dimmer>

          <UserListComponent users={this.props.user.users} />
          <CompanyFormComponent />
          <Card fluid>
            <Card.Content style={Styles.cardContent}>
              <Card.Header style={Styles.cardHeaderList}>
                <Icon name='chevron left' style={Styles.cardHeaderIcon} onClick={() => { this.context.router.history.goBack() }} />
                <Item style={Styles.cardHeaderTitle}>Reportes</Item>
                <Item />
              </Card.Header>

              <Item.Group divided relaxed>
                <Item as='a' onClick={ () => { this.context.router.history.push(`/admins/companies/${company.id}/reports/events`) }}>
                  <Item.Content>
                    <Item.Header style={Styles.textBold}>
                      Eventos contabilizados
                    </Item.Header>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    )
  }
}

CompanyComponent.contextTypes = {
  router: PropTypes.object
}
