import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getReligionsRequest: [],
  getReligionsSuccess: ['religions'],
  getReligionsFailure: ['errors'],

  getReligionRequest: ['religionId'],
  getReligionSuccess: ['religion'],
  getReligionFailure: ['errors'],

  createReligionRequest: ['religion'],
  createReligionSuccess: ['religion'],
  createReligionFailure: ['errors'],

  updateReligionRequest: ['religion'],
  updateReligionSuccess: ['religion'],
  updateReligionFailure: ['errors'],

  destroyReligionRequest: ['religion'],
  destroyReligionSuccess: ['religion'],
  destroyReligionFailure: ['errors'],

  clearReligionForm: [],
  toggleReligionForm: ['isReligionFormVisible', 'editReligion']
})

export const ReligionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  religion: null,
  errors: [],
  creatingReligion: false,
  fetching: false,
  religions: [],
  updatingReligion: false,
  isReligionFormVisible: false,
  editReligion: null,
  destroyingReligion: false
})

/* ------------- Reducers ------------- */

export const getReligionsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getReligionsSuccess = (state: Object, { religions }: Object) => 
  state.merge({
    religions,
    fetching: false
  })

export const getReligionsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getReligionRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getReligionSuccess = (state: Object, { religion }: Object) => 
  state.merge({
    religion,
    fetching: false
  })

export const getReligionFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createReligionRequest = (state: Object) =>
  state.merge({
    creatingReligion: true,
    errors: []  
  })

export const createReligionSuccess = (state: Object, { religion }: Object) => 
  state.merge({
    religions: [...state.religions, religion],
    creatingReligion: false,
    isReligionFormVisible: false,
    editReligion: null
  })

export const createReligionFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingReligion: false,
    errors
  })

export const updateReligionRequest = (state: Object) =>
  state.merge({
    updatingReligion: true,
    errors: []  
  })

export const updateReligionSuccess = (state: Object, { religion }: Object) => {
  let religions = state.religions
  
  religions = religions.map((w) => {
    if (w.id === religion.id) {
      return religion
    }

    return w
  })

  return state.merge({ 
    updatingReligion: false,
    isReligionFormVisible: false,
    religions,
    editReligion: null
  })
}

export const updateReligionFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingReligion: false,
    errors
  })

export const destroyReligionRequest = (state: Object) => 
  state.merge({ 
    destroyingReligion: true,
    errors: [] 
  })

export const destroyReligionSuccess = (state: Object, { religion }: Object) =>
  state.merge({ 
    destroyingReligion: false,
    religions: state.religions.filter((w) => w.id !== religion.id)
  })
  
export const destroyReligionFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingReligion: false, 
    errors 
  })

export const clearReligionForm = (state: Object ) =>
  state.merge({
    religion: null
  })

export const toggleReligionForm = (state: Object, { isReligionFormVisible, editReligion }: Object) =>
  state.merge({
    isReligionFormVisible,
    editReligion,
    errors: []
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RELIGIONS_REQUEST]: getReligionsRequest,
  [Types.GET_RELIGIONS_SUCCESS]: getReligionsSuccess,
  [Types.GET_RELIGIONS_FAILURE]: getReligionsFailure,

  [Types.GET_RELIGION_REQUEST]: getReligionRequest,
  [Types.GET_RELIGION_SUCCESS]: getReligionSuccess,
  [Types.GET_RELIGION_FAILURE]: getReligionFailure,

  [Types.CREATE_RELIGION_REQUEST]: createReligionRequest,
  [Types.CREATE_RELIGION_SUCCESS]: createReligionSuccess,
  [Types.CREATE_RELIGION_FAILURE]: createReligionFailure,

  [Types.UPDATE_RELIGION_REQUEST]: updateReligionRequest,
  [Types.UPDATE_RELIGION_SUCCESS]: updateReligionSuccess,
  [Types.UPDATE_RELIGION_FAILURE]: updateReligionFailure,

  [Types.DESTROY_RELIGION_REQUEST]: destroyReligionRequest,
  [Types.DESTROY_RELIGION_SUCCESS]: destroyReligionSuccess,
  [Types.DESTROY_RELIGION_FAILURE]: destroyReligionFailure,

  [Types.CLEAR_RELIGION_FORM]: clearReligionForm,
  [Types.TOGGLE_RELIGION_FORM]: toggleReligionForm
})