import { connect } from 'react-redux'
import DashboardComponent from '../../components/Users/DashboardComponent'
import BranchActions from '../../redux/BranchRedux'
import EventActions from '../../redux/EventRedux'
import PromoActions from '../../redux/PromoRedux'

const mapStateToProps = (state) => {
  const { branch, promo } = state

  return {
    branch,
    promo,
  }
}

const mapStateToDispatch = (dispatch) => ({
  getBranches: () => dispatch(BranchActions.getBranchesRequest()),
  toggleEventForm: (isFormVisible, event) => dispatch(EventActions.toggleEventForm(isFormVisible, event)),
  toggleBranchForm: (isFormVisible, branch) => dispatch(BranchActions.toggleBranchForm(isFormVisible, branch)),
  getPromos: (q) => dispatch(PromoActions.getPromosRequest(q)),
})

export default connect(mapStateToProps, mapStateToDispatch)(DashboardComponent)