import React, { Component } from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import moment from "moment";
import { Images } from "../../themes";

export default class BranchScreenComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderRooms(branch) {
    if (!branch.rooms.length) {
      return "No hay áreas creadas";
    }

    const rooms = [...branch.rooms]
      .filter(r => r.current_event || r.next_event)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    return rooms.map(r => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "10vh",
            width: "45%",
            "@media screen and (max-width: 1200px)": {
              width: "90%"
            },
            backgroundColor: "#fff",
            padding: ".5vh 1.5vh",
            marginBottom: "2.5vh",
            borderRadius: "10px",
            boxShadow:
              "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
          }}
        >
          {r.current_event && (
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              {r.current_event.image_url && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "7.5vh",
                    minWidth: "7.5vh",
                    backgroundColor: "#3081d2",
                    borderRadius: "50%",
                    marginRight: "1vh",
                    boxShadow:
                      "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
                  }}
                >
                  <Image
                    style={{
                      height: "7vh",
                      width: "7vh",
                      borderRadius: "50%"
                    }}
                    src={Images.remote(r.current_event.image_url)}
                  />
                </div>
              )}
              <div
                style={{ fontSize: "3vh", color: "#494949", fontWeight: "600" }}
              >
                {`${r.current_event.event_name}`}
              </div>
            </div>
          )}

          {!r.current_event && r.next_event && (
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              {r.next_event.image_url && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "7.5vh",
                    minWidth: "7.5vh",
                    backgroundColor: "#3081d2",
                    borderRadius: "50%",
                    marginRight: "1vh",
                    boxShadow:
                      "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
                  }}
                >
                  <Image
                    style={{
                      height: "7vh",
                      width: "7vh",
                      borderRadius: "50%"
                    }}
                    src={Images.remote(r.next_event.image_url)}
                  />
                </div>
              )}
              <div
                style={{
                  lineHeight: 1,
                  fontSize: "3vh",
                  color: "#494949",
                  fontWeight: "600"
                }}
              >
                {`${r.next_event.event_name}`}
                <br />
                <span
                  style={{
                    fontSize: "1.5vh",
                    fontWeight: "400",
                    textTransform: "capitalize"
                  }}
                >{`(${moment(r.next_event.starts_at).fromNow()})`}</span>
              </div>
            </div>
          )}

          {!r.current_event && !r.next_event && <div />}

          <div style={{ fontSize: "3vh", color: "#3081d2" }}>{r.name}</div>
        </div>
      );
    });
  }

  render() {
    const { branch } = this.props;

    return (
      <div
        style={{
          height: "100vh",
          backgroundColor: "#e9e9e9"
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            height: "15vh",
            backgroundColor: "#3081d2"
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "-4vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "15vh",
              width: "50%",
              "@media screen and (max-width: 1200px)": {
                width: "95%"
              },
              backgroundColor: "#fff",
              padding: "1vh 2vh",
              borderRadius: "10px",
              boxShadow:
                "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "12vh",
                minWidth: "12vh",
                borderRadius: "50%",
                marginRight: "1.5vh",
                boxShadow:
                  "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)"
              }}
            >
              <Image
                style={{
                  height: "12vh",
                  width: "12vh",
                  borderRadius: "50%"
                }}
                src={Images.remote(branch.company_logo_url)}
              />
            </div>
            <div
              style={{
                lineHeight: "1",
                fontSize: "3.5vh",
                color: "#494949"
              }}
            >
              Bienvenido a <br />
              <b>{branch.name}</b>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "75vh",
            paddingTop: "9vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "wrap"
          }}
        >
          {this.renderRooms(branch)}
        </div>
        <div
          style={{
            height: "10vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            borderBottom: "1vh solid #3081d2"
          }}
        >
          <Image
            style={{
              height: "5vh"
            }}
            src={Images.logo}
          />
          <div
            style={{
              height: "5vh",
              width: "2px",
              margin: "0 25px",
              backgroundColor: "#3081d2"
            }}
          />
          <div
            style={{
              fontSize: "2vh",
              color: "#3081d2"
            }}
          >
            www.requiem.gropup
          </div>
        </div>
      </div>
    );
  }
}

BranchScreenComponent.contextTypes = {
  router: PropTypes.object
};
