import { connect } from 'react-redux'
import PromoComponent from '../../components/Shared/PromoComponent'
import PromoActions from '../../redux/PromoRedux'
import BranchActions from '../../redux/BranchRedux';

const mapStateToProps = (state) => {  
  const { promo, userAccount, branch } = state
  
  return {
    promo,
    userAccount,
    branch,
  }
}

const mapStateToDispatch = (dispatch) => ({
  getPromo: (promoId) => dispatch(PromoActions.getPromoRequest(promoId)),
  createPromo: (promo) => dispatch(PromoActions.createPromoRequest(promo)),
  updatePromo: (promo) => dispatch(PromoActions.updatePromoRequest(promo)),
  clearPromoForm: () => dispatch(PromoActions.clearPromo()),
  destroyPromo: (promo) => dispatch(PromoActions.destroyPromoRequest(promo)),
  getBranches: () => dispatch(BranchActions.getBranchesRequest()),
})

export default connect(mapStateToProps, mapStateToDispatch)(PromoComponent)