const Colors = {
  background: "#f7f7f7",
  primary: "#f5275f",
  secondary: "#250065",
  blue: "#618bbe",
  red: "#f44336",
  chalk: "#f3f1f1",
  lightgray: "rgba(34,36,38,0.15)",
  gray: "#3b3b3b",
  bg: "#250065",
  facebook: "#3b5998",
  charcoal: "#595959",
  black: "#000",
  snow: "#FFF",
  transparent: "rgba(0,0,0,0)"
};

export default Colors;
