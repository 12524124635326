import { connect } from 'react-redux'
import DashboardComponent from '../../components/Admins/DashboardComponent'
import CompanyActions from '../../redux/CompanyRedux'

const mapStateToProps = (state) => {  
  const { company } = state
  
  return {
    company
  }
}

const mapStateToDispatch = (dispatch) => ({
  getCompanies: () => dispatch(CompanyActions.getCompaniesRequest()),
  toggleCompanyForm: (isFormVisible, company) => dispatch(CompanyActions.toggleCompanyForm(isFormVisible, company))
})

export default connect(mapStateToProps, mapStateToDispatch)(DashboardComponent)