/* eslint-disable max-len */

import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import R from "ramda";
import Config from "../config/DebugSettings";
import AppConfig from "../config/AppConfig";

export default (rootReducer, rootSaga) => {
  const middleware = [];
  const enhancers = [];

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  const SAGA_LOGGING_BLACKLIST = [
    "EFFECT_TRIGGERED",
    "EFFECT_RESOLVED",
    "EFFECT_REJECTED"
  ];

  if (AppConfig.DEV) {
    const USE_LOGGING = Config.reduxLogging;

    const logger = createLogger({
      predicate: (getState, { type }) =>
        USE_LOGGING && R.not(R.contains(type, SAGA_LOGGING_BLACKLIST))
    });

    middleware.push(logger);
  }

  enhancers.push(applyMiddleware(...middleware));

  const store = createStore(rootReducer, compose(...enhancers));
  sagaMiddleware.run(rootSaga);

  return store;
};
