import React, { Component } from "react";
import {
  Button,
  Grid,
  Card,
  Icon,
  Image,
  Item,
  Loader,
  Modal,
  Dimmer,
  Header,
  Confirm
} from "semantic-ui-react";
import Styles from "../Styles/EventComponentStyles";
import moment from "moment";
import { Images } from "../../themes";
import ScreenComponent from "./ScreenComponent";
import queryString from "query-string";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import LeaveMessageInEventComponent from "./LeaveMessageInEventComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class EventComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: null,
      token: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      const params = queryString.parse(this.props.location.search);

      this.setState({
        token: params.token
      });

      this.props.findEventBySlug(this.props.match.params.id, params.token);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.publicController.event &&
      nextProps.publicController.event
    ) {
      this.setState({
        event: nextProps.publicController.event
      });
    }

    if (
      !this.props.publicController.eventMessage &&
      nextProps.publicController.eventMessage
    ) {
      if (
        this.props.publicController.creatingMessage &&
        !nextProps.publicController.creatingMessage
      ) {
        const eventMessage = Object.assign(
          {
            image_url: nextProps.publicController.eventMessage.image.url
          },
          nextProps.publicController.eventMessage
        );

        this.setState({
          event: {
            ...this.state.event,
            event_messages: [...this.state.event.event_messages, eventMessage]
          }
        });

        toast.success("Mensaje enviado correctamente");
        return;
      }

      if (
        this.props.publicController.destroyingMessage &&
        !nextProps.publicController.destroyingMessage
      ) {
        this.setState({
          event: {
            ...this.state.event,
            event_messages: this.state.event.event_messages.filter(
              mm => mm.id !== nextProps.publicController.eventMessage.id
            )
          },
          messageToDestroy: null
        });
        toast.success("Mensaje eliminado correctamente");
        return;
      }
    }
  }

  renderMessages() {
    if (!this.state.event || !this.state.event.event_messages) {
      return;
    }

    return this.state.event.event_messages
      .filter(m => {
        return (
          !m.is_device_blacklisted &&
          !m.is_message_blacklisted &&
          m.deleted_at == undefined
        );
      })
      .map(message => {
        return (
          <VerticalTimelineElement
            key={message.id}
            className="vertical-timeline-element--work"
            iconStyle={{
              background:
                this.state.event.ends_at < message.created_at
                  ? "#2E2343"
                  : "#37a2cc",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {message.image_url && (
              <Image
                src={Images.remote(message.image_url)}
                style={Styles.imageCard}
              />
            )}

            <h3 className="vertical-timeline-element-title">
              {message.name}
              <Header as="h5" disabled floated="right">
                {moment(message.created_at).format("DD/MM/YYYY HH:mm")}
              </Header>
            </h3>

            {!this.state.event.image_only && <p>{message.content}</p>}

            {this.state.event.can_edit && (
              <Icon
                name="trash"
                style={Styles.deleteIcon}
                onClick={() => this.setState({ messageToDestroy: message })}
              />
            )}
          </VerticalTimelineElement>
        );
      });
  }

  render() {
    const event = this.state.event;

    if (event) {
      return (
        <Grid centered columns={1} style={Styles.screenContent}>
          <Item style={Styles.infoContainer}>
            <Item style={Styles.companyCard}>
              <Image
                src={Images.remote(event.company_logo_url)}
                style={Styles.companyLogo}
              />
            </Item>
          </Item>

          <Item style={Styles.infoContainer}>
            <Item style={Styles.eventCard}>
              {event.image_url && (
                <Item style={Styles.imageContainer}>
                  <Image
                    src={Images.remote(event.image_url)}
                    style={Styles.image}
                  />
                </Item>
              )}

              <Item.Header style={Styles.eventCardHeader}>
                {event.event_name}
              </Item.Header>

              <Item.Header style={Styles.eventCardDates}>
                {event.born_at &&
                  moment(event.born_at).format("DD/MM/YYYY") + " - "}{" "}
                {moment(event.died_at).format("DD/MM/YYYY")}
              </Item.Header>

              {event.can_edit && (
                <CopyToClipboard
                  text={window.location.href.split("?")[0]}
                  onCopy={() => toast.success("Link copiado al portapapeles.")}
                >
                  <Button fluid style={Styles.shareButton} primary>
                    <Icon
                      link
                      name="share alternate"
                      style={Styles.shareIcon}
                    />
                    Haga click para copiar link y compartir
                  </Button>
                </CopyToClipboard>
              )}
            </Item>

            <LeaveMessageInEventComponent event={event} />
          </Item>

          <Item style={Styles.timelineContainer}>
            <VerticalTimeline>{this.renderMessages()}</VerticalTimeline>
          </Item>

          {this.state.messageToDestroy && (
            <Confirm
              open={this.state.messageToDestroy !== null}
              content={`Seguro desea eliminar el mensaje?`}
              cancelButton="Cancelar"
              confirmButton="Aceptar"
              onCancel={() => {
                this.setState({ messageToDestroy: null });
              }}
              onConfirm={() => {
                this.props.removeEventMessageWithToken(
                  this.state.messageToDestroy.id,
                  this.state.token
                );
              }}
            />
          )}

          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            closeButton={false}
          />
        </Grid>
      );
    }

    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }
}
